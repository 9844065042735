import React from 'react';
import Zippyops from 'src/icons/zippy.png';
const Logo = (props) => (
  <img
    alt="Logo"
    src={Zippyops}
    {...props}
    style={{ height: '40px' }}
  />
);

export default Logo;
