import React from "react";
import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import { Helmet } from "react-helmet";
import GroupsIcon from "@material-ui/icons/Groups";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BudgetError from "./BudgetError";
import ScheduleError1 from "./ScheduleError1";
import CustomDaypicker from "./CustomDaypicker";


const Days = [
  { id: 1, name: "Sunday", value: "SUN" },
  { id: 2, name: "Monday", value: "MON" },
  { id: 3, name: "Tuesday", value: "TUE" },
  { id: 4, name: "Wednesday", value: "WED" },
  { id: 5, name: "Thurday", value: "THU" },
  { id: 6, name: "Friday", value: "FRI" },
  { id: 7, name: "Saturday", value: "SAT" },
];

export default function FormDialog(props) {
  const { InstanceID, name, budget, count } = props;

  const [temporary, settemporary] = React.useState({
    repeat_frequency: "none",
  });

  const [state, setState] = React.useState({
    name: "",
    number_of_trainee: "1",
    start_date_time: "",
    end_date_time: "",
    repeat_frequency: "none",
    budget: "",
  });


  const [lablist, setlablist] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/lablist/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setlablist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [frequencyday, setFrequencyDay] = React.useState([]);
  const [frequencyMonth, setFrequencyMonth] = useState();

  const [scheduleerroropen1, setscheduleerroropen1] = React.useState(false);

  const [find, setfind] = useState(false);
  const handleChange = (e) => {
    setfind(false);
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    const checklab = lablist.map((instance) => {
      if (instance.name === value) {
        setfind(true);
      }
    });
  };

  const handleChange1 = (event, value) => {
    const id = "cronvalue";
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [budgetopen, setBudgetOpen] = React.useState(false);

  const [schedulecheckstate, setSchedulecheckstate] = React.useState({
    selectedValue: "checkedA",
  });

  const handleschedulecheckChange = (event) => {
    const name = "selectedValue";
    setSchedulecheckstate({ [name]: event.target.value });
  };

  const handleSubmitClick = (labid) => (e) => {
    e.preventDefault();

    setBudgetOpen(false);
    let available = localStorage.getItem("available");
    if (parseInt(state.budget) < parseInt(available)) {
      let timecount = 0;
      let timecount1 = 0;

      var zone = "America/New_York";
      var datefmt = "MM-DD";
      var timefmt = "HH:mm";

      var startDate;
      var NewstartDate;

      if (state.start_date_time) {
        startDate = new Date(state.start_date_time).toISOString().split("T")[0];
        NewstartDate = new Date(startDate);
      } else {
        startDate = new Date().toISOString().substring(0, 10);
        NewstartDate = new Date(startDate);
      }

      var a = moment(NewstartDate, datefmt, zone).utc().format(datefmt);
      var sDate = new Date(a);

      var sDate2 = new Date(NewstartDate);
      const startdays = sDate2.getUTCDate();
      const startmonths = sDate2.getUTCMonth() + 1;

      var endDate;
      var NewendDate;
      if (state.end_date_time) {
        endDate = new Date(state.end_date_time).toISOString().split("T")[0];
        NewendDate = new Date(endDate);
      } else {
        endDate = new Date().toISOString().substring(0, 10);
        NewendDate = new Date(endDate);
      }

      var b = moment(NewendDate, datefmt, zone).utc().format(datefmt);
      var eDate = new Date(b);

      var eDate2 = new Date(NewendDate);
      const enddays = eDate2.getUTCDate();
      const endmonths = eDate2.getUTCMonth() + 1;

      var startminutes, starthours;
      if (schedulecheckstate.selectedValue !== "checkedC") {
        const startTime = new Date(state.start_date_time)
          .toISOString()
          .split("T")[1];

        const stTime = startTime.split(":");

        startminutes = stTime[1];
        starthours = stTime[0];

        const CountstopTime = state.end_date_time.split("T")[1];
        if (CountstopTime <= startTime) {
          timecount = timecount + 1;
        }
      }

      const stopTime = new Date(state.end_date_time)
        .toISOString()
        .split("T")[1];
      const spTime = stopTime.split(":");

      const stopminutes = spTime[1];
      const stophours = spTime[0];

      const repeatFrequency = state.repeat_frequency;
      const Every = state.every;

      if (state.cronvalue) {
        const onDays = state.cronvalue;

        const MaponDays = onDays
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((ondayvalue) => {
            const one = ondayvalue.value;
            return one;
          });
        const stringonDays = JSON.stringify(MaponDays);
        const onDaysreplace = stringonDays
          .replace("[", "")
          .replace("]", "")
          .replace(/"/g, "");
        var NewonDays = onDaysreplace;
        var stronDays = NewonDays;
      }
      var cron_expression_for_start;
      var cron_expression_for_end;
      var cron_expression_for_start2;
      var cron_expression_for_end2;
      var depends_on;
      if (schedulecheckstate.selectedValue === "checkedA") {
        depends_on = "always";
        cron_expression_for_start = `${startminutes} ${starthours} ${startdays} ${startmonths} ?`;
        cron_expression_for_end = `${stopminutes} ${stophours} ${enddays} ${endmonths} ?`;
      } else if (schedulecheckstate.selectedValue === "checkedB") {
        depends_on = "during_training";
        if (repeatFrequency === "day") {
          if (frequencyday.length === 0) {
            timecount1 = timecount1 + 1;
          }
          else {
          cron_expression_for_start = `${startminutes} ${starthours} ${frequencyday[0]} ?`;
          cron_expression_for_end = `${stopminutes} ${stophours} ${frequencyday[0]} ?`;
          if (frequencyday[1] !== undefined) {
            cron_expression_for_start2 = `${startminutes} ${starthours} ${frequencyday[1]} ?`;
            cron_expression_for_end2 = `${stopminutes} ${stophours} ${frequencyday[1]} ?`;
          } else {
            cron_expression_for_start2 = 'multiple month not selected';
            cron_expression_for_end2 = 'multiple month not selected';
          }
         } 
        } else {
          if (stronDays === undefined) {
            timecount1 = timecount1 + 1;
          }
          else {
          cron_expression_for_start = `${startminutes} ${starthours} ? ${startmonths} ${NewonDays}`;
          cron_expression_for_end = `${stopminutes} ${stophours} ? ${startmonths} ${NewonDays}`;
          if (startmonths != endmonths) {
            cron_expression_for_start2 = `${startminutes} ${starthours} ? ${endmonths} ${NewonDays}`;
            cron_expression_for_end2 = `${stopminutes} ${stophours} ? ${endmonths} ${NewonDays}`; 
          } else {
            cron_expression_for_start2 = 'multiple month not selected';
            cron_expression_for_end2 = 'multiple month not selected';
          }
        }
       }
      } else {
        depends_on = "immediate";        
        cron_expression_for_end = `${stopminutes} ${stophours} ${enddays} ${endmonths} ?`;
      }

      var currentTime = new Date();
      var hoursIST = String(currentTime.getUTCHours()).padStart(2, "0");
      var minutesIST = String(currentTime.getUTCMinutes()).padStart(2, "0");

      var FinalstartTime;
      if (state.start_date_time) {
        const FinalstartTime1 = new Date(state.start_date_time)
          .toISOString({ hour: "2-digit", minute: "2-digit" })
          .split("T")[1];
        const FinalTime = FinalstartTime1.split(":");
        const minsUTC = FinalTime[1];
        const hrsUTC = FinalTime[0];

        FinalstartTime = `${hrsUTC}:${minsUTC}`;
      } else {
        FinalstartTime = `${hoursIST}:${minutesIST}`;
      }

      var currentDTime = new Date();
      var hoursUTC = String(currentDTime.getUTCHours()).padStart(2, "0");
      var minutesUTC = String(currentDTime.getUTCMinutes()).padStart(2, "0");
      var FinalstopTime;

      if (state.end_date_time) {
        const FinalstopTime1 = new Date(state.end_date_time)
          .toISOString({ hour: "2-digit", minute: "2-digit" })
          .split("T")[1];
        const FinalStopTime = FinalstopTime1.split(":");
        const minsUTC = FinalStopTime[1];
        const hrsUTC = FinalStopTime[0];
        FinalstopTime = `${hrsUTC}:${minsUTC}`;
      } else {
        FinalstopTime = `${hoursUTC}:${minutesUTC}`;
      }

    if (timecount1 > 0) {
      setscheduleerroropen1(true);
    } else {  
      const data = {
        labid: labid,
        name: state.name,
        number_of_trainee: state.number_of_trainee,
        start_date: startDate,
        end_date: endDate,
        start_time: FinalstartTime,
        stop_time: FinalstopTime,
        depends_on: depends_on,
        repeat_frequency: state.repeat_frequency,
        startcron_expression: cron_expression_for_start,
        stopcron_expression: cron_expression_for_end,
        startcron_expression2: cron_expression_for_start2,
        stopcron_expression2: cron_expression_for_end2,
        budget: state.budget,
      };
      fetch(`${process.env.REACT_APP_API_URL}/api/cloneinstance/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.name) {
            window.location.reload();
          } else {
            if (data.name) {
              setState({ errorMessage: data.name[0] });
            }
          }
        })
        .catch((error) => {
          window.location.reload();
        });
      }
    } else {
      setBudgetOpen(true);
    }
  };

  const [open, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClickClose = () => {
    setOpen1(false);
  };

  var currentTime = new Date();

  var currentOffset = currentTime.getTimezoneOffset();
  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );
  var hoursIST = ISTTime.getHours();
  var minutesIST = ISTTime.getMinutes();
  var currentDate = new Date().toISOString().substring(0, 10);
  let DateTimeformat = `${currentDate}`;

  let localTime = new Date();
  let minDate1 = moment(localTime).format("YYYY-MM-DDTHH:MM");

  let startTimelocal = new Date(state.start_date_time);
  let startday = (startTimelocal.getDate() + 1).toString().padStart(2, "0");
  let startmonth = (startTimelocal.getMonth() + 1).toString().padStart(2, "0");

  let startyear = startTimelocal.getFullYear();
  let maxDate1 = `${startyear}-${startmonth}-${startday}`;

  let Dateformat = new Date(DateTimeformat);
  let currentDateTime = `${hoursIST}:${minutesIST}`;

  var nextMonth = ((Dateformat.getMonth() + 1) % 12) + 1;
  var cMonth = Dateformat.getMonth() + 1;

  if (cMonth === 12) {
    var year = currentTime.getFullYear() + 1;
    var date = new Date(`${year}`, `${nextMonth}`, 0);
    var endDate = date.getDate();
    var maxDate = `${year}-${nextMonth}-${endDate}`;
  } else {
    var year = currentTime.getFullYear();
    var date = new Date(`${year}`, `${nextMonth}`, 0);
    var endDate = date.getDate();
    var maxDate = `${year}-${nextMonth}-${endDate}`;
  }
  var maxDateFinal = new Date(maxDate).toISOString().substring(0, 10);

  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <MenuItem onClick={handleClickOpen}>
        <IconButton aria-label="Filter" style={{ color: "#2f2c3d" }}>
          <GroupsIcon /> &nbsp;&nbsp;
          <span style={{ fontSize: "0.7em", color: "#172B4D" }}>Clone</span>
        </IconButton>
      </MenuItem>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <BudgetError
          validerroropen={budgetopen}
          setvaliderroropen={setBudgetOpen}
        />

        <ScheduleError1
          validerroropen={scheduleerroropen1}
          setvaliderroropen={setscheduleerroropen1}
        />
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            <strong style={{ color: "#000000" }}>Enter Clone Details</strong>
          </DialogContentText>
          <br />
          <br />
          <TextField
            fullWidth
            type="text"
            color={temporary.name && !find ? "success" : ""}
            helperText={
              find
                ? "Lab name already exists, Please enter the unique name"
                : "Please specify the name"
            }
            error={find}
            label="Name"
            name="name"
            id="name"
            value={state.name}
            size="small"
            onChange={handleChange}
            required
          />
          <br />
          <br />
          
          <Box sx={{ pb: 2, pt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Scheduling
            </Typography>
            <Divider />
          </Box>
          <Box
            borderColor="error.main"
            border={schedulecheckstate.selectedValue === "checkedA" ? 1 : 0}
          >
            <Accordion
              square
              expanded={schedulecheckstate.selectedValue === "checkedA"}
            >
              <AccordionSummary>
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={
                    <Checkbox
                      checked={schedulecheckstate.selectedValue === "checkedA"}
                      onChange={handleschedulecheckChange}
                      value="checkedA"
                      color="secondary"
                    />
                  }
                  label={
                    <Typography variant="h5">
                      {" "}
                      Always{" "}
                      <small>
                        ( Lab will be running continuously between the schedule
                        provided )
                      </small>
                    </Typography>
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h7" gutterBottom>
                      Start Date & Time
                    </Typography>
                    <TextField
                      fullWidth
                      name="start_date_time"
                      id="start_date_time"
                      type="datetime-local"
                      onChange={handleChange}
                      required
                      variant="outlined"
                      disabled={
                        schedulecheckstate.selectedValue === "checkedA"
                          ? false
                          : true
                      }
                      inputProps={{
                        min: minDate1,
                        max: `${maxDateFinal}T00:00`,
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h7" gutterBottom>
                      End Date & Time
                    </Typography>
                    <TextField
                      fullWidth
                      name="end_date_time"
                      id="end_date_time"
                      type="datetime-local"
                      onChange={handleChange}
                      required
                      variant="outlined"
                      disabled={
                        schedulecheckstate.selectedValue === "checkedA"
                          ? false
                          : true
                      }
                      inputProps={{
                        min:
                          schedulecheckstate.selectedValue === "checkedA" &&
                          state.start_date_time
                            ? state.start_date_time
                            : minDate1,
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box border={schedulecheckstate.selectedValue === "checkedB" ? 1 : 0}>
            <Accordion
              square
              expanded={schedulecheckstate.selectedValue === "checkedB"}
            >
              <AccordionSummary>
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={
                    <Checkbox
                      checked={schedulecheckstate.selectedValue === "checkedB"}
                      onChange={handleschedulecheckChange}
                      value="checkedB"
                      color="secondary"
                    />
                  }
                  label={
                    <Typography variant="h5">
                      {" "}
                      During Training Hours{" "}
                      <small>
                        ( Lab will be running at given frequencies between the
                        schedule provided )
                      </small>
                    </Typography>
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h7" gutterBottom>
                      Start Date & Time
                    </Typography>
                    <TextField
                      fullWidth
                      name="start_date_time"
                      id="start_date_time"
                      type="datetime-local"
                      onChange={handleChange}
                      required
                      variant="outlined"
                      disabled={
                        schedulecheckstate.selectedValue === "checkedB"
                          ? false
                          : true
                      }
                      inputProps={{
                        min: minDate1,
                        max: `${maxDateFinal}T00:00`,
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h7" gutterBottom>
                      End Date & Time
                    </Typography>
                    <TextField
                      fullWidth
                      name="end_date_time"
                      id="end_date_time"
                      type="datetime-local"
                      onChange={handleChange}
                      required
                      variant="outlined"
                      disabled={
                        schedulecheckstate.selectedValue === "checkedB"
                          ? false
                          : true
                      }
                      inputProps={{
                        min:
                          schedulecheckstate.selectedValue === "checkedB" &&
                          state.start_date_time
                            ? state.start_date_time
                            : minDate1,
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ pb: 2, pt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Frequency Details
                  </Typography>
                  <Divider />
                </Box>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={4}>
                    <Typography variant="h8" gutterBottom>
                      Repeat Frequency
                    </Typography>
                    <TextField
                      fullWidth
                      name="repeat_frequency"
                      id="repeat_frequency"
                      onChange={handleChange}
                      disabled={
                        schedulecheckstate.selectedValue === "checkedB"
                          ? false
                          : true
                      }
                      required
                      select
                      SelectProps={{ native: true }}
                      autoComplete="repeat_frequency"
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                      }}
                    >
                      {" "}
                      <option key="" value=""></option>
                      <option key="day" value="day">
                        Specific Day's
                      </option>
                      <option key="week" value="week">
                        Weekly
                      </option>
                    </TextField>
                  </Grid>
                  {temporary.repeat_frequency === "day" ? (
                    <Grid item md={4} xs={4}>
                      <Typography variant="h8" gutterBottom>
                        Choose the Required Dates
                      </Typography>
                      <CustomDaypicker
                        setFrequencyDay={setFrequencyDay}
                        setFrequencyMonth={setFrequencyMonth}
                        startDate={state.start_date_time}
                        endDate={state.end_date_time}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {temporary.repeat_frequency === "week" ? (
                    <Grid item md={6} xs={4}>
                      <Typography variant="h8" gutterBottom>
                        On Days
                      </Typography>
                      <Autocomplete
                        multiple
                        name="on_days"
                        size="small"
                        limitTags={2}
                        options={Days}
                        onChange={handleChange1}
                        disabled={
                          schedulecheckstate.selectedValue === "checkedB"
                            ? false
                            : true
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <React.Fragment>
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="On Days"
                            />
                          </React.Fragment>
                        )}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            borderColor="error.main"
            border={schedulecheckstate.selectedValue === "checkedC" ? 1 : 0}
          >
            <Accordion
              square
              expanded={schedulecheckstate.selectedValue === "checkedC"}
            >
              <AccordionSummary>
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={
                    <Checkbox
                      checked={schedulecheckstate.selectedValue === "checkedC"}
                      onChange={handleschedulecheckChange}
                      value="checkedC"
                      color="secondary"
                    />
                  }
                  label={
                    <Typography variant="h5">
                      {" "}
                      Immediate{" "}
                      <small>( Lab will be running upto end date )</small>
                    </Typography>
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="h7" gutterBottom>
                      End Date & Time
                    </Typography>
                    <TextField
                      fullWidth
                      name="end_date_time"
                      id="end_date_time"
                      type="datetime-local"
                      onChange={handleChange}
                      required
                      variant="outlined"
                      disabled={
                        schedulecheckstate.selectedValue === "checkedC"
                          ? false
                          : true
                      }
                      inputProps={{
                        min: minDate1,
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ pb: 2, pt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Budget
            </Typography>
            <Divider />
          </Box>
          <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
            <Typography variant="h8">
              Please specify the budget (In Credits) for your lab. if your
              budget is reached, your lab will be automatically stopped. And the
              next day it will be terminated.
            </Typography>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="NOTE: Enter your Budget in Credits"
                name="budget"
                id="budget"
                onChange={handleChange}
                required
                placeholder={budget}
                error
                value={state.budget}
                type="number"
                variant="outlined"
                InputProps={{
                  style: {
                    height: "40px",
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitClick(InstanceID)}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
