import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Profile from './pages/Profile/Account';
import Dashboard from './pages/Dashboard/Dashboard';
import NotFound from './pages/Error/NotFound';
import NOTFOUNd403 from './pages/Error/403';
import Management from './pages/Management/Management';
import Schedule from './pages/Schedule/Schedule';
import Usermodify from './pages/Management/Usermodify';
import Groupmodify from './pages/Management/Groupmodify';
import CustomBilling from './pages/BillingForm/CustomLabsBilling/Billinginfo';
import LabEstimation from './pages/LabEstimation/LabEstimation';
import CustomInstanceDetail from './pages/LabManager/CustomDetail';
import InstanceConfig from './pages/DeploymentForm/CutomApplications/RequestLayout';
import CustomLabList from './pages/LabManager/CustomLabs';
import Succeeded from './pages/BillingForm/Success/Success';

import AboutPage from './pages/About/AboutPage';

if (localStorage.getItem("group") === "admin"){ 
  var routes = [
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'maindeployment/instancerequest', element: <InstanceConfig />},
        { path: 'instance/custom', element: <CustomLabList />},
        { path: 'custom/instanceedit', element: <CustomInstanceDetail />},
        { path: 'management', element: <Management />}, 
        { path: 'schedule', element: <Schedule />},
        { path: 'usermodify', element: <Usermodify />},   
        { path: 'groupmodify', element: <Groupmodify />},
        { path: 'custom/billinginfo', element: <CustomBilling />},      
        { path: 'estimation', element: <LabEstimation />},
        { path: '403', element: <NOTFOUNd403 />},
        { path: '*', element: <Navigate to="/404" /> },        
      ]
    },
    {
      path: 'app',
      children: [
        { path: 'about', element: <AboutPage />},
        // { path: 'success:identity', element: <Succeeded /> }
      ]
    },  
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: 'success:identity', element: <Succeeded /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ]
    }
  ];
} else if (localStorage.getItem("group") === "ops_users"){
  var routes = [
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'maindeployment/instancerequest', element: <InstanceConfig />},
        { path: 'instance/custom', element: <CustomLabList />},
        { path: 'custom/instanceedit', element: <CustomInstanceDetail />},
        { path: 'schedule', element: <Schedule />}, 
        { path: 'custom/billinginfo', element: <CustomBilling />},
        { path: 'estimation', element: <LabEstimation />},
        { path: '*', element: <Navigate to="/404" /> },
      ]
    },
    {
      path: 'app',
      children: [
        { path: 'about', element: <AboutPage />}
      ]
    },  
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];   
} else {
  var routes = [
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'instance/custom', element: <CustomLabList />}, 
        { path: 'custom/instanceedit', element: <CustomInstanceDetail />},
        { path: 'schedule', element: <Schedule />}, 
        { path: 'estimation', element: <LabEstimation />},
        { path: '*', element: <Navigate to="/404" /> },
      ]
    },
    {
      path: 'app',
      children: [
        { path: 'about', element: <AboutPage />}
      ]
    },  
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ]; 
}
  
export default routes;
