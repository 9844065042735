import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ProgrammingLang from "./ProgrammingLang";
import Applications from "./Applications";
import Tools from "./Tools";
import Notify from "./Alert";
import Validerror from "./Error";
import SystemTools from "./Systemtools";
import LinuxStorageError from "./LinuxStorageError";
import WindowStorageError from "./WindowStorageError";
import DataMissingError  from "./DataMissingError";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 30px",
    paddingLeft: 10,
  },
}));

export default function Configurations(props) {
  const { onState, value, activeStep, handleBack, handleNext, classes } = props;      
  const classes1 = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const [errorserver, setErrorserver] = React.useState();
  const [validerroropen, setvaliderroropen] = React.useState(false);
  const [validerropen, setvaliderropen] = React.useState(false);
  const [validerropen1, setvaliderropen1] = React.useState(false);
  
  const [linuxstorageerroropen, setlinuxstorageerroropen] = React.useState(false);
  const [missingerroropen, setmissingerroropen] = React.useState(false);
  const [windowsstorageerroropen, setwindowstorageerroropen] = React.useState(false);

  const [alertstate, setAlertstate] = React.useState(false);
  const [formFields, setFormFields] = React.useState([]);

  const [programmingapps, setProgrammingapps] = React.useState([]);
  const [Systemtools, setSystemtools] = React.useState([]);

  const [normalapps, setNormalapps] = React.useState([]);
  const [devopsapps, setDevopsapps] = React.useState([]);

  const [devopsport, setDevopsport] = React.useState([]);
  const [normalport, setNormalport] = React.useState([]);

  const [linuxsingleselectedapp, setLinuxsingleselectedapp] = React.useState([]);  
  const [temporary, settemporary] = React.useState([]);
  const [temporarycount, settemporarycount] = React.useState([]);

  const handleChangeInput = (e, index) => {
    const { name, value } = e.target;
    const list = [...formFields];
    list[index][name] = value;
    setFormFields(list);
  };

  const handleRemoveForm = () => {
    const lastIndex = formFields.length - 1;
    const list = [...formFields];
    list.splice(lastIndex, 1);
    setFormFields(list);
    const nameJsonStr = linuxsingleselectedapp;
    delete nameJsonStr[lastIndex];
    setLinuxsingleselectedapp(nameJsonStr);
  };

  const handleAddForm = () => {
    if (formFields.length < 5) {
      const newAddValue = {
        servername: "",
        instance_type: "",
        ostype: "linux",
        instance_storage: "",
        port: "",
      };
      setFormFields([...formFields, newAddValue]);
    } else {
      setAlertstate(true);
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  const [checkstate, setCheckstate] = React.useState({});
  const handlecheckChange = (event) => {
    setCheckstate({ ...checkstate, [event.target.name]: event.target.checked });
  };

  const [instancetype, setInstancetype] = React.useState([[]]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/instancetype/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setInstancetype(resp))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmitClick = (e) => {
    e.preventDefault();
      const Lengthform = formFields.length;      
      var newObj = {};
      var newObj2 = {};
      let count = 0;
      let limit = 0;
      let empty = 0;
      
      let collectType = [];
      [...Array(Lengthform)].map((frm, i) => {
        collectType.push(formFields[i]["instance_type"]);
        const numberObject = i + 1;
        const server = "server-" + numberObject;
        const servername = "servername-" + numberObject;
        const instance_type = "instance_type-" + numberObject;
        const ostype = "ostype-" + numberObject;
        const linux_type = "linux_type-" + numberObject;
        const windows_type = "windows_type-" + numberObject;
        const instance_storage = "instance_storage-" + numberObject;
        const port = "port-" + numberObject;
        const allport = "all_ports-" + numberObject;
        const command = "commands-" + numberObject;
        const appNormalValue = "apps-" + numberObject;
        const appProgrammingValue = "programming-" + numberObject;
        const appToolsValue = "tools-" + numberObject;
        const appSystemToolsValue = "system_tools-" + numberObject;

        newObj[server] = "yes";
        newObj[servername] = formFields[i]["servername"];
        newObj[instance_type] = formFields[i]["instance_type"];
        newObj[instance_storage] = formFields[i]["instance_storage"];
        newObj[ostype] = formFields[i]["ostype"];
        if (formFields[i]["ostype"] === "linux") {
          newObj[linux_type] = formFields[i]["linux_type"];
        } else {
          newObj[windows_type] = formFields[i]["windows_type"];
        }                        
        
        const customlistedports = formFields[i]["port"]
          .split(",")
          .map((pts) => {
            return `${pts}`;
          });
        const selectedcustomports = JSON.stringify(customlistedports).replace(
          /"/g,
          ""
        );
        if (selectedcustomports === "[all]") {
          newObj[allport] = "yes";
        } else {
          newObj[port] = selectedcustomports;
        }

        newObj2[command] = formFields[i]["command"];

        if (normalapps[i]) {
          const listofnrmlapps = normalapps[i].map((b) => {
            return `${b}`;
          });
          const selectedNormalApps = JSON.stringify(listofnrmlapps)
            .replace(/","/g, "-")
            .replace(/\[/g, "")
            .replace(/]/g, "")
            .replace(/"/g, "");
          newObj[appNormalValue] = selectedNormalApps;

          if (devopsapps[i]) {
            normalport[i].map((na) => {
              devopsport[i].map((db) => {
                if (parseInt(na) === parseInt(db)) {
                  count = count + 1;
                  setErrorserver(`machine-${numberObject}`);
                }
              });
            });
          }
        } else {
          newObj[appNormalValue] = "notapply";
        }

        const os_type = formFields[i]["ostype"];
        const inst_storage = formFields[i]["instance_storage"];
        const inst_type = formFields[i]["instance_type"];        

        if (os_type === "" || inst_storage === "" || inst_type === "" || selectedcustomports === "") {
          empty = empty + 1;
          setmissingerroropen(true);
        }

        if (os_type === "windows" && inst_storage < 30) {
          limit = limit + 1;
          setwindowstorageerroropen(true);
        } else if (os_type === "linux" && inst_storage < 8) {
          limit = limit + 1;
          setlinuxstorageerroropen(true);
        }                 

        if (programmingapps[i]) {
          const listofprogrammingapps = programmingapps[i].map((b) => {
            return `${b}`;
          });
          const selectedProgrammingApps = JSON.stringify(listofprogrammingapps)
            .replace(/","/g, "-")
            .replace(/\[/g, "")
            .replace(/]/g, "")
            .replace(/"/g, "");
          newObj[appProgrammingValue] = selectedProgrammingApps;
        } else {
          newObj[appProgrammingValue] = "notapply";
        }

        if (devopsapps[i]) {
          newObj[appToolsValue] = devopsapps[i];
        } else {
          newObj[appToolsValue] = "notapply";
        }

        if (Systemtools[i]) {
          const listofsystemtools = Systemtools[i].map((b) => {
            return `${b}`;
          });
          const selectedSystemtools = JSON.stringify(listofsystemtools)
            .replace(/","/g, "-")
            .replace(/\[/g, "")
            .replace(/]/g, "")
            .replace(/"/g, "");
          newObj[appSystemToolsValue] = selectedSystemtools;
        } else {
          newObj[appSystemToolsValue] = "notapply";
        }
      });
            
             
      if (limit > 0) {
        setvaliderropen(true);
       }
       else if(empty > 0){
        setvaliderropen1(true);         
       } 
       else if (count > 0) {        
        setvaliderroropen(true);
       }       
        else {
          [...Array(Lengthform)].map((frm, i) => {
            if (normalapps[i]) {
              const listofnrmlapps = normalapps[i].map((b) => {
                return `${b}`;
              });
              formFields[i]["apps"] = listofnrmlapps;
            } else {
              formFields[i]["apps"] = "notapply";
            }

            if (programmingapps[i]) {
              const listofprogrammingapps = programmingapps[i].map((b) => {
                return `${b}`;
              });
              formFields[i]["programming"] = listofprogrammingapps;
            } else {
              formFields[i]["programming"] = "notapply";
            }

            if (devopsapps[i]) {
              formFields[i]["tools"] = devopsapps[i];
            } else {
              formFields[i]["tools"] = "notapply";
            }

            if (Systemtools[i]) {
              const listofsystemtools = Systemtools[i].map((b) => {
                return `${b}`;
              });
              formFields[i]["system_tools"] = listofsystemtools;
            } else {
              formFields[i]["system_tools"] = "notapply";
            }
          });

          let cpuFinalCount = [];
          const InstanceValue = instancetype.map((instanceCpu) => {
            const CollectValue = collectType.map((collectCpu) => {
              if (instanceCpu.instance_type === collectCpu) {
                const cpuCount = instanceCpu.vCPUs;
                cpuFinalCount.push(cpuCount);
              }
            });
          });
          var addcount = cpuFinalCount.reduce(function (n, val) {
            return n + parseInt(val);
          }, 0);

          let value2 = JSON.stringify(newObj2);
          var value = newObj;

          for (var i = 0; i < formFields.length; i++) {
            delete formFields[i].command;
          }

          var value1 = JSON.stringify(formFields);
                            
          const id1 = "customized_variable";
          const id2 = "billing_info";
          const id3 = "commands";
          const id4 = "cpu_count";
          
          onState((prevState) => ({
            ...prevState,
            [id1]: value,
            [id2]: value1,
            [id3]: value2,
            [id4]: addcount
          }));
          
          handleNext();
        }
  };

  const [confirmation, setconfirmation] = React.useState(false);

  const handleClickConfirmation = () => {
    setconfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setconfirmation(false);
  };

 
return (
      <React.Fragment>
        <Helmet>
          <title>SandboxAsService</title>
        </Helmet>
        <Box sx={{ pb: 2 }}>
          {alertstate ? <Notify setAlertstate={setAlertstate} /> : ""}
          <Container maxWidth={false}>
            
              <Validerror
                errorserver={errorserver}
                validerroropen={validerroropen}
                setvaliderroropen={setvaliderroropen}
              /> 
              <DataMissingError 
               validerropen1={missingerroropen}
               setvaliderropen1={setmissingerroropen}
              />           
              <LinuxStorageError
                validerropen={linuxstorageerroropen}
                setvaliderropen={setlinuxstorageerroropen}
              />
              <WindowStorageError
                validerropen={windowsstorageerroropen}
                setvaliderropen={setwindowstorageerroropen}
              />                                                         
                <Box sx={{ pb: 2}}>
                  <Typography variant="h6" gutterBottom>
                    Configuration
                  </Typography>
                  
                  <div className={classes1.buttons}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleAddForm()}
                    >
                      ADD
                    </Button>
                  </div>
                </Box>
                {formFields.length > 0 ? (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      {formFields.length} machines are added ( Maximum 5 machines
                      are allowed )
                    </Typography>
                    {formFields.map((e, index) => {
                      const serverIndex = index + 1;
                      return (
                        <div key={index}>
                          <Box sx={{ m: 2, border: "1px solid dark" }}>
                            <Accordion
                              style={{ border: "1px solid rgba(0, 0, 0, .125)" }}
                              defaultExpanded
                            >
                              <AccordionSummary
                                expandIcon={
                                  expanded ? (
                                    <ExpandMoreIcon />
                                  ) : (
                                    <ExpandLessIcon />
                                  )
                                }
                                onClick={(event) => {
                                  setExpanded(!expanded);
                                }}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <Typography variant="h6">
                                  Machine-{serverIndex}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={4}>
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      label="Server Name"
                                      name={`servername`}
                                      id={`servername`}
                                      onChange={(e) =>
                                        handleChangeInput(e, index)
                                      }
                                      value={e.servername}
                                      required
                                      autoComplete={`servername`}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      label="Instance Type"
                                      name={`instance_type`}
                                      id={`instance_type`}
                                      onChange={(e) =>
                                        handleChangeInput(e, index)
                                      }
                                      value={e.instance_type}
                                      required
                                      autoComplete="name"
                                      select
                                      SelectProps={{ native: true }}
                                    >
                                      {" "}
                                      <option key="" value=""></option>
                                      {instancetype.map((instancetype) => {
                                        return (
                                          <React.Fragment>
                                            <option
                                              value={instancetype.instance_type}
                                            >
                                              {instancetype.title}
                                            </option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </TextField>
                                  </Grid>
  
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      label="OS-Type"
                                      name={`ostype`}
                                      id={`ostype`}
                                      onChange={(e) =>
                                        handleChangeInput(e, index)
                                      }
                                      value={e.ostype}
                                      required
                                      select
                                      SelectProps={{ native: true }}
                                      autoComplete="name"
                                    >
                                      {" "}
                                      <option key="linux" value="linux">
                                        Linux
                                      </option>
                                      <option key="windows" value="windows">
                                        Windows
                                      </option>
                                    </TextField>
                                  </Grid>
                                  {formFields[index].ostype === "linux" ? (
                                    <React.Fragment>
                                      <Grid item xs={6}>
                                        <TextField
                                          fullWidth
                                          label="Linux-Type"
                                          name={`linux_type`}
                                          id={`linux_type`}
                                          onChange={(e) =>
                                            handleChangeInput(e, index)
                                          }
                                          value={e.linux_type}                                   
                                          required
                                          select
                                          SelectProps={{ native: true }}
                                          autoComplete="name"
                                        >
                                          {" "}
                                          <option key="" value=""></option>
                                          <option key="centos7" value="centos7">
                                            Centos7
                                          </option>
                                          <option key="centos8" value="centos8">
                                            Centos8
                                          </option>
                                          <option key="ubuntu16" value="ubuntu16">
                                            Ubuntu16
                                          </option>
                                          <option key="ubuntu18" value="ubuntu18">
                                            Ubuntu18
                                          </option>
                                          <option key="ubuntu20" value="ubuntu20">
                                            Ubuntu20
                                          </option>
                                        </TextField>
                                      </Grid>
                                      {formFields[index].linux_type ===
                                      "centos7" ? (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="centos7"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="centos7"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="centos7"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Tools
                                                OSTYPE="centos7"
                                                appsetFormFields={setDevopsapps}
                                                appfieldIndex={index}
                                                setDevopsport={setDevopsport}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : formFields[index].linux_type ===
                                        "centos8" ? (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="centos8"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="centos8"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="centos8"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Tools
                                                OSTYPE="centos8"
                                                appsetFormFields={setDevopsapps}
                                                appfieldIndex={index}
                                                setDevopsport={setDevopsport}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : formFields[index].linux_type ===
                                        "ubuntu16" ? (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="ubuntu16"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="ubuntu16"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="ubuntu16"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Tools
                                                OSTYPE="ubuntu16"
                                                appsetFormFields={setDevopsapps}
                                                appfieldIndex={index}
                                                setDevopsport={setDevopsport}
                                                setDevopsport={setDevopsport}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : formFields[index].linux_type ===
                                        "ubuntu18" ? (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="ubuntu18"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="ubuntu18"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="ubuntu18"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Tools
                                                OSTYPE="ubuntu18"
                                                appsetFormFields={setDevopsapps}
                                                appfieldIndex={index}
                                                setDevopsport={setDevopsport}
                                                setDevopsport={setDevopsport}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="ubuntu20"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="ubuntu20"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="ubuntu20"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Tools
                                                OSTYPE="ubuntu20"
                                                appsetFormFields={setDevopsapps}
                                                appfieldIndex={index}
                                                setDevopsport={setDevopsport}
                                                setDevopsport={setDevopsport}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  ) : formFields[index].ostype === "windows" ? (
                                    <React.Fragment>
                                      <Grid item xs={6}>
                                        <TextField
                                          fullWidth
                                          label="Windows-Type"
                                          name={`windows_type`}
                                          id={`windows_type`}
                                          onChange={(e) =>
                                            handleChangeInput(e, index)
                                          }
                                          value={e.windows_type}
                                          required
                                          select
                                          SelectProps={{ native: true }}
                                          autoComplete="name"
                                        >
                                          {" "}
                                          <option key="" value=""></option>
                                          <option
                                            key="windows2019"
                                            value="windows2019"
                                          >
                                            Windows2019
                                          </option>
                                          <option
                                            key="windows2022"
                                            value="windows2022"
                                          >
                                            Windows2022
                                          </option>
                                        </TextField>
                                      </Grid>
                                      {formFields[index].windows_type ===
                                      "windows2019" ? (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="windows2019"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="windows2019"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="windows2019"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12}>
                                          <Grid container spacing={4}>
                                            <Grid item xs={6}>
                                              <Applications
                                                OSTYPE="windows2022"
                                                appsetFormFields={setNormalapps}
                                                appfieldIndex={index}
                                                setNormalport={setNormalport}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <ProgrammingLang
                                                OSTYPE="windows2022"
                                                appsetFormFields={
                                                  setProgrammingapps
                                                }
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <SystemTools
                                                OSTYPE="windows2022"
                                                appsetFormFields={setSystemtools}
                                                appfieldIndex={index}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      helperText={
                                      formFields[index].ostype === "linux"
                                          ? "Minimum Requirement for linux storage is 8"
                                          : "Minimum Requirement for windows storage is 30"
                                      }
                                      label="Storage"
                                      name={`instance_storage`}
                                      id={`instance_storage`}
                                      onChange={(e) =>
                                        handleChangeInput(e, index)
                                      }
                                      value={e.instance_storage}
                                      required
                                      autoComplete="name"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      fullWidth
                                      label="Port"
                                      name={`port`}
                                      id={`port`}
                                      helperText="Type all to allow all the ports, whereas to allow custom ports please type it with comma separated values."
                                      onChange={(e) =>
                                        handleChangeInput(e, index)
                                      }
                                      value={e.port}
                                      required
                                      autoComplete="name"
                                    />
                                  </Grid>
  
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      label="Command"
                                      name={`command`}
                                      id={`command`}
                                      onChange={(e) =>
                                        handleChangeInput(e, index)
                                      }
                                      value={e.command}
                                      autoComplete="name"
                                      multiline
                                      rows={5}
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                              <Divider />
                            </Accordion>
                          </Box>
                        </div>
                      );
                    })}
                    <div className={classes1.buttons}>
                      <IconButton
                        title="Delete"
                        aria-label="delete"
                        id="delete"
                        style={{
                          padding: "10px",
                          color: "#e68383",
                        }}
                      >
                        <DeleteIcon
                          color="secondary"
                          variant="contained"
                          onClick={() => handleRemoveForm()}
                        />
                      </IconButton>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box
                      sx={{
                        pb: 2,
                        pt: 2,
                        m: 2,
                        color: "#000000",
                        backgroundColor: "#e7e7e7",
                        border: "1px solid #2d5e6e",
                        bordercolor: "error.main",
                        paddingTop: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingBottom: 2,
                        textAlignLast: "center",
                      }}
                    >
                      <Typography variant="h6">
                        Click ADD button to add the servers.
                      </Typography>
                    </Box>
                  </React.Fragment>
                )}                
               
          </Container>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReload}
            className={classes}
          >
            Cancel
          </Button>
        </div>
        <div className={classes}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} className={classes}>
              Back
            </Button>
          )}
          {checkstate.checkedB ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickConfirmation}
              className={classes}
              disabled={result > 0 ? false : true}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitClick}
              className={classes}
              disabled={formFields.length > 0 ? false : true}
            >
              Next
            </Button>
          )}
        </div>
      </Box>
      <div style={{ alignItems: "center", display: "flex", width: "600px" }}>
        <Dialog
          open={confirmation}
          onClose={handleCloseConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            The below applied specifications will get reflected on your machine{" "}
            <strong style={{ color: "#EF5350" }}>
              {localStorage.getItem("specifyusername")}
            </strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmation} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitClick} color="primary" autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </div>  
  </React.Fragment>
  );
}
