import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Button,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";


import ProgrammingLang from "./ProgrammingLang";
import Applications from "./Applications";
import Tools from "./Tools";
import SystemTools from "./Systemtools";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },

  layout: {
  },
  paper: {
  },
  stepper: {
    color: "green",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 30px",
    paddingLeft: 10,
  },

  button: {
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

function a11yProps(value) {
  if (!value) {
    return {
      disabled: true,
      style: { backgroundColor: "#ececec" },
    };
  }
}

const Days = [
  { id: 1, name: "Sunday", value: "SUN" },
  { id: 2, name: "Monday", value: "MON" },
  { id: 3, name: "Tuesday", value: "TUE" },
  { id: 4, name: "Wednesday", value: "WED" },
  { id: 5, name: "Thurday", value: "THU" },
  { id: 6, name: "Friday", value: "FRI" },
  { id: 7, name: "Saterday", value: "SAT" },
];

export default function InstanceConfig(props) {
  const { instancetype,
    formFields,
    setFormFields, 
    linuxsingleselectedapp,
    setLinuxsingleselectedapp,    
    setLinuxselectedapps,
    setWindowsselectedapps,
    setProgrammingapps, setSystemtools, setNormalapps, setDevopsapps, setDevopsport, setNormalport
  } = props;  

  const classes = useStyles();
  const [checkstate, setCheckstate] = React.useState({});

  const handleChangeInput = (e, index) => {
    const { name, value } = e.target;
    const list = [...formFields];
    list[index][name] = value;
    setFormFields(list);
  };

  const handleRemoveForm = () => {
    const lastIndex = formFields.length - 1;
    const list = [...formFields];
    list.splice(lastIndex, 1);
    setFormFields(list);
    const nameJsonStr = linuxsingleselectedapp;
    delete nameJsonStr[lastIndex];
    setLinuxsingleselectedapp(nameJsonStr);
  };

  const handleAddForm = () => {
    if (formFields.length < 5) {
      const newAddValue = {
        instance_type: "",
        ostype: "linux",
        linux_type: "",
        windows_type: "",
        instance_storage: "",
        multi_select: false
      };
      setFormFields([...formFields, newAddValue]);
    }  
  };

  const [temporary, settemporary] = React.useState({
    repeat_frequency: "none",
  });


  var currentTime = new Date();
  var currentOffset = currentTime.getTimezoneOffset();
  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );
  var hoursIST = ISTTime.getHours();
  var minutesIST = ISTTime.getMinutes();
  var currentDate = new Date().toISOString().substring(0, 10);
  let DateTimeformat = `${currentDate}`;
  let currentDateTime = `${hoursIST}:${minutesIST}`;

  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
              <Box sx={{ pb: 0, pt: 0 }}>
                <div className={classes.buttons}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleAddForm()}
                    className={classes.button}
                  >
                    ADD
                  </Button>
                </div>
       
              </Box>
              {formFields.length > 0 ? (
                <React.Fragment>
                  <Typography variant="h6" gutterBottom>
                  {formFields.length} machines are added ( Maximum 5 machines are allowed )
                  </Typography>
                  {formFields.map((e, index) => {
                    const serverIndex = index + 1;
                    return (
                      <div key={index}>
                        <Box sx={{ m: 2, border: "1px solid dark" }}>
                          <Accordion
                            style={{ border: "1px solid rgba(0, 0, 0, .125)" }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1c-content"
                              id="panel1c-header"
                            >
                              <Typography variant="h6">
                                Machine-{serverIndex}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={4}>
                              <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="OS-Type"
                                    name={`ostype`}
                                    id={`ostype`}
                                    onChange={(e) =>
                                      handleChangeInput(e, index)
                                    }
                                    value={e.ostype}                                    
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    autoComplete="name"
                                  >
                                    {" "}
                                    <option key="linux" value="linux">
                                      Linux
                                    </option>
                                    <option key="windows" value="windows">
                                      Windows
                                    </option>
                                  </TextField>
                                </Grid>
                                {formFields[index].ostype === "linux" ?                                
                                 (
                                  <Grid item xs={6}>
                                      <TextField                                         
                                        fullWidth
                                        label="Linux-Type"
                                        name={`linux_type`}
                                        id={`linux_type`}
                                        onChange={(e) =>
                                          handleChangeInput(e, index)
                                        }
                                        value={e.linux_type}                                        
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        autoComplete="name"
                                      >
                                        {" "}
                                        <option key="" value=""></option>
                                        <option key="centos7" value="centos7">
                                          Centos7
                                        </option>
                                        <option key="centos8" value="centos8">
                                          Centos8
                                        </option>
                                        <option key="ubuntu16" value="ubuntu16">
                                          Ubuntu16
                                        </option>
                                        <option key="ubuntu18" value="ubuntu18">
                                          Ubuntu18
                                        </option>
                                        <option key="ubuntu20" value="ubuntu20">
                                          Ubuntu20
                                        </option>
                                      </TextField>
                                    </Grid>
                                )
                                :
                                ( 
                                  <Grid item xs={6}>
                                      <TextField                                         
                                        fullWidth
                                        label="Windows-Type"
                                        name={`windows_type`}
                                        id={`windows_type`}
                                        onChange={(e) =>
                                          handleChangeInput(e, index)
                                        }
                                        value={e.windows_type}                                        
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        autoComplete="name"
                                      >
                                        {" "}
                                        <option key="" value=""> </option>
                                        <option key="windows2019" value="windows2019">
                                          Windows2019
                                        </option>
                                        <option key="windows2022" value="windows2022">
                                          Windows2022
                                        </option>
                                        
                                      </TextField>
                                    </Grid>
                                  )}  
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Instance Type"
                                    name={`instance_type`}
                                    id={`instance_type`}
                                    onChange={(e) =>
                                      handleChangeInput(e, index)
                                    }
                                    value={e.instance_type}
                                    required
                                    autoComplete="name"
                                    select
                                    SelectProps={{ native: true }}
                                  >
                                    {" "}
                                    <option key="" value=""></option>
                                    {instancetype.map((instancetype) => {
                                      return (
                                        <React.Fragment>
                                          <option
                                            value={instancetype.instance_type}
                                          >
                                            {instancetype.title}
                                          </option>
                                        </React.Fragment>
                                      );
                                    })}
                                  </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Storage"
                                    name={`instance_storage`}
                                    id={`instance_storage`}
                                    onChange={(e) =>
                                      handleChangeInput(e, index)
                                    }
                                    value={e.instance_storage}
                                    required
                                    autoComplete="name"
                                  />
                                </Grid>
                                {formFields[index].ostype === "linux" ? (
                                  <React.Fragment>                                  
                                    {formFields[index].linux_type ===
                                    "centos7" ? (
                                      <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                          <Grid item xs={2}>
                                            <Applications
                                              OSTYPE="centos7"
                                              appsetFormFields={setNormalapps}
                                              appfieldIndex={index}
                                              setNormalport={setNormalport}
                                            />
                                          </Grid>
                                          <Grid item xs={3}>
                                            <ProgrammingLang
                                              OSTYPE="centos7"
                                              appsetFormFields={
                                                setProgrammingapps
                                              }
                                              appfieldIndex={index}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                          <SystemTools 
                                            OSTYPE="centos7"
                                            appsetFormFields={
                                              setSystemtools
                                            }
                                            appfieldIndex={index}                                          
                                          />
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Tools
                                              OSTYPE="centos7"
                                              appsetFormFields={setDevopsapps}
                                              appfieldIndex={index}
                                              setDevopsport={setDevopsport}
                                            />
                                          </Grid>                                                                                    
                                        </Grid>
                                      </Grid>
                                    ) : formFields[index].linux_type ===
                                    "centos8" ? (
                                      <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                          <Grid item xs={2}>
                                            <Applications
                                              OSTYPE="centos8"
                                              appsetFormFields={setNormalapps}
                                              appfieldIndex={index}
                                              setNormalport={setNormalport}
                                            />
                                          </Grid>
                                          <Grid item xs={3}>
                                            <ProgrammingLang
                                              OSTYPE="centos8"
                                              appsetFormFields={
                                                setProgrammingapps
                                              }
                                              appfieldIndex={index}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                          <SystemTools 
                                            OSTYPE="centos8"
                                            appsetFormFields={
                                              setSystemtools
                                            }
                                            appfieldIndex={index}                                          
                                          />
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Tools
                                              OSTYPE="centos8"
                                              appsetFormFields={setDevopsapps}
                                              appfieldIndex={index}
                                              setDevopsport={setDevopsport}
                                            />
                                          </Grid>                                                                                    
                                        </Grid>
                                      </Grid>
                                    )
                                    : formFields[index].linux_type === 'ubuntu16' ?
                                     (
                                      <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                          <Grid item xs={2}>
                                            <Applications
                                              OSTYPE="ubuntu16"
                                              appsetFormFields={setNormalapps}
                                              appfieldIndex={index}
                                              setNormalport={setNormalport}
                                            />
                                          </Grid>
                                          <Grid item xs={3}>
                                            <ProgrammingLang
                                              OSTYPE="ubuntu16"
                                              appsetFormFields={
                                                setProgrammingapps
                                              }
                                              appfieldIndex={index}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                          <SystemTools 
                                            OSTYPE="ubuntu16"
                                            appsetFormFields={
                                              setSystemtools
                                            }
                                            appfieldIndex={index}                                          
                                          />
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Tools
                                              OSTYPE="ubuntu16"
                                              appsetFormFields={setDevopsapps}
                                              appfieldIndex={index}
                                              setDevopsport={setDevopsport}
                                              setDevopsport={setDevopsport}
                                            />
                                          </Grid>                                          
                                        </Grid>
                                      </Grid>
                                    )
                                    : formFields[index].linux_type === 'ubuntu18' ?
                                    (
                                     <Grid item xs={12}>
                                       <Grid container spacing={0}>
                                         <Grid item xs={2}>
                                           <Applications
                                             OSTYPE="ubuntu18"
                                             appsetFormFields={setNormalapps}
                                             appfieldIndex={index}
                                             setNormalport={setNormalport}
                                           />
                                         </Grid>
                                         <Grid item xs={3}>
                                           <ProgrammingLang
                                             OSTYPE="ubuntu18"
                                             appsetFormFields={
                                               setProgrammingapps
                                             }
                                             appfieldIndex={index}
                                           />
                                         </Grid>
                                         <Grid item xs={2}>
                                         <SystemTools 
                                           OSTYPE="ubuntu18"
                                           appsetFormFields={
                                             setSystemtools
                                           }
                                           appfieldIndex={index}                                          
                                         />
                                         </Grid>
                                         <Grid item xs={2}>
                                           <Tools
                                             OSTYPE="ubuntu18"
                                             appsetFormFields={setDevopsapps}
                                             appfieldIndex={index}
                                             setDevopsport={setDevopsport}
                                             setDevopsport={setDevopsport}
                                           />
                                         </Grid>                                          
                                       </Grid>
                                     </Grid>
                                   ) 
                                   : 
                                   (
                                    <Grid item xs={12}>
                                      <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                          <Applications
                                            OSTYPE="ubuntu20"
                                            appsetFormFields={setNormalapps}
                                            appfieldIndex={index}
                                            setNormalport={setNormalport}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <ProgrammingLang
                                            OSTYPE="ubuntu20"
                                            appsetFormFields={
                                              setProgrammingapps
                                            }
                                            appfieldIndex={index}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                        <SystemTools 
                                          OSTYPE="ubuntu20"
                                          appsetFormFields={
                                            setSystemtools
                                          }
                                          appfieldIndex={index}                                          
                                        />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Tools
                                            OSTYPE="ubuntu20"
                                            appsetFormFields={setDevopsapps}
                                            appfieldIndex={index}
                                            setDevopsport={setDevopsport}
                                            setDevopsport={setDevopsport}
                                          />
                                        </Grid>                                          
                                      </Grid>
                                    </Grid>
                                  )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {formFields[index].windows_type ===
                                    "windows2019" ?
                                    (
                                     <Grid item xs={12}>
                                      <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                          <Applications
                                            OSTYPE="windows2019"
                                            appsetFormFields={setNormalapps}
                                            appfieldIndex={index}
                                            setNormalport={setNormalport}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <ProgrammingLang
                                            OSTYPE="windows2019"
                                            appsetFormFields={
                                              setProgrammingapps
                                            }
                                            appfieldIndex={index}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <SystemTools 
                                            OSTYPE="windows2019"
                                            appsetFormFields={
                                              setSystemtools
                                            }
                                            appfieldIndex={index}                                          
                                          />
                                          </Grid>                                        
                                      </Grid>
                                    </Grid>
                                  ) 
                                  :
                                  (
                                    <Grid item xs={12}>
                                      <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                          <Applications
                                            OSTYPE="windows2022"
                                            appsetFormFields={setNormalapps}
                                            appfieldIndex={index}
                                            setNormalport={setNormalport}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <ProgrammingLang
                                            OSTYPE="windows2022"
                                            appsetFormFields={
                                              setProgrammingapps
                                            }
                                            appfieldIndex={index}
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <SystemTools 
                                            OSTYPE="windows2022"
                                            appsetFormFields={
                                              setSystemtools
                                            }
                                            appfieldIndex={index}                                          
                                          />
                                          </Grid>                                        
                                      </Grid>
                                    </Grid>
                                  )} 
                                  </React.Fragment>
                                )}                               
                              </Grid>
                              {/* </Box> */}
                            </AccordionDetails>
                            <Divider />
                          </Accordion>
                        </Box>
                      </div>
                    );
                  })}
                  <div className={classes.buttons}>
                    <IconButton
                      title="Delete"
                      aria-label="delete"
                      id="delete"
                      style={{
                        padding: "10px",
                        color: "#e68383",
                      }}
                    >
                      <DeleteIcon
                        color="secondary"
                        variant="contained"
                        onClick={() => handleRemoveForm()}
                        className={classes.button}
                      />
                    </IconButton>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box
                    sx={{
                      pb: 2,
                      pt: 2,
                      m: 2,
                      color: "#000000",
                      backgroundColor: "#e7e7e7",
                      border: "1px solid #2d5e6e",
                      bordercolor: "error.main",
                      paddingTop: 2,
                      paddingLeft: 2,
                      paddingRight: 2,
                      paddingBottom: 2,
                      textAlignLast: "center",
                    }}
                  >
                    <Typography variant="h6">+ Add the server...</Typography>
                  </Box>
                </React.Fragment>
              )}
    </React.Fragment>
  );
}