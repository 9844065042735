import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
// core components
import styles from "src/components/authentication/assets/jss/labs/components/headerStyle.js";
import Logo from 'src/components/Logo';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();

  const { color, fixed, absolute, page, navigation } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const PageNavigation = () => {
    window.location.href = `/${navigation}`;
  };
  return (
    <AppBar className={appBarClasses} style={{ width: 'auto'}}>
      <Toolbar className={classes.container} style={{ minHeight: '10px'}}>
        <div>   
        <Button onClick={PageNavigation} variant="contained" style={{ backgroundImage: "linear-gradient(60deg, #e4aa59, #ee6f9a)" }}>
        {"<"} {page} 
        </Button>  
        </div>                     
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};