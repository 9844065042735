import React from "react";
import { makeStyles } from "@material-ui/styles";
// core components
import GridItem from "../../../components/authentication/components/Grid/GridItem.js";
import styles from "src/components/authentication/assets/jss/labs/views/aboutPageSections/productStyle.js";
import teamstyles from "src/components/authentication/assets/jss/labs/views/aboutPageSections/teamStyle.js";


const useStyles = makeStyles(styles);
const teamuseStyles = makeStyles(teamstyles);

export default function ProductSection() {
  const classes = useStyles();
  const teamclasses = teamuseStyles();

  return (
    <>
        <GridItem xs={12} sm={12} md={18}>
          <h2 className={classes.title}>About Us.</h2>
          <h5 className={classes.description}>
          We are a team of talented Architects, Developers, designers and Entrepreneurs with one thing in common: we are passionate about the Automation industry and want to improve the current workflow which is to often tedious and repetitive.
          </h5>
        </GridItem>
      <div style={{paddingTop: "1px"}}>
          <GridItem xs={12} sm={12} md={18}>
          <h4 className={classes.title}>SandBox As Service</h4>
          <h5 className={classes.description}>
              SandBox are a very important ingredient for successful training. Hands-on experience is required to translate knowledge into real-world results
              Well Performing ready to use SandBox is very important to keep the participants engaged. Trainers and training organizations spend lots of effort in setting up the SandBox or the training hours are wasted in troubleshooting SandBox environments.
              SandBoxAsService provides pre-configured SandBox servers on the cloud which can be accessed from any machine with an Internet connection
          </h5>
        </GridItem>
      </div>
    </>
  );
}
