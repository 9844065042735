import React from 'react';
import { Avatar } from "@material-ui/core";

const user = {  
  avatar: '/static/images/avatars/rectwhite.png',
  name: localStorage.getItem("user"),
  email: localStorage.getItem("email")
};

function ImageUpload() {

  const handleClick = () => {
    window.location.href = "/app/dashboard";
  };


  return (
    <>
      <Avatar
        src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 150,
            height: 50
          }}
        style={{ borderRadius: 0 }}    
        onClick={handleClick} 
      />
      
    </>
  );
}

export default ImageUpload;

