import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "center", 
  },
  textField: {
    width: '25ch',
  },
  btncenter: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 30px",
    paddingLeft: 10,
  },  
}));

export default function LayoutTextFields() {
  const classes = useStyles();

  const [state, setState] = useState({
    name: "",
    email: "",
    contactno: "",
    commends: "",
  });
  
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();        
    const data = {
      name: state.name,
      email: state.email,
      contactno: state.contactno,
      commends: state.commends,
    };
    setState({ submitted: true });
    fetch(`${process.env.REACT_APP_API_URL}/api/userrequest/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };  
  return (
    <div className={classes.root}>
      <div>
        <TextField
          label="Name (required*)"
          style={{ margin: 8 }}
          fullWidth
          id='name'
          name='name'
          onChange={handleChange}
          value={state.name}          
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        <TextField
          label="E-mail (required*)"
          style={{ margin: 8 }}
          fullWidth
          id='email'
          name='email'
          onChange={handleChange}
          value={state.email}          
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        /> 
        <TextField
          label="Contact no (optional)"
          style={{ margin: 8 }}
          fullWidth
          id='contactno'
          name='contactno'
          onChange={handleChange}
          value={state.contactno}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />         
        <TextField
        fullWidth
        label="Comments (required*)"
        style={{ margin: 8 }}
        id='commends'
        name='commends'
        onChange={handleChange}
        value={state.commends}
        autoComplete="name"
        multiline
        rows={5}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"        
        />     
      <div className={classes.btncenter}>
      <Button
       disabled={state.name && state.email && state.commends ? false : true}
       color="primary"
       variant="contained"
       onClick={handleSubmitClick}
      >Send
      </Button>
      </div>                        
      </div>
    </div>
  );
}
