import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles';
import {
  Box,
} from '@material-ui/core';
import Changepass from "./profilepassword.js"
import AcProfileUpload from "./AcProfileUpload";
import {
  FiCardActions,  
} from "./FullImageCard";
import ModifyDetails from "./ModifyDetails";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 30px',
    paddingLeft: 10,
    
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: '100%',
    opacity: 0.5
  },
  fiCardContent: {
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,.20)",    
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)"
  }  
}));

const styles = {
  paperContainer: {
      backgroundImage: 'url(/static/images/avatars/zippy.png)',
      opacity: 1,
      backgroundSize: 'cover'
  },
  cardContainer: {
    opacity: 1,
  }
};


export default function Profile() {
  const classes = useStyles();
  const [edituser, setedituser] = useState([]);
  const [state, setState] = useState({
    username: '',
    password: '',
    email: '',
    group: '',
    organization: '',
    address:''
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/user_management/getprofile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setedituser(resp))
      .catch((error) => {
        if (error.code) {
          window.location.href = "/access_denied";
          } else {
          window.location.href = "/something_went_wrong";
          }
      });
  }, []);  

  const [avatar1, setAvatar1] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/usrimage/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setAvatar1(resp[0]))
      .catch((error) => {
        console.error(error);

      });
  }, []); 

const user = {
  avatar: '/static/images/auth.jpeg',
  email: localStorage.getItem("email"),
  name: localStorage.getItem("user"),
  group: localStorage.getItem("group")
};

  return (
    <>
      <main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover  opacity1"
            style={{
              backgroundImage: 'url(' + user.avatar + ')',               
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
            
            <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
              <div className="py-6 px-3 mt-32 sm:mt-0">
                <FiCardActions className={classes.fiCardContent}>
                  <AcProfileUpload />
                </FiCardActions>                     
              </div>
            </div>  
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">                  
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-start -mt-12">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-start">
                    <div className="relative">
                      <img
                        alt="..."
                        src={avatar1 ? avatar1.cover : ''}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16"
                        style={{ maxWidth: "150px", border: "3px solid #c9c9c9" }}
                      />
                    </div>
                  </div>                                    
                </div>
               
                <div className="text-center mt-12">    
                <Box style={{ textAlign: 'justify', marginLeft: '200px', marginTop: '80px'}}>           
                <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  Name: <strong style={{ marginLeft: '10px'}}>{user.name}</strong>
                </h3>                                   
                  <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  E-mail ID: <strong style={{ marginLeft: '10px'}}>{user.email}</strong>
                  </h3>
                  <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  Group: <strong style={{ marginLeft: '10px'}}>{edituser.group}</strong>
                  </h3>                  
                  <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  Organization: <strong style={{ marginLeft: '10px'}}>{edituser.organization}</strong>
                  </h3>  
                  <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  Address: {edituser.address ? <strong style={{ marginLeft: '10px'}}>{edituser.address}</strong>: <span style={{ marginLeft: '20px'}}>Nil</span>} <ModifyDetails topicname="Address"/>
                  </h3>  
                  <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  Country: {edituser.country ? <strong style={{ marginLeft: '10px'}}>{edituser.country}</strong> : <span style={{ marginLeft: '20px'}}>Nil</span>} <ModifyDetails topicname="Country"/>
                  </h3>  
                  <h3 className="text-1xl leading-normal mb-2 text-gray-800 mb-2">
                  Currency: {edituser.currency ? <strong style={{ marginLeft: '10px'}}>{edituser.currency}</strong>: <span style={{ marginLeft: '20px'}}>Nil</span>}
                  </h3>                                                                   
                  </Box>          
                  <div className="flex flex-wrap mb-4 text-gray-700 justify-end">
                    <Changepass />
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}