import Card from '@material-tailwind/react/Card';
import CardImage from '@material-tailwind/react/CardImage';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import NavLink from '@material-tailwind/react/NavLink';
import H4 from '@material-tailwind/react/Heading4';
import H6 from '@material-tailwind/react/Heading6';
import LeadText from '@material-tailwind/react/LeadText';
import Typography from "@material-ui/core/Typography";
import StatusCard from 'src/components/landing/StatusCard';
import Teamwork from 'src/assets/img/teamjoy.jpg';


export default function WorkingSection() {
    return (
        <section className="pb-20 bg-gray-100 -mt-32">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap relative z-50">

                    <StatusCard color="red" path="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" title="Right Pricing">
                    No hidden charges, no suspicious plans, no conspiracy theories, Just pay per Usage.
                    </StatusCard>
                    <StatusCard
                        color="lightBlue"
                        path="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        title="Easy to use"
                    >                        
                    SandboxAsService has an easy-to-use UI and dashboard to Manage Sandbox environments.
                    </StatusCard>
                    <StatusCard
                        color="teal"
                        path="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        title="Schedule"
                    >
                        Can schedule the powering on/off of Sandbox environments on a specific day and time.
                    </StatusCard>
                </div>

                <div className="flex flex-wrap items-center mt-32">
                    <div className="w-full md:w-6/12 px-4 mx-auto">
                        <div className="text-blue-gray-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                        </svg>
                        </div>
                        <H4 color="gray">SandboxAsService</H4>
                        {/* <LeadText color="blueGray"> */}
                        <LeadText color="blueGray">
                        Sandboxing is a software management strategy where you create an isolated test environment within a system. It allows a program to perform specific actions without damaging the system.
                        Companies worldwide are looking to connect and modernize their processes to be abreast of the fast-changing market demands. So, every organization, including those who work in the virtual world needs safe testing and optimization of its operations.
                        
                        <br />
                        <br />

                        Using sandboxing, you can:
                        <LeadText className="ul">                                                                  
                        <li>Foster collaboration during the development
                        With a robust sandbox, different players in your organization can implement new features, verify requirements, and offer their input seamlessly and quickly.
                        </li>
                        
                        <li>
                        Minimize disruption and risks
                        Using sandboxing, you can change features within a separate platform that has clear IT rules. Therefore, you can’t encounter interruptions related to any change along the way.
                        </li>
                        
                        <li>
                        Facilitate IT governance
                        Client developers get a chance to collaborate with your IT teams during the project development. Therefore, you gain the best way of governance.
                        </li>                        
                        </LeadText>
                        </LeadText>
                        {/* </LeadText> */}
                        
                            <a
                                href="https://www.zippyops.com/sandboxasservice"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                                className="font-medium text-light-blue-500 mt-2 inline-block"
                            >
                                Read More 
                            </a>
                    </div>

                    <div className="w-full md:w-6/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
                        <Card>
                            <CardImage alt="Card Image" src={Teamwork} />
                            <CardBody>
                                <H6 color="gray">Customized sandbox avaliable on request</H6>
                                <Typography color="blueGray">
                                The shoe that fits one person pinches another; there is no recipe for living that suits all cases. We also offer customized lab that meets your need.
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
}
