import React, { useState } from 'react';
import { makeStyles } from "@material-ui/styles";
import Dialog from '@material-ui/core/Dialog';
import Button from "@material-ui/core/Button";
import DialogContent from '@material-ui/core/DialogContent';
import {TextField, Box} from "@material-ui/core";
import { Bold } from 'react-feather';

const user = {  
  avatar1: '/static/images/avatars/zippy.png',
  name: localStorage.getItem("user"),
  email: localStorage.getItem("email")
};

const useStyles = makeStyles(theme => ({
    root: {
     margin: '2px',
     width: 300    
    },

    btncenter: {
      display: "flex",
      justifyContent: "center",
      padding: "10px 30px",
      paddingLeft: 10,
    },

    label: {
      textAlign: 'center',
      fontSize: '1em',
      paddingBottom: '12px'
    },
   
    text: {
      textAlign: 'center'
    },
    
    header: {
      display: 'flex',
      color:'black',
      paddingTop: '1rem',
      fontSize: 20,
      font: Bold,
      flexDirection: 'column',
      alignItems: 'center',
    },
}));
  
const customContentStyle = {
    width: '100%',
    maxWidth: 'none',
};


function ImageUpload() {
  const classes = useStyles();
  const [ cover, setCever ] = useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const newBook = () => {
    const uploadData = new FormData();
    uploadData.append("cover", cover, cover.name);
    uploadData.append("user", user.name);
    const data = {   
      'cover': uploadData,
    }
    fetch(`${process.env.REACT_APP_API_URL}/api/profilebook/`, {
      method: 'POST',      
      body: uploadData
    })
    .then(res => console.log(res))
    .then((data) => 
        console.log(data),
        window.location.reload()
    )
    .catch((error) => {
      console.log(error);
      window.location.reload();
    });          
  }     

  const [avatar, setAvatar] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/usrimage/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setAvatar(resp[0]))
      .catch((error) => {
        console.error(error);

      });
  }, []);

  return (
    <>
          <button
           className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
           type="button"
           style={{ transition: "all .15s ease" }}
           onClick={handleClickOpen}
          >
            Upload profile
          </button>
    <Dialog open={open} onClose={handleClose} contentStyle={customContentStyle} fullWidth maxWidth="sm">
    <Box textAlign="center">
          <img src={avatar ? avatar.cover : ''} height="100px" />
      <DialogContent>       
      <TextField
       name="Cover"
       fullWidth
       placeholder="upload the image"
       sx={{textAlign: 'center', alignItems: 'center'}}
       type="file" 
       onChange={(evt) => setCever(evt.target.files[0])}
       />
      <div className={classes.btncenter}>
      <Button
      disabled={cover ? false : true}
       color="primary"
       variant="contained"
       onClick={()=>newBook()}
      >Save
      </Button>
      </div>
    </DialogContent>
    </Box>
    </Dialog>
    </>
  );
}

export default ImageUpload;