import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LabelTwoToneIcon from "@material-ui/icons/LabelTwoTone";

import Application from "./InfoCard/Application";
import Applicationprice from "./InfoCard/Applicationprice";
import Runninghours from "./InfoCard/Runninghours";
import Storageprice from "./InfoCard/Storageprice";
import Typeprice from "./InfoCard/Typeprice";

const CurrentSchedule = (props) => {
  const {
    name,
    app,
    appPrice,
    type,
    storage,
    loadBalancer,
    overallprice,
    runninghours,
  } = props;
  const [open, setOpen] = React.useState(false);
  const fullWidth = true;
  const maxWidth = "md";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>
        <InfoIcon />
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          <Chip
            color="primary"
            icon={<LabelTwoToneIcon />}
            label={`Sandbox name: ${name}`}
          />{" "}
          (<span style={{ color: "red" }}>Total Cost : ₹{overallprice}</span>)
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: "100%",
              py: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg={3} sm={6} xl={4} xs={12}>
                <Application app={app} />
              </Grid>
              <Grid item lg={3} sm={6} xl={4} xs={12}>
                <Runninghours runninghours={runninghours} />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              minHeight: "100%",
              py: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg={3} sm={6} xl={6} xs={12}>
                <Applicationprice appPrice={appPrice} />
              </Grid>
              <Grid item lg={3} sm={6} xl={6} xs={12}>
                <Typeprice type={type} />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              minHeight: "100%",
              py: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg={3} sm={6} xl={6} xs={12}>
                <Storageprice storage={storage} />
              </Grid>              
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CurrentSchedule;
