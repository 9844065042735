import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Button } from "@material-ui/core";

const Three = (props) => {
  const { userlogin } = props;

  const [termsconditions, setTermsconditions] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClose = () => {
    setTermsconditions(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (termsconditions) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [termsconditions]);

  const [ismulti, setIsmulti] = React.useState(false);

  React.useEffect(() => {
    if (userlogin === "newuser") {
      setTermsconditions(true);
      setScroll("paper");
    }
  }, [userlogin]);

  const Submit = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/firstlogin/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: "",
    })
      .then((response) => response.json())
      .then((data) => {
        setTermsconditions(false);
        window.localStorage.setItem("userLogin", "success");
      })
      .catch((error) => {
        window.location.reload();
      });
  };
  const logout = () => {
    setTermsconditions(false);
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <Dialog
        style={{
          height: "100%",
        }}
        open={termsconditions}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundImage:
              "linear-gradient( -45deg, #FFA000 0%, #EC407A 33%, #EC407A 100%)",
            color: "#ffff",
            fontSize: 18,
          }}
        >
          Terms And Conditions
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 15,
              marginTop: 0,
              webkitTextStrokeWidth: "thin",
            }}
          >
            ZIPPYOPS CONSULTING SERVICES LLP. AND/OR ITS AFFILIATES ("ZIPPYOPS")
            IS WILLING TO GRANT ACCESS TO THE SAAS PRODUCTS TO YOU AS THE
            COMPANY OR THE LEGAL ENTITY THAT WILL BE UTILIZING THE SAAS PRODUCTS
            (REFERENCED BELOW AS "CUSTOMER") ON THE CONDITION THAT YOU ACCEPT
            ALL OF THE TERMS OF THIS AGREEMENT (AS DEFINED BELOW). BY ENTERING
            INTO THIS AGREEMENT ON BEHALF OF AN ENTITY OR ORGANIZATION, YOU
            REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ENTITY OR
            ORGANIZATION TO THIS AGREEMENT. CUSTOMER AND ZIPPYOPS MAY EACH ALSO
            BE REFERRED TO AS A "PARTY" AND TOGETHER, THE "PARTIES".
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
            }}
          >
            PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE LABASSERVICE
            PRODUCTS. THIS LABASSERVICE TERMS OF SERVICE (“AGREEMENT”)
            CONSTITUTES A LEGAL AND ENFORCEABLE CONTRACT BETWEEN CUSTOMER AND
            ZIPPYOPS. BY INDICATING CONSENT ELECTRONICALLY, OR ACCESSING OR
            OTHERWISE USING THE LABASSERVICE PRODUCTS, CUSTOMER AGREES TO THE
            TERMS AND CONDITIONS OF THIS AGREEMENT. IF CUSTOMER DOES NOT AGREE
            TO THIS AGREEMENT, DO NOT INDICATE CONSENT ELECTRONICALLY AND MAKE
            NO FURTHER USE OF THE LABASSERVICE PRODUCTS.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
            }}
          >
            1. Access and Use
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.1. Access and Use. Subject to payment of all applicable fees set
            forth in the Order or payment in accordance with an Indirect Order
            through a Channel Partner (as appropriate) and the terms and
            conditions of this Agreement, ZippyOPS grants Customer, during the
            Subscription Term, a non-exclusive, non-transferable right to access
            and use (and permit Authorized Users to access and use) the
            LabAsService Products and applicable Documentation solely for
            Customer’s and its Affiliates’ internal business purposes in
            accordance with the Documentation and in the quantity specified in
            the applicable Order.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.2. Access and Use Restrictions. Customer shall not (directly or
            indirectly): (a) copy or reproduce the LabAsService Products or the
            Documentation except as permitted under this Agreement; (b) exceed
            the subscribed quantities, users or other entitlement measures of
            the LabAsService Products as set forth in the applicable Order; (c)
            remove or destroy any copyright, trademark or other proprietary
            marking or legends placed on or contained in the LabAsService
            Products, Documentation or ZippyOPS Intellectual Property; (d)
            assign, sell, resell, sublicense, rent, lease, time-share,
            distribute or otherwise transfer the rights granted to Customer
            under this Agreement to any third party except as expressly set
            forth herein; (e) modify, reverse engineer or disassemble the
            LabAsService Products; (f) except to the limited extent applicable
            laws specifically prohibit such restriction, decompile, attempt to
            derive the source code or underlying ideas or algorithms of any part
            of the LabAsService Products, attempt to recreate the LabAsService
            Products or use the LabAsService Products for any competitive or
            benchmark purposes; (g) create, translate or otherwise prepare
            derivative works based upon the LabAsService Products, Documentation
            or ZippyOPS Intellectual Property; (h) interfere with or disrupt the
            integrity or performance of the LabAsService Products; (i) attempt
            to gain unauthorized access to the LabAsService Products or its
            related systems or networks, or perform unauthorized penetrating
            testing on the LabAsService Products; (j) use the LabAsService
            Products in a manner that infringes on the Intellectual Property
            rights, publicity rights, or privacy rights of any third party, or
            to store or transfer defamatory, trade libelous or otherwise
            unlawful data; or (k) store in or process with the LabAsService
            Products any personal health data, credit card data, personal
            financial data or other such sensitive regulated data not required
            by the Documentation, or any Customer Data that is subject to the
            International Traffic in Arms Regulations maintained by the United
            States Department of State. Fees for the LabAsService Products are
            based on use of the LabAsService Products in a manner consistent
            with the Documentation. If Customer uses the LabAsService products
            in a manner that is outside or in violation of the Documentation,
            then Customer will cooperate with ZippyOPS to address any applicable
            burden on the LabAsService Products or pay an additional mutually
            agreed upon fee.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.3. Login Access to the LabAsService Products. Customer is solely
            responsible for ensuring: (i) that only appropriate Authorized Users
            have access to the LabAsService Products, (ii) that such Authorized
            Users have been trained in proper use of the LabAsService Products,
            and (iii) proper usage of passwords, tokens and access procedures
            with respect to logging into the LabAsService Products. ZippyOPS
            reserves the right to refuse registration of, or to cancel, login
            IDs that it reasonably believes to violate the terms and conditions
            set forth in this Agreement, in which case ZippyOPS will promptly
            inform Customer in writing of such refusal or cancellation. In
            addition to the rights set forth in this Agreement, ZippyOPS may
            suspend Customer’s access and use of the LabAsService Products if
            there is an unusual and material spike or increase in Customer’s use
            of the LabAsService Products and ZippyOPS reasonably suspects or
            knows that such traffic or use is fraudulent or materially and
            negatively impacting the operating capability of the LabAsService
            Products. ZippyOPS will provide notice prior to such suspension if
            permitted by applicable law or unless ZippyOPS reasonably believes
            that providing such notice poses a risk to the security of the
            LabAsService Products. ZippyOPS will promptly reinstate Customer’s
            access and use once the issue has been resolved.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.4. Trial Services. If Customer is using a free trial, a proof of
            concept version of the LabAsService Products, a beta version of the
            LabAsService Products, or using the LabAsService Products on any
            other free-of-charge basis as specified in an Order including any
            related support services to the extent provided by ZippyOPS in its
            sole discretion (collectively, “Trial Services”), ZippyOPS makes
            such Trial Services available to Customer until the earlier of: (i)
            the end of the free trial or proof of concept period or beta testing
            period as communicated by ZippyOPS or specified in an Order; (ii)
            the start date of any purchased version of such LabAsService
            Products; or (iii) written notice of termination from ZippyOPS
            (“Trial Services Period”). ZippyOPS grants Customer, during the
            Trial Services Period, a non-exclusive, non-transferable right to
            access and use the Trial Services for Customer’s internal evaluation
            purposes in accordance with the Documentation and subject to the
            access and use restrictions set forth in this Agreement. Customer is
            authorized to use Trial Services only for evaluation and not for any
            business or productive purposes, unless otherwise authorized by
            ZippyOPS in writing. Any data Customer enters into the Trial
            Services and any configurations made to the Trial Services by or for
            Customer during the term of such Trial Services will be permanently
            lost unless Customer: (a) has purchased a subscription to the same
            LabAsService Products as covered by the Trial Services; or (b)
            exports such data or configurations before the end of such free
            period. There is no guarantee that features or functions of the
            Trial Services will be available, or if available will be the same,
            in the general release version of the LabAsService Products, and
            Customer should review the LabAsService Products features and
            functions before making a purchase. ZippyOPS will be under no
            obligation to provide Customer any maintenance or support services
            with respect to the Trial Services. Notwithstanding anything to the
            contrary, ZippyOPS provides the Trial Services “as is” and “as
            available” without any warranties or representations of any kind. To
            the extent permitted by law, ZippyOPS disclaims all implied
            warranties and representations, including, without limitation, any
            implied warranty of merchantability, fitness for a particular
            purpose and non-infringement. Customer assumes all risks and all
            costs associated with its use of the Trial Services. Customer’s sole
            and exclusive remedy in case of any dissatisfaction or ZippyOPS’s
            breach of the Agreement with respect to such Trial Services is
            termination of the Trial Services. Any obligations on behalf of
            ZippyOPS to indemnify, defend, or hold harmless under this Agreement
            are not applicable to Customers using Trial Services.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.5. Third Party Materials. The LabAsService Products include
            Third-Party Materials, use of which is subject to their respective
            OSS Licenses as indicated in the Documentation. ZippyOPS warrants
            that the inclusion of such Third-Party Materials in the LabAsService
            Products will not prevent Customer from exercising the license
            rights provided to Customer herein in respect of the LabAsService
            Products or limit Customer’s ability to use the LabAsService
            Products in accordance with the Documentation. Nothing herein shall
            derogate from mandatory rights Customer may have under any OSS
            Licenses, if any. Customer may obtain a copy of the source code for
            certain Third-Party Materials by following the instructions set
            forth in the Documentation.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.6. Support. As part of its provision of the LabAsService Products,
            ZippyOPS shall make available technical support to Customer in
            accordance with ZippyOPS’ s then applicable LabAsService support
            terms. Upon notification from ZippyOPS, Customer shall promptly
            update any Agents on Customer systems that interact with the
            LabAsService Products. Customer acknowledges and agrees that its
            failure to timely install such an update may result in disruptions
            to or failures of the LabAsService Products, security risks or
            suspension of Customer’s access to the LabAsService Products,
            without any liability on the part of ZippyOPS to Customer.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            1.7. Mobile Applications. With regard to LabAsService Products that
            require the use of mobile applications by an Authorized User,
            Customer shall ensure that all Authorized Users promptly download
            and install all available updates for the mobile applications.
            Customer further acknowledges and agrees that the LabAsService
            Products may not properly operate should any Authorized User fail to
            do so, and that ZippyOPS is not liable for any damages caused by a
            failure to update mobile applications accordingly.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            2. Payment and Taxes
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            2.1. Payment Terms. Customer shall pay all invoices within thirty
            (30) days of date of invoice, without any deduction or set-off
            (except for any amount disputed promptly and in writing by Customer
            in good faith), and payment will be sent to the address specified by
            ZippyOPS. Any amounts arising in relation to this Agreement not paid
            when due will be subject to a late charge of one and one-half
            percent (1 ½ %) per month on the unpaid balance or the maximum rate
            allowed by law, whichever is less. Without prejudice to Customer’s
            rights set out elsewhere in this Agreement, all LabAsService
            Products fees are non-refundable and payable in advance. ZippyOPS
            may invoice for purchases of LabAsService Products upon delivery.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            2.2. Taxes. The fees and charges covered by this Agreement are
            exclusive of any Indirect Taxes imposed or levied, currently or in
            the future based on applicable legislation, on the LabAsService
            Products. Unless otherwise agreed between the Parties, Customer will
            be liable for compliance with reporting and payment of such Indirect
            Taxes in its tax jurisdiction. ZippyOPS shall include the Indirect
            Taxes on its invoice to Customer and remit such Indirect Taxes
            collected to the relevant authority if required by applicable law.
            For the avoidance of doubt, ZippyOPS will be responsible for direct
            taxes imposed on ZippyOPS’s net income or gross receipts in its tax
            jurisdiction.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            2.3. Indirect Orders. If Customer places an Indirect Order, then
            ZippyOPS grants the rights described in this Agreement in
            consideration for and subject to: (a) Customer’s agreement to comply
            with the pricing and payment terms of the Indirect Order, to be
            separately agreed between Customer and the applicable Channel
            Partner; and (b) Customer’s agreement to comply with its obligations
            set forth in this Agreement (including the restrictions on use of
            the LabAsService Products). Notwithstanding the foregoing, the final
            sales price or rate shall be freely and independently determined
            between the applicable Channel Partner and Customer. For the
            avoidance of doubt, in the case of such an Indirect Order, any
            indication in this Agreement of an agreement between Customer and
            ZippyOPS for the price payable by Customer for such Indirect Order
            shall be null and void and not form a binding part of this Agreement
            and the provisions of this Agreement related to payment terms,
            pricing and/or order procedures shall not apply.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            3. Rights in Intellectual Property
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            3.1. Intellectual Property. Except for the rights granted in this
            Agreement, all rights, title, and interest in and to the
            LabAsService Products, Documentation, and ZippyOPS Intellectual
            Property are hereby reserved by ZippyOPS, its Affiliates or
            licensors. Except as provided for herein, all rights, title, and
            interest in and to Customer Intellectual Property are hereby
            reserved by Customer, its Affiliates or licensors. Nothing in this
            Agreement shall transfer ownership of any Intellectual Property
            rights from one Party to the other.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            3.2. Customer Data. Customer owns all right, title and interest in
            all Customer Data. Nothing in this Agreement shall be construed to
            grant ZippyOPS any rights in Customer Data beyond those expressly
            provided herein. Customer grants ZippyOPS and its Affiliates the
            limited, non-exclusive, worldwide license to view and use the
            Customer Data solely for the purpose of providing the LabAsService
            Products.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            3.3. Usage Data and Suggestions. ZippyOPS shall be permitted to
            collect and use the Usage Data for its reasonable business purposes
            and for Customer’s benefit. In the event ZippyOPS wishes to disclose
            the Usage Data or any part thereof to third parties (either during
            the Subscription Term or thereafter), such data shall be anonymized
            and/or presented in the aggregate so that it will not identify
            Customer or its Authorized Users. The foregoing shall not limit in
            any way ZippyOPS’s confidentiality obligations pursuant to section 4
            below. To the extent that Customer provides ZippyOPS with
            Suggestions, such Suggestions shall be free from any confidentiality
            restrictions that might otherwise be imposed upon ZippyOPS pursuant
            to this Agreement, and may be implemented by ZippyOPS in its sole
            discretion. Customer acknowledges that any ZippyOPS products or
            materials incorporating any such Suggestions shall be the sole and
            exclusive property of ZippyOPS.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            4. Confidentiality
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            4.1. Confidential Information. The Parties acknowledge that each may
            disclose certain valuable confidential and proprietary information
            to the other Party. The receiving Party may only use the disclosing
            Party’s Confidential Information to fulfill the purposes of this
            Agreement. The receiving Party will protect the disclosing Party’s
            Confidential Information by using at least the same degree of care
            as the receiving Party uses to protect its own Confidential
            Information of a like nature (but no less than a reasonable degree
            of care) to prevent the unauthorized use, dissemination, disclosure
            or publication of such Confidential Information. Notwithstanding the
            foregoing, the receiving Party may disclose Confidential Information
            to its (and its Affiliates) employees, advisors, consultants, and
            agents on a need-to-know basis and provided that such party is bound
            by obligations of confidentiality substantially similar to those
            contained herein. This section 4 supersedes any and all prior or
            contemporaneous understandings and agreements, whether written or
            oral, between the Parties with respect to Confidential Information
            and is a complete and exclusive statement thereof. Additionally, the
            obligations set forth in section 5.3 and not this section 4 herein
            apply to Customer Data.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            4.2. Exceptions. Information will not be deemed Confidential
            Information if it: (i) is known to the receiving Party prior to
            receipt from the disclosing Party directly or indirectly from a
            source other than one having an obligation of confidentiality to the
            disclosing Party; (ii) becomes known (independently of disclosure by
            the disclosing Party) to the receiving Party directly or indirectly
            from a source other than one having an obligation of confidentiality
            to the disclosing Party; (iii) becomes publicly known or otherwise
            ceases to be secret or confidential, except through a breach of this
            Agreement by the receiving Party; or (iv) is independently developed
            by the receiving Party without use of or reliance upon the
            disclosing Party’s Confidential Information, and the receiving Party
            can provide evidence to that effect. The receiving Party may
            disclose Confidential Information pursuant to the requirements of a
            court, governmental agency or by operation of law but shall (to the
            extent permissible by law) limit such disclosure to only the
            information requested and give the disclosing Party prior written
            notice sufficient to permit the disclosing Party to contest such
            disclosure.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            4.3. Advertising and Publicity. Neither Party shall make or permit
            to be made any public announcement concerning the existence, subject
            matter or terms of this Agreement or relationship between the
            Parties without the prior written consent of the other Party except
            as expressly permitted in this section. Customer grants ZippyOPS and
            its Affiliates during the term of the Agreement the right to use
            Customer’s trade names, logos, and symbols (“Customer Marks”) in its
            public promotional materials and communications for the sole purpose
            of identifying Customer as a ZippyOPS customer. ZippyOPS shall not
            modify the Customer Marks, or display the Customer Marks any larger
            or more prominent on its promotional materials than the names,
            logos, or symbols of other ZippyOPS customers. The foregoing
            promotional materials and communications may be created, displayed,
            and reproduced without Customer’s review, provided that they are in
            compliance with this section and any Customer Marks usage guidelines
            provided by Customer to ZippyOPS in writing.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            5. Security and Processing of Personal Data
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            5.1. Customer Data Content. As between ZippyOPS and Customer,
            Customer is solely responsible for: (i) the content, quality and
            accuracy of Customer Data as made available by Customer and by
            Authorized Users; (ii) providing notice to Authorized Users with
            regards to how Customer Data will be collected and used for the
            purpose of the LabAsService Products; (iii) ensuring Customer has a
            valid legal basis for processing Customer Data and for sharing
            Customer Data with ZippyOPS (to the extent applicable); and (iv)
            ensuring that the Customer Data as made available by Customer
            complies with applicable laws and regulations including Applicable
            Data Protection Laws.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            5.2. Data Protection Laws. The Parties shall comply with their
            respective obligations under the Applicable Data Protection Laws.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            5.3. Security of Customer Data. ZippyOPS shall: (i) ensure that is
            has in place appropriate administrative, physical and technical
            measures designed to protect the security and confidentiality of
            Customer Data against any accidental or illicit destruction,
            alteration or unauthorized access or disclosure to third parties;
            (ii) have measures in place designed to protect the security and
            confidentiality of Customer Data; and (iii) access and use the
            Customer Data solely to perform its obligations in accordance with
            the terms of this Agreement, and as otherwise expressly permitted in
            this Agreement. ZippyOPS shall not materially diminish its security
            controls with respect to Customer Data during a particular
            LabAsService Products term.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            6. Warranties
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            6.1. Limited LabAsService Products Warranty. During the applicable
            Subscription Term, ZippyOPS warrants that: (a) the LabAsService
            Products will perform in substantial conformity with the
            Documentation; and (b) ZippyOPS will use industry standard measures
            designed to detect viruses, worms, Trojan horses or other unintended
            malicious or destructive code in the LabAsService Products. The
            foregoing warranties are void if the failure of the LabAsService
            Products has resulted from negligence, error, or misuse of the
            LabAsService Products (including use not in accordance with the
            Documentation) by Customer, the Authorized User or by anyone other
            than ZippyOPS. Customer shall be required to report any breach of
            warranty to ZippyOPS within a period of thirty (30) days of the date
            on which the incident giving rise to the claim occurred. ZippyOPS’s
            sole and exclusive liability, and Customer’s sole and exclusive
            remedy, for breach of these warranties will be for ZippyOPS, at its
            expense, to use reasonable commercial efforts to correct such
            nonconformity within thirty (30) days of the date that notice of the
            breach was provided; and, if ZippyOPS fails to correct the breach
            within such cure period, Customer may terminate the affected Order
            and, in such event, ZippyOPS shall provide Customer with a pro-rata
            refund of any unused pre-paid fees paid for the period following
            termination as calculated on a monthly basis for the affected
            LabAsService Products. Without derogating from ZippyOPS’s
            obligations under this Agreement, Customer warrants that it shall
            take and maintain appropriate steps within its control to protect
            the confidentiality, integrity, and security of its Confidential
            Information and Customer Data, including: (i) operating the
            LabAsService Products in accordance with the Documentation and
            applicable law and; and (ii) dedicating reasonably adequate
            personnel and resources to implement and maintain the security
            controls set forth in the Documentation. Customer will be
            responsible for the acts and omissions of its Authorized Users.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            6.2. Compliance with Law. Each Party shall comply with all
            applicable, laws and regulations in connection with the performance
            of its obligations and the exercise of its rights under this
            Agreement.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            6.3. Disclaimer. Any and all warranties, expressed, incorporated or
            implied, are limited to the extent and period mentioned in this
            Agreement. To the maximum extent allowed by applicable law, ZippyOPS
            disclaims (and disclaims on behalf of its licensors and/or
            contributors to any Third-Party Materials) all other warranties,
            conditions and other terms, whether express or implied or
            incorporated into this Agreement by statute, common law or
            otherwise, including the implied conditions and warranties of
            merchantability and fitness for a particular purpose. ZippyOPS will
            have no liability for delays, failures or losses attributable or
            related in any way to the use or implementation of third-party
            software or services not provided by ZippyOPS.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            7. Indemnification
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            7.1. Infringement Indemnity. ZippyOPS shall defend and indemnify
            Customer and/or its Affiliates and their officers, directors and
            employees against all third-party claims, suits and proceedings
            resulting from the violation, misappropriation, or infringement of
            such third party’s patent, copyright, trademark or trade secret
            caused by Customer’s use of the LabAsService Products in accordance
            with this Agreement and the Documentation, and all directly related
            losses, liabilities, damages, costs and expenses (including
            reasonable attorneys’ fees).
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            7.2. Customer Data and Use Indemnity. Customer shall defend and
            indemnify ZippyOPS and/or its Affiliates and their officers,
            directors and employees against any third-party claims, suits and
            proceedings (including those brought by a government entity)
            resulting from: (i) an alleged infringement or violation by the
            Customer Data of such third-party’s patent, copyright, trademark,
            trade secret; or (ii) ZippyOPS’s use of the Customer Data, in
            accordance with the terms of this Agreement and (where applicable)
            with the terms of the DPA, violates applicable law; and all directly
            related losses, liabilities, damages, costs and expenses (including
            reasonable attorneys’ fees).
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            7.3. Process. Each Party’s defense and indemnification obligations
            herein will become effective upon, and are subject to: (a) the
            indemnified Party’s prompt notification to the indemnifying Party of
            any claims in writing; and (b) the indemnified Party providing the
            indemnifying Party with full and complete control, authority and
            information for the defense of the claim, provided that the
            indemnifying Party will have no authority to enter into any
            settlement or admission of the indemnified Party’s wrongdoing on
            behalf of the indemnified Party without the indemnified Party’s
            prior written consent (not to be unreasonably withheld). At the
            indemnifying Party’s request, the indemnified Party shall reasonably
            cooperate with the indemnifying Party in defending or settling any
            claim.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            7.4. Exclusions. The above ZippyOPS obligations to defend and
            indemnify will not apply in the event that a claim arises from or
            relates to: (a) use of the LabAsService Products not in accordance
            with the Documentation and this Agreement; (b) Customer’s use of the
            LabAsService Products in violation of applicable laws; (c) any
            modification, alteration or conversion of the LabAsService Products
            not created or approved in writing by ZippyOPS; (d) any combination
            or use of the LabAsService Products with any computer, hardware,
            software, data or service not required by the Documentation; (e)
            ZippyOPS’s compliance with specifications, requirements or requests
            of Customer; or (f) Customer’s gross negligence or willful
            misconduct.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            {" "}
            7.5. Remedies. If the LabAsService Products becomes, or ZippyOPS
            reasonably determines that the LabAsService Products is likely to
            become, subject to a claim of infringement for which ZippyOPS must
            indemnify Customer as described above, ZippyOPS may at its option
            and expense: (a) procure for Customer the right to continue to
            access and use the LabAsService Products, (b) replace or modify the
            LabAsService Products so that it becomes non-infringing without
            causing a material adverse effect on the functionality provided by
            the infringing LabAsService Products, or (c) if neither of the
            foregoing options are available in a timely manner on commercially
            reasonable terms, terminate the affected Order and provide Customer
            with a pro-rata refund of any unused pre-paid fees paid for the
            period following termination as calculated on a monthly basis for
            the affected LabAsService Product. This section states the sole
            liability of ZippyOPS and the exclusive remedy of Customer with
            respect to any indemnification claims arising out of or related to
            this Agreement.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            8. Limitation of Liability
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            8.1. Maximum Liability. Except for liability caused by ZippyOPS’s
            intellectual property infringement indemnification obligations in
            section 7.1, Customer’s data infringement indemnity in section 7.2,
            and Customer’s payment obligations herein, in no event will either
            Party’s maximum aggregate liability arising out of or related to
            this Agreement, regardless of the cause of action and whether in
            contract, tort (including negligence), warranty, indemnity or any
            other legal theory, exceed the total amount paid or payable to
            ZippyOPS under this Agreement during the twelve (12) month period
            preceding the date of initial claim.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            8.2. No Consequential Damages. Neither Party will have any liability
            to the other Party for any loss of profits or revenues, loss of
            goodwill, or for any indirect, special, incidental, consequential or
            punitive damages arising out of, or in connection with this
            Agreement, however caused, whether in contract, tort (including
            negligence), warranty, indemnity or any other legal theory, and
            whether or not the Party has been advised of the possibility of such
            damages. You acknowledge and agree that neither ZippyOPS nor any of
            its employees, officers, directors, agents or other service
            providers has or will have any liability to you whatsoever (whether
            based in contract, tort, strict liability or otherwise) for any
            direct, indirect, incidental, consequential, or special damages that
            arise out of or are connected in any way with your use of any
            LabAsService Product.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            8.3. Construction. This Agreement is not intended to and will not be
            construed as excluding or limiting any liability which cannot be
            limited or excluded by applicable law, including liability for (a)
            death or bodily injury caused by a Party’s negligence; or (b) gross
            negligence, willful misconduct, or fraud.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            9. Assignment. Neither Party may assign any of its rights or
            obligations under this Agreement without the other Party’s prior
            written consent, which will not be unreasonably withheld.
            Notwithstanding the foregoing, either Party may assign any and all
            of its rights and obligations under this Agreement to a successor in
            interest in the event of a merger or acquisition or to an Affiliate,
            upon written notice to the other Party.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            10. Restricted Rights and Export Control
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            10.1. Export Control. The exportation of the LabAsService Products
            and Documentation, and all related technology and information
            thereof are subject to Indian laws and regulations pertaining to
            export controls and trade and economic sanctions, and the laws of
            any country or organization of nations within whose jurisdiction
            Customer (or its Authorized Users who may use or otherwise receive
            the LabAsService Products as expressly authorized by this Agreement)
            operates or does business, as amended, and the rules and regulations
            promulgated from time to time thereunder. Specifically, Customer
            hereby undertakes not to export, re-export, access or grant access
            to the LabAsService Products and all related technology,
            information, materials and any upgrades thereto to: (a) any
            Prohibited Persons; (b) any country to which such export, re-export
            or access from is restricted or prohibited per the foregoing
            applicable laws; or (c) otherwise in violation of any applicable
            export or import restrictions, laws or regulations. Customer also
            certifies that it is not a Prohibited Person nor owned, controlled
            by, or acting on behalf of a Prohibited Person.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            11. Professional Services. Customer may separately purchase from
            ZippyOPS professional services in relation to the LabAsService
            Products as may be generally available by ZippyOPS to its customers,
            pursuant to ZippyOPS’s then applicable professional services terms.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            12. Term and Termination
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            12.1. Term. This Agreement will be effective upon signature by both
            Parties and shall remain in force during the applicable Subscription
            Term of the LabAsService Products unless or until terminated by
            either Party pursuant to this section.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            12.2. Termination. Either Party may terminate this Agreement
            immediately upon notice to the other Party if the other Party: (i)
            materially breaches this Agreement and fails to remedy such breach
            within thirty (30) days after receiving written notice of the breach
            from the other Party; or (ii) commences bankruptcy or dissolution
            proceedings, has a receiver appointed for a substantial part of its
            assets or ceases to operate in the ordinary course of business. In
            addition, a Party may terminate this Agreement, a SOW, or an Order ,
            in whole or in part, or cease provision of the LabAsService Products
            if required to comply with applicable law or regulation, and such
            termination will not constitute a breach of this Agreement by the
            terminating Party. ZippyOPS reserves the right to suspend Customer’s
            access to the applicable LabAsService Products upon 30 days’ written
            notice to Customer if: (a) an invoice is more than sixty (60) days
            past due; or (b) if there is an uncured material breach of this
            Agreement. ZippyOPS will promptly reinstate Customer’s access and
            use of the LabAsService Products/provision of the Professional
            Services once the issue has been resolved. Upon termination or
            expiration of the Agreement or an Order, (x) any accrued rights and
            obligations will survive; (y) all outstanding fees and other charges
            under the Agreement or Order (as applicable) will become immediately
            due and payable, and (z) Customer will have no further right to
            access or use the applicable LabAsService Products or professional
            services. If Customer is converting its perpetual on-premise
            software licenses to a LabAsService Product, the applicable
            previously licensed perpetual on-premise software licenses will be
            terminated, along with any associated maintenance services, in
            accordance with the terms of the applicable Order.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            12.3. Effects of Termination/Expiration. Upon termination or
            expiration of an applicable Subscription Term: (i) Customer will
            have no further right to access or use the LabAsService Products;
            and (ii) each Party shall within thirty (30) days after written
            request return or destroy any tangible Confidential Information of
            the other Party within its possession or control that is not
            contained on the LabAsService Products. Any Customer Data contained
            on the LabAsService Products will be deleted within sixty (60) days
            of termination/expiration of Customer’s Subscription Term. Customer
            acknowledges that it is responsible for exporting any Customer Data
            to which Customer desires continued access after
            termination/expiration, and ZippyOPS shall have no liability for any
            failure of Customer to retrieve such Customer Data and no obligation
            to store or retain any such Customer Data after such sixty (60) day
            period. Following termination of the LabAsService Products, ZippyOPS
            may immediately deactivate Customer’s account. Any accrued rights
            and obligations will survive termination.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13. Miscellaneous
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.1. Independent Contractors. Nothing in this Agreement will be
            construed to imply a joint venture, partnership or principal-agent
            relationship between ZippyOPS and Customer, and neither Party will
            have the right, power or authority to obligate or bind the other in
            any manner whatsoever.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.2. Notices. All Notices will be in writing and will be deemed to
            have been duly given: (a) when delivered by hand; (b) three (3) days
            after being sent by registered or certified mail, return receipt
            requested and postage prepaid; (c) one (1) day after deposit with a
            nationally recognized overnight delivery or express courier service;
            or (d) when provided via email when the sender has received a
            delivery/read receipt. Notices for ZippyOPS should be sent to the
            following addresses: (i) for physical Notices the address specified
            for ZippyOPS in section 13.4 “Governing Law and Jurisdiction” and;
            (ii) for electronic Notices to: admin@zippyops.com
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.3. Force Majeure. With the exception of Customer’s payment
            obligations herein, neither Party will be liable to the other Party
            for any delay or failure to perform which is due to fire, pandemic,
            virus, epidemic, travel advisories as to health, security and/or
            terrorism, flood, lockout, transportation delay, war, acts of God,
            governmental rule or order, strikes or other labor difficulties, or
            other causes beyond its reasonable control. However, in such event,
            both Parties will resume performance promptly after the cause of
            such delay or failure has been removed.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.4. Governing Law and Jurisdiction. Each Party agrees and
            acknowledges that: (A) this Agreement will be governed by and
            construed in accordance with the laws of the State of Tamilnadu,
            India; and (B) any action at law or in equity arising out of or
            relating to this Agreement shall be filed only in the courts located
            in the State of Tamilnadu, India. Customer hereby consents and
            submits to the personal jurisdiction of such courts for the purposes
            of litigating any such action.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.5. Entire Agreement, Execution, and Modification. This Agreement
            supersedes all prior agreements and representations between the
            Parties regarding the subject matter of this Agreement. The terms
            and conditions contained in any Order issued by Customer will be of
            no force or effect, even if the Order is accepted by ZippyOPS.
            ZippyOPS may make changes to these Terms of Service from time to
            time. If ZippyOPS makes a material change to any of the foregoing,
            ZippyOPS will inform Customer by e-mail to the e-mail address(es)
            noted on the Order (or subsequently designated by Customer in
            writing as a contact for notifications from ZippyOPS), or through a
            banner or other prominent notice within the LabAsService Products,
            or through the ZippyOPS support platform. If Customer does not agree
            to the change, Customer must so notify ZippyOPS by e-mail to
            admin@zippyops.com within thirty (30) days after ZippyOPS’s notice.
            If Customer so notifies ZippyOPS, then Customer will remain governed
            by the most recent terms of service applicable to Customer until the
            end of the then-current year of the Subscription Term and the
            updated terms shall apply upon the commencement of the subsequent
            Subscription Term.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.6. Severability and Waiver. This Agreement shall be deemed
            severable, and the invalidity or unenforceability of any term or
            provision hereof shall not affect the validity or enforceability of
            this Agreement or of any other term or provision hereof. Should any
            term or provision of this Agreement be declared void or
            unenforceable by any court of competent jurisdiction, the Parties
            intend that a substitute provision will be added to this Agreement
            that, to the greatest extent possible, achieves the intended
            commercial result of the original provision. The failure of either
            Party to enforce any rights granted to it hereunder or to take
            action against the other Party in the event of any breach hereunder
            will not be deemed a waiver by that Party as to subsequent
            enforcement of rights or subsequent actions in the event of future
            breaches.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            13.7. Definitions and Interpretation. The following definitions and
            rules of interpretation apply in this Agreement:
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Affiliate” means a company controlling, controlled by, or under
            common control with a Party (an entity will be deemed to have
            control if it owns over 50% of another entity).
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Agents” means ZippyOPS’s proprietary software, systems and
            locally-installed software agents and connectors that interact with
            the LabAsService Products as may be provided by ZippyOPS in
            connection with the LabAsService Products.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Applicable Data Protection Laws” means the EU General Data
            Protection Regulation (2016/679) (“GDPR”), any applicable laws of EU
            member states implementing the GDPR (including the UK Data
            Protection Act 2018), and the California Consumer Privacy Act, in
            each case as amended, consolidated, re-enacted or replaced from time
            to time and only if and insofar as they apply.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Authorized Users” means employees, agents, consultants,
            contractors, or vendors authorized by Customer to use the
            LabAsService Products solely for the internal use of Customer and
            its Affiliates, subject to the terms and conditions of this
            Agreement.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Channel Partner” means a third-party business entity that ZippyOPS
            has appointed as an approved partner to as applicable, distribute,
            re-sell and support the LabAsService Products.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Confidential Information” means all information provided by the
            disclosing Party to the receiving Party concerning the disclosing
            Party or its Affiliates’ business, products or services that is not
            generally known to the public, including information relating to
            customers, vendors, trade secrets, prices, products, services,
            computer programs and other intellectual property and any other
            information which a Party should reasonably understand to be
            considered Confidential Information whether or not such information
            is marked “Confidential” or contains such similar legend by the
            disclosing Party at the time of disclosure.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Customer Data” means all data and/or content uploaded to the
            LabAsService Products by Customer (including where applicable
            Authorized Users), and in all data derived from it, including
            personal data. For the avoidance of doubt, Customer Data does not
            include Usage Data.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “ZippyOPS” means the ZippyOPS legal entity specified on the
            signature line below, at the address specified in section 13.4
            “Governing Law and Jurisdiction.”
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Documentation” means the user guides, installation documents, and
            specifications for the LabAsService Products that are made available
            from time to time by ZippyOPS in electronic or tangible form and
            found at docs.ZippyOPS.com, including the documentation located
            therein under the ‘Security’ section for the relevant LabAsService
            Products, but excluding any sales or marketing materials.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Indirect Order” means excise, sales, use, gross-turnover, value
            added, goods and services tax or other similar types of indirect
            taxes on turnover and/or revenues, duties, customs or tariffs
            (however designated, levied or based and whether foreign or
            domestic, federal, state or province).
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Indirect Taxes” means excise, sales, use, gross-turnover, value
            added, goods and services tax or other similar types of indirect
            taxes on turnover and/or revenues, duties, customs or tariffs
            (however designated, levied or based and whether foreign or
            domestic, federal, state or province).
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Intellectual Property” means a Party’s proprietary material,
            technology, or processes (excluding the LabAsService Products and
            Documentation), including services, software tools, proprietary
            framework and methodology, hardware designs, algorithms, objects and
            documentation (both printed and electronic), network designs,
            know-how, trade secrets and any related intellectual property rights
            throughout the world (whether owned or licensed by a third party)
            and any derivatives, improvements, enhancements or extensions of
            such Intellectual Property conceived, reduced to practice, or
            developed.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Notice” means any notice or other communication required or
            permitted under this Agreement.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Order” means ZippyOPS’s quote accepted by Customer via Customer’s
            purchase order or other ordering document submitted to ZippyOPS
            (directly or indirectly through a Channel Partner) to order
            ZippyOPS’s LabAsService Products, which references the LabAsService
            Products, pricing, payment terms, quantities, expiration date and
            other applicable terms set forth in an applicable ZippyOPS quote or
            ordering document.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “OSS Licenses” means the respective open source licenses that the
            Third-Party Materials are subject to.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Prohibited Persons” means anyone on the U.S. Commerce Department’s
            Denied Persons, Entity, or Unverified Lists or the U.S. Treasury
            Department’s list of Specially Designated Nationals and Consolidated
            Sanctions list.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “LabAsService Products” means the software-as-a-service products
            specified in the Order as further described in the Documentation
            (including any updates and upgrades to the LabAsService Products
            provided by ZippyOPS in its sole discretion, and any software,
            systems and locally-installed software agents and connectors that
            interact with the LabAsService Products as may be provided by
            ZippyOPS in connection with the LabAsService Products), provided
            that any free trial LabAsService software, proof of concept of the
            LabAsService Products, beta version of the LabAsService Products, or
            any other free-of-charge software product (“Trial Version”) will not
            be subject to this Agreement and instead will be governed by the
            applicable terms of service embedded in, or provided with, such
            Trial Version.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Subscription Term” means the period of time during which Customer
            is subscribed to the LabAsService Products, as specified in an Order
            and which shall begin upon delivery of the LabAsService Products.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Suggestions” means, any ideas or suggestions for improvements, new
            features, functionalities, corrections, enhancements or changes to
            the LabAsService Products suggested by Customer to ZippyOPS.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Third-Party Materials” means open source software programs that are
            made available by third parties under their respective OSS Licenses.
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            “Usage Data” means data generated in connection with Customer’s
            access, use and configuration of the LabAsService Products and data
            derived from it (e.g., types of applications or accounts utilized or
            interacting with the LabAsService Products).
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              fontSize: 13,
              marginTop: 0,
              paddingTop: 10,
            }}
          >
            Any words following the terms including or include shall be regarded
            as examples only and not construed as an exhaustive list. Should
            Customer have any questions concerning this Agreement, or if
            Customer desires to contact ZippyOPS for any reason, please e-mail
            us at: admin@zippyops.com.
          </DialogContentText>
          <input
            type="checkbox"
            id="agree"
            onChange={() => setIsmulti(!ismulti)}
          />
          {"  "} I agree to the terms and condition
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} to="/auth/login">
            Cancel
          </Button>

          <Button
            onClick={Submit}
            color="primary"
            variant="contained"
            disabled={ismulti ? false : true}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Three;
