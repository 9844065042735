import React from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";

import {
  Button,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@material-ui/core";

const NavItemdropdown = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const splitLoc = splitLocation[3];
  const splitLoc1 = splitLocation[2];

  return (
    <>
      <List component="div" disablePadding>
        <li className="rounded-lg mb-1 text-gray-700">
          {localStorage.getItem("group") === "admin" ||
          localStorage.getItem("group") === "ops_users" ? (
            <ListItem
              disableGutters
              sx={{
                display: "flex",
                py: 0,
              }}
            >
              <Button
                component={RouterLink}
                sx={{
                  color: "#ffff",
                  fontWeight: "medium",
                  justifyContent: "flex-start",
                  letterSpacing: 0,
                  py: 0.5,
                  textTransform: "none",
                  width: "100%",
                  ...(splitLoc === "instancerequest"
                    ? {
                        backgroundColor: "rgb(6 57 108)",
                        color: "#2cbcd3",
                      }
                    : ""),
                  "& svg": {
                    mr: 1,
                  },
                }}
                to="/app/maindeployment/instancerequest"
              >
                <span>Request</span>
              </Button>
            </ListItem>
          ) : (
            ""
          )}

          <ListItem
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
          >
            <Button
              component={RouterLink}
              sx={{
                color: "#ffff",
                fontWeight: "medium",
                justifyContent: "flex-start",
                letterSpacing: 0,
                py: 0.5,
                textTransform: "none",
                width: "100%",
                ...(splitLoc === "custom"
                  ? {
                      backgroundColor: "rgb(6 57 108)",
                      color: "#2cbcd3",
                    }
                  : ""),
                "& svg": {
                  mr: 1,
                },
              }}
              to="/app/instance/custom"
            >
              <span>Running Sandbox</span>
            </Button>
          </ListItem>

          <ListItem
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
          >
            <Button
              component={RouterLink}
              sx={{
                color: "#ffff",
                fontWeight: "medium",
                justifyContent: "flex-start",
                letterSpacing: 0,
                py: 0.5,
                textTransform: "none",
                width: "100%",
                ...(splitLoc1 === "schedule"
                  ? {
                      backgroundColor: "rgb(6 57 108)",
                      color: "#2cbcd3",
                    }
                  : ""),
                "& svg": {
                  mr: 1,
                },
              }}
              to="/app/schedule"
            >
              <span>Schedule</span>
            </Button>
          </ListItem>
        </li>
      </List>
    </>
  );
};

export default NavItemdropdown;
