import React, { useState, View } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Helmet } from "react-helmet";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Logo from "src/components/Logo";
import Avatar from "@material-ui/core/Avatar";
import Zippyops from "src/icons/zippy.png";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Sanboximg2 from "../../../assets/img/tech5.webp";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  image: {
    backgroundImage: `url(${Sanboximg2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    paddingLeft: "7rem",
    paddingRight: "7rem",
    paddingBottom: "3rem",
  },
  btncenter: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 30px",
    paddingLeft: 10,
  },
}));

export default function RegisterPage(props) {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const { ...rest } = props;

  const [state, setState] = useState({
    email: "",
    password: "",
    errorMessage: "",
    submitted: false,
    handleChange: false,
  });

  localStorage.clear();

  const CLIENT_ID = `${process.env.REACT_APP_CLIENTID}`;
  const responseGoogle = (response) => {
    const google = {
      auth_token: response.credential,
    };
    fetch(`${process.env.REACT_APP_API_URL}/social_auth/google/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(google),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("user", data.username);
        localStorage.setItem("email", data.email);
        localStorage.setItem("group", data.group);
        localStorage.setItem("token", data.tokens.access);
        localStorage.setItem("google", "google");
        if (data.tokens.access) {
          window.location.href = "/admin/index";
        } else {
          if (data.email) {
            setState({ email: "" });
            setState({ username: "" });
            setState({ password: "" });
            setState({ password2: "" });
            setState({ errorMessage: data.email[0] });
          } else {
            setState({ errorMessage: data.detail });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem("token");
      });
  };
  const failureGoogle = (response) => {
    console.log(response);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    setState({ submitted: true });
    if (state.password !== state.password2) {
      setState({ errorMessage: "The passwords doesn't match" });
    } else {
      const register = {
        email: state.email,
        username: state.username,
        password: state.password,
      };
      fetch(`${process.env.REACT_APP_API_URL}/auth/register/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(register),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.email && data.username) {
            handleClickOpenconfirm();
          } else {
            setState({ email: "" });
            setState({ username: "" });
            setState({ password: "" });
            setState({ password2: "" });
            setState({ submitted: false });
            setOpenerror(true);
            if (data.username) {
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ errorMessage: data.username[0] });
              setOpenerror(true);
            } else if (data.email) {
              setOpenerror(true);
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ errorMessage: data.email[0] });
              setOpenerror(true);
            } else if (data.password) {
              setOpenerror(true);
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ errorMessage: data.password[0] });
              setOpenerror(true);
            } else {
              setOpenerror(true);
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ errorMessage: data.errors.email[0] });
              setOpenerror(true);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem("token");
        });
    }
  };

  const [openerror, setOpenerror] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenerror(false);
  };

  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [openconfirm, setOpenconfirm] = React.useState(false);

  const handleClickOpenconfirm = () => {
    setOpenconfirm(true);
  };

  const handleCloseOpenconfirm = () => {
    window.location.href = "/login";
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.container}>
          {state.errorMessage && !state.submitted && (
            <React.Fragment>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openerror}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
                style={{ paddingTop: "40px", paddingRight: "30px" }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleClose}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
                maxSnack={3}
              >
                <Alert onClose={handleClose} severity="error">
                  {state.errorMessage}
                </Alert>
              </Snackbar>
            </React.Fragment>
          )}

          <img
            style={{
              padding: "20px",
              width: "40%",
              height: "40%",
              class: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            alt="..."
            src={require("../../../assets/img/rectcolor.png").default}
          />

          <a
            style={{
              paddingBottom: "13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#757575",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Sign in with
          </a>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GoogleOAuthProvider
              className="loginBtn loginBtn--google"
              onClick={responseGoogle}
              clientId={CLIENT_ID}
              color="default"
              style={{
                marginRight: "0px",
                theme: "#0000FF",
                shape: "circle",
                background: "rgb(255 255 255)",
                padding: "0px",
                borderRadius: "200px",
                mixBlendMode: "overlay",
              }}
            >
              <GoogleLogin onSuccess={responseGoogle} onError={failureGoogle} />
            </GoogleOAuthProvider>
          </div>
          <br />
          <Divider
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "20px",
              color: "#757575",
            }}
          >
            or
          </Divider>
          <form className={classes.form}>
            <TextField
              display="block"
              marginRight="auto"
              marginLeft="auto"
              variant="outlined"
              required
              fullWidth
              id="email"
              size="small"
              name="email"
              autoComplete="email"
              placeholder="Email"
              style={{
                padding: 10,
              }}
              value={state.email}
              onChange={handleChange}
              validate={{
                email: {
                  value: true,
                  errorMessage: "Please enter a valid email id",
                },
              }}
              autoFocus
            />
            <br />
            <TextField
              position="relative"
              bottom="25px"
              variant="outlined"
              required
              fullWidth
              size="small"
              style={{
                padding: 10,
              }}
              name="username"
              placeholder="Name"
              type="text"
              id="username"
              value={state.username}
              autoComplete="username"
              onChange={handleChange}
              autoFocus
            />

            <TextField
              autoComplete="current-password"
              name="password"
              variant="outlined"
              placeholder="Password"
              required
              fullWidth
              style={{
                padding: 10,
              }}
              type="password"
              id="password"
              size="small"
              autoFocus
              onChange={handleChange}
              value={state.password}
              validate={{
                pattern: {
                  value:
                    "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
                  errorMessage:
                    "Requires min 8 char, 1 upper case, 1 numeric and 1 special char",
                },
              }}
            />

            <TextField
              variant="outlined"
              required
              fullWidth
              size="small"
              id="password2"
              placeholder="Confirm Password"
              name="password2"
              style={{
                padding: 10,
              }}
              onChange={handleChange}
              value={state.password2}
              autoComplete="current-password"
              type="password"
              validate={{
                pattern: {
                  value:
                    "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
                  errorMessage:
                    "Requires min 8 char, 1 upper case, 1 numeric and 1 special char",
                },
              }}
            />
            <div className={classes.btncenter}>
              <Button
                simple
                onClick={handleSubmitClick}
                disabled={state.submitted && buttonDisabled ? true : false}
                variant="contained"
                style={{ color: "#ffffff", backgroundColor: "#e91e63" }}
                size="lg"
              >
                Create Account&nbsp;
                {state.submitted && <i class="fas fa-sync fa-spin"></i>}
              </Button>
            </div>

            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "16px",
                color: "#757575",
                fontFamily: "Open Sans",
                fontWeight: 400,
              }}
            >
              <a>
                Already on <strong>SandboxAsService</strong>?{" "}
              </a>
              <Link href="login">
                <h3>
                  {" "}
                  <small>Sign in</small>{" "}
                </h3>
              </Link>
            </div>

            <br />

            <Link
              color="primary"
              href="https://www.zippyops.com/contact-us"
              target="_blank"
              fontFamily="sans-serif"
              textAlign="center"
              marginLeft="auto"
              marginRight="auto"
              display="block"
              fontSize="14px"
            >
              <a>Contact us</a>
            </Link>

            <div>
              <Typography
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#757575",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                }}
              >
                &copy; {1900 + new Date().getYear()} ZippyOPS Consulting
                Services LLP
              </Typography>
            </div>
            <br />
          </form>
          <div
            style={{ alignItems: "center", display: "flex", width: "411px" }}
          >
            <Dialog
              open={openconfirm}
              onClose={handleCloseOpenconfirm}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <strong
                  style={{ color: "rgb(8 217 42)", paddingLeft: "110px" }}
                >
                  Registration Successful
                </strong>
              </DialogTitle>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: 180,
                  width: 411,
                }}
              >
                <Avatar
                  src={<Logo />}
                  sx={{
                    height: 170,
                    width: 170,
                  }}
                >
                  <img alt="Logo" src={Zippyops} style={{ height: "170px" }} />
                </Avatar>
              </Box>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    fontSize: "small",
                    alignItems: "center",
                    paddingLeft: "80px",
                  }}
                >
                  Your verification mail has been sent,
                </DialogContentText>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ paddingLeft: "50px" }}
                >
                  <strong>Please verify the mail with in 24hrs!!!</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseOpenconfirm}
                  color="primary"
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
    </Grid>
  );
}
