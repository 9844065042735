import React from 'react';
import { Helmet } from 'react-helmet';

const Forbidden = () => {
    
    return (
  <React.Fragment>
      <style type="text/css">
    {`
    html,
    body {
      height: 100%;
      width: 100%;
      margin: 0;
    }
    body {
      background: #b4cbc9;
      background-size: 100%;
      line-height: 1.45;
    }
    p {
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 40px;
      letter-spacing: 4px;
      color: #72449e;;
      margin: 3em 0 1em;
      opacity: 0;
      animation: fadedown .5s ease-in-out;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
    
    h1 {
      color: red;
      font-family: 'squada one';
      text-transform: uppercase;
      font-size: 45px;
      margin: 0;
      transform: scale(0);
      animation: zoomin 1s ease-in-out;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }
    
    h1 span {
      display: inline-block;
    }
    
    h1 + p {
      font-size: 20px;
      letter-spacing: 2px;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      animation-delay: 3.5s;
    }
    
    @keyframes zoomin {
      0% {
        transform: scale(0);
      }
      90% {
        transform: scale(1.1);
      }
      95% {
        transform: scale(1.07);
      }
      100% {
        transform: scale(1);
      }
    }
    
    @keyframes fadedown {
      0% {
        opacity: 0;
        transform: translate3d(0,-10px,0);
      }
      90% {
        transform: translate3d(0,1px,0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
    `}</style>  
    <Helmet>
      <title>Access Denied</title>
    </Helmet>
    <p style={{ textAlign: 'center'}}>403</p>
<h1 style={{ textAlign: 'center'}}>Access Denied</h1>
  </React.Fragment>
)
}

export default Forbidden;
