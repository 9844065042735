import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box, Button, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function a11yProps(value) {
  if (!value) {
    return {
      disabled: true,
      style: { backgroundColor: "#ececec" },
    };
  }
}

export default function Schedule(props) {
  const { onState, activeStep, handleBack, handleSubmitClick, classes } = props;

  const [temporary, settemporary] = React.useState([]);

  const [checkstate, setCheckstate] = React.useState({});

  const handlecheckChange = (event) => {
    setCheckstate({ ...checkstate, [event.target.name]: event.target.checked });
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    onState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <React.Fragment>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h6" gutterBottom>
          {" "}
          Notification{" "}
        </Typography>
      </Box>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkstate.checkedB}
              onChange={handlecheckChange}
              name="checkedB"
              color="secondary"
            />
          }
          label={
            <Typography variant="h6"> Receive Labs Details Alerts </Typography>
          }
        />
      </Grid>
      <Typography variant="h8">
        By default lab start {"&"} stop will be sent to the creating user. if
        you want to send the notification to additional users please specify the
        email in address below.
      </Typography>
      <Grid item xs={12} sx={{ pt: 4 }}>
        <Grid item md={6} xs={12}>
          <Typography variant="h6"> Email Address: </Typography>
          <TextField
            fullWidth
            name="email"
            id="email"
            onChange={handleChange}
            required
            type="email"
            placeholder={localStorage.getItem("email")}
            {...a11yProps(checkstate.checkedB)}
            variant="outlined"
            InputProps={{
              style: {
                height: "40px",
              },
            }}
          ></TextField>
        </Grid>
        <Typography variant="h5">
          <small>
            Note: Please specify the email in comma-seperated format.
          </small>
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReload}
            className={classes}
          >
            Cancel
          </Button>
        </div>
        <div className={classes}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} className={classes}>
              Back
            </Button>
          )}
          {checkstate.checkedB ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitClick}
              className={classes}
              disabled={temporary.email ? false : true}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitClick}
              className={classes}
            >
              Next
            </Button>
          )}
        </div>
      </Box>
    </React.Fragment>
  );
}
