import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  useTheme,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,  
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const Sales = (props) => {
  const theme = useTheme();

  const [instancelist, setinstancelist] = React.useState([]); 
  React.useEffect(() => {    
    fetch(`${process.env.REACT_APP_API_URL}/api/manageInstance/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {

        } else {
          setinstancelist(data)
        }
      })
      .catch((error) => {
        console.error(error);
      });              
  }, []);

  const CreateInsatnce = () => {
    window.location.href = "/app/instance/custom";
  };

  return (
    <Card {...props}>
      <CardHeader
        title="Last Activity"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 350,
            position: 'relative'
          }}
        >
          <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
                 <TableCell>
                  SandBox Name
                </TableCell>
                <TableCell>
                  Status
                </TableCell>               
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell>
                  End Date
                </TableCell>                 
                <TableCell>
                  Start Time
                </TableCell>
                <TableCell>
                  Stop Time
                </TableCell>   
            </TableRow>
          </TableHead>
          { instancelist.length > 0 ?         
            <TableBody >
              {instancelist
              .slice(0, 5)
              .map((instance) => (
                <TableRow
                  hover
                  key={instance.id}
                >
                  <TableCell>
                    {instance.name}
                  </TableCell>  
                  <TableCell>
                  {instance.status === 'Creating' ?
                    <Chip color="primary" label={instance.status} size="small" />
                  : instance.status === 'Scheduled' ?
                  <Chip color="primary" style={{backgroundColor: 'rgb(44 189 186)'}} label={instance.status} size="small" />
                  : instance.status === 'Running' ?
                    <Chip color="primary" style={{backgroundColor: '#00a200'}} label={instance.status} size="small" />
                  : instance.status === 'Stopped' ? 
                    <Chip color="primary" style={{backgroundColor: 'rgb(228 193 3 / 94%)'}} label={instance.status} size="small" />
                  : <Chip color="primary" style={{backgroundColor: 'rgb(228 3 3 / 94%)'}} label={instance.status} size="small" /> }
                  </TableCell>                                                  
                  <TableCell>
                    {instance.start_date}
                  </TableCell>
                  <TableCell>
                    {instance.end_date}
                  </TableCell>
                  <TableCell>
                  {instance.start_time}
                  </TableCell>
                  <TableCell>
                  {instance.stop_time}
                  </TableCell>                                  
                </TableRow>
              ))}
            </TableBody> 
            : 
            <TableBody align='center'>
              <TableCell > 
                No Data Available
              </TableCell>  
            </TableBody>}
        </Table>
        </TableContainer>
        </Box>
      </CardContent>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="contained"
          onClick={CreateInsatnce}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default Sales;