import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function PositionedSnackbar(props) {
  const { setAlertstate } = props;
  const [state, setState] = React.useState({
    open: true,
    vertical: "bottom",
    horizontal: "right",
  });

  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
    setAlertstate(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          style={{ backgroundColor: "#523370" }}
          severity="info"
        >
          Machines limit exceeded!
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
