import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import { Helmet } from 'react-helmet';

// @material-ui/icons
import Grid from '@material-ui/core/Grid';
// core components
import Header from "src/components/authentication/components/Header/Header.js";
import GridItem from "src/components/authentication/components/Grid/GridItem.js";
import Parallax from "src/components/authentication/components/Parallax/Parallax.js";

import styles from "src/components/authentication/assets/jss/labs/views/AboutPage.js";
import teamstyles from "src/components/authentication/assets/jss/labs/views/aboutPageSections/teamStyle.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";

//icons
import { SocialIcon } from 'react-social-icons';
import { CardText } from "reactstrap";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const teamuseStyles = makeStyles(teamstyles);

export default function AboutPage(props) {
  const classes = useStyles();
  const teamclasses = teamuseStyles();
  const { ...rest } = props;

  const imageClasses = classNames(
    teamclasses.imgRaised,
    teamclasses.imgRoundedCircle,
    teamclasses.imgFluid
  );

  const { color, fixed, absolute, page, navigation } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const PageNavigation = () => {
    window.location.href = `/app/dashboard`;
  };

  return (
    <div>
      <Helmet>
      <title>SandboxAsService</title>
    </Helmet>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="SandboxAsService"
        page="Back"
        navigation="app/dashboard"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />

      <Parallax filter image={require("src/components/authentication/assets/img/landing-bg.jpg").default}>
      <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.container}
          >   
        <div className={classes.container} style={{padding: "20px 0", textAlign: "-webkit-center"}}>
            <GridItem xs={12} sm={12} md={6}>
            <div
                style={{
                  display: 'block', 
                  paddingTop: "30px"
                }}
                >
            <Grid xs={12} sm={12} md={8} 
            >
            
        <br />
                <img 
                 style={{
                  width: "50%",
                  height: "50%",
                  class: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  paddingTop: '20px',
                }}
                      alt="..."
                      src={
                        require("src/assets/img/squarecolourwithtag.png").default
                          
                      }/>
              </Grid>
              <h1 className={classes.title}>ZippyOPS</h1>
              <h4>Making Automation Work</h4>

<br />

              <CardText>
                  <SocialIcon target="blank" url="https://twitter.com/ZippyOps" network="twitter" fgColor="#fff" style={{ height: 35, width: 35 }} />
                  <SocialIcon target="blank" url="https://facebook.com/zippyops" network="facebook" fgColor="#fff" style={{ marginLeft: '0.5em', height: 35, width: 35 }} />
                  <SocialIcon target="blank" url="https://instagram.com/zippyops" network="instagram" fgColor="#fff" style={{ marginLeft: '0.5em', height: 35, width: 35 }} />
                  <SocialIcon target="blank" url="https://youtube.com/channel/UCZh2aijMM-h6ztRo36m3CiQ" network="youtube" fgColor="#fff" style={{ marginLeft: '0.5em', height: 35, width: 35 }} />
                  <SocialIcon target="blank" url="https://linkedin.com/in/zippyops" network="linkedin" fgColor="#fff" style={{ marginLeft: '0.5em', height: 35, width: 35 }} />
                  <SocialIcon target="blank" url="https://t.me/ZippyOPS" network="telegram" fgColor="#fff" style={{ marginLeft: '0.5em', height: 35, width: 35 }}/>
              </CardText>   
              </div>
              <br />
            </GridItem>           
        </div>
        </Grid>    
        <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.container}
          >  
          <ProductSection />
        </Grid> 
        </Grid> 
      </Parallax>
    </div>
  );
}
