import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import TotalApplications from '../../components/dashboard/TotalApplications';
import ActiveApplications from '../../components/dashboard/ActiveApplications';
import TotalCustomLabs from '../../components/dashboard/TotalCustomLabs';
import ResourceUsage from '../../components/dashboard/ResourceUsage';
import CordonedLabs from '../../components/dashboard/CordonedLabs';
import LatestActivity from './LabActivity';

const Dashboard = () => {
   
  return (
  <React.Fragment>
    <Helmet>
      <title>SandboxAsService</title>
    </Helmet>  
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
                      
      <Container maxWidth={false}>
        <Typography variant="h6" gutterBottom component="div">Overview:</Typography>   
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={2}
            sm={6}
            xl={2}
            xs={12}

            style={{
              marginRight: "auto", 
              marginLeft: "auto", 
              display: 'block',
            }}

          >
            <TotalApplications
            />
          </Grid>
          <Grid
            item
            lg={2}
            sm={6}
            xl={2}
            xs={12}

            style={{
              marginRight: "auto", 
              marginLeft: "auto", 
              display: 'block',
            }}

          >
            <ActiveApplications 
            />
          </Grid>       
          <Grid
            item
            lg={2}
            sm={6}
            xl={2}
            xs={12}

            style={{
              marginRight: "auto", 
              marginLeft: "auto", 
              display: 'block',
            }}

          >
            <ResourceUsage sx={{ height: '100%' }} 
            />
          </Grid>
          <Grid
            item
            lg={2}
            sm={6}
            xl={2}
            xs={12}

            style={{
              marginRight: "auto", 
              marginLeft: "auto", 
              display: 'block',
            }}

          >
            <TotalCustomLabs sx={{ height: '100%' }} 
            />
          </Grid>
          <Grid
            item
            lg={2}
            sm={6}
            xl={2}
            xs={12}

            style={{
              marginRight: "auto", 
              marginLeft: "auto", 
              display: 'block',
            }}

          >
            <CordonedLabs sx={{ height: '100%' }} 
            />
          </Grid>    
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <LatestActivity />
          </Grid>
          </Grid>
          <hr/>
          <br/>
      </Container>
    </Box>
  </React.Fragment>
);
        }

export default Dashboard;
