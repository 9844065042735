import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from "react";
import { Form, FormGroup, Alert } from "reactstrap";
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const customContentStyle = {
  width: '100%',
  maxWidth: 'none',
};

export default function Profilepassword() {
    const [state, setState] = useState({
      password: "",
      password2: "",
      confirm_password: "",
      errorMessage: "",
      errorMessage1: "",
      handleChange: false,
    });
  
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  
  
  const handleEditChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  
  const Submit = (e) => {
    e.preventDefault();
    const login = {
      email: localStorage.getItem("email").toString(),
      password: state.password,
    };
    fetch(`${process.env.REACT_APP_API_URL}/auth/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(login),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.tokens && data.email && data.username) {
          localStorage.setItem("user", data.username);
          localStorage.setItem("email", data.email);
          localStorage.setItem("token", data.tokens.access);
          handleClickOpen1();
        }else {
          if (data.password) {
            setState({ errorMessage: data.password[0] });
          } else if (data.detail) {
            setState({ errorMessage: data.detail });
          }else {
            setState({ errorMessage: data.detail });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem("token");
          if (error.code) {
            window.location.href = "/access_denied";
            } else {
            window.location.href = "/something_went_wrong";
          }
      });
  };
  
  const HandleSubmit = (e) => {
    e.preventDefault();
    if(state.confirm_password !== state.password2){
      setState({ errorMessage1: "The passwords doesn't match" });

  }
  else {
    const usrprofile = {
      username: "",
      password: state.password2,
      groups: "",
      organization_name: "",
      address: "",
    };
      fetch(`${process.env.REACT_APP_API_URL}/api/user_management/editprofile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(usrprofile),
      })
        .then((response) => response.json())
        .then((data) => {
            window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });
        window.location.reload();
  }
  };

  
const [open, setOpen] = React.useState(false);
const [open1, setOpen1] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClickOpen1 = () => {
  setOpen1(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleClose1 = () => {
  setOpen1(false);
};
  
function refreshPage() {
    window.location.reload();
  }

  return (
    <div>
       
       {localStorage.getItem("google") === "google" ? (
        <React.Fragment>
          <Button
            color="primary"
            style={{ marginTop: "30px", width: "80%" }}
            disabled="true"
          >
            Change Password
          </Button>
          <IconButton
            sx={{
              color: "white",
              '&.Mui-checked': {
                color: "white",
              },
            }} 
            size="sm"
            title="As you are using google account, you can't change your password"
            data-toggle="collapse"
            style={{ marginTop: "29px" }}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginTop: "30px", width: "100%", borderRadius: "1.245rem", marginTop: "26px", width: "100%", textTransform: "none", fontFamily: "Montserrat, Helvetica Neue, Arial, sans-serif" }}
        >
          Change Password
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} contentStyle={customContentStyle} fullWidth maxWidth="sm">
        <DialogContent>
                        <div>
                          {state.errorMessage && (
                            <Alert isOpen={visible} toggle={onDismiss}
                             style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "810",
                              color: "#FF0000",
                              fontFamily: "sans-serif",
                              lineHeight: "4.5rem",
                            }}
                            >
                              {" "}
                              {state.errorMessage}{" "}
                            </Alert>
                          )}
                        </div>
        <Form role="form">
            <div className="head" style={{ height: "80px" }}>
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "810",
                  color: "rgb(48, 68, 115)",
                  fontFamily: "sans-serif",
                  lineHeight: "4.5rem",
                }}
              >
                Current Password
              </h2>
            </div>
            <FormGroup style={{ marginBottom: "2.5rem" }}>
         
          <TextField
            autoFocus
            margin="dense"
            id="password"
            name="password"
            placeholder="password"
            type="password"
            inputProps={{ maxLength: 12 }}
            fullWidth
            variant="outlined"
            value={state.password}
            onChange={handleEditChange}
          />
          </FormGroup>
          </Form>
        </DialogContent>
        
        <DialogActions>
        <Button color="secondary" variant="contained" style={{ borderRadius: "1.245rem", textTransform: "none", fontFamily: "Montserrat, Helvetica Neue, Arial, sans-serif"}} onClick={refreshPage}>
        Cancel
        </Button>
        
        <Button
        color="primary"
        type="submit"
        variant="contained"
        onClick={Submit}
        style={{ borderRadius: "1.245rem", textTransform: "none", fontFamily: "Montserrat, Helvetica Neue, Arial, sans-serif"}}
        >
         Confirm
        </Button>
        
        </DialogActions>
      </Dialog>

      <Dialog open={open1} onClose={handleClose1} contentStyle={customContentStyle} fullWidth maxWidth="sm">
        <DialogContent>
                        <div>
                          {state.errorMessage1 && (
                            <Alert isOpen={visible} toggle={onDismiss}
                             style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "810",
                              color: "#FF0000",
                              fontFamily: "sans-serif",
                              lineHeight: "4.5rem",
                             }}
                             >
                              {" "}
                              {state.errorMessage1}{" "}
                            </Alert>
                          )}
                        </div>     
        <Form role="form">
            <div className="head" style={{ height: "80px" }}>
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "810",
                  color: "rgb(48, 68, 115)",
                  fontFamily: "sans-serif",
                  lineHeight: "4.5rem",
                }}
              >
               Set New Password
              </h2>
            </div>
            <FormGroup style={{ marginBottom: "2.5rem" }}>

          <TextField
            autoFocus
            margin="dense"
            placeholder="Password"
            type="password"
            fullWidth
            variant="outlined"
            id="password2"
            name="password2"
            value={state.password2}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            placeholder="Confirm Password"
            type="password"
            inputProps={{ maxLength: 12 }}
            fullWidth
            variant="outlined"
            id="confirm_password"
            name="confirm_password"
            value={state.confirm_password}
            onChange={handleEditChange}
          />
        </FormGroup>  
        </Form>
        </DialogContent>
        
        <DialogActions>
        <Button color="secondary" variant="contained" style={{ borderRadius: "1.245rem", textTransform: "none", fontFamily: "Montserrat, Helvetica Neue, Arial, sans-serif"}} onClick={handleClose1}>
        Cancel
        </Button>
        <Button
        color="primary"
        type="submit"
        variant="contained"
        onClick={HandleSubmit}
        style={{ borderRadius: "1.245rem", textTransform: "none", fontFamily: "Montserrat, Helvetica Neue, Arial, sans-serif"}}
        >
         Confirm
        </Button>
        
        </DialogActions>
      </Dialog>
      </div>
  );
}
