import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "src/assets/img/squarewhitewithtag.png";
import { 
Button
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';


const PDFGenerate = (props) => {
  const { estimationcount, state, details} = props;

   const generatePDF = () => {
      const doc = new jsPDF({orientation: "p", lineHeight: 1.5});
      var col = ["ID", "Name", "OS", "Spec", "Cost/hrs (INR)"];
      var rows = [];
      var sum = 0;

      doc.addImage(logo, "PNG", 92, 8, 35, 42);
      doc.setFontSize(18);
      doc.text("Sandbox Estimation", 89, 63);
      doc.setFontSize(12);

      doc.text(30, 100, `Report Name: ${estimationcount.reportname}`);
      doc.text(30, 120, `Number of Trainee: ${estimationcount.no_of_trainee}`);
      doc.text(30, 130, `Start Date and Time: ${estimationcount.start_date}`);
      doc.text(30, 140, `End Date and Time: ${estimationcount.end_date}`);
      doc.text(120, 100, `Training Hours: ${estimationcount.actual_hours} Hrs`);
      doc.text(120, 110, `Storage Hours: ${estimationcount.storage_hours} Hrs`);
      doc.text(120, 120, `Overall App Cost: Rs.${estimationcount.overall_app_price}`);
      doc.text(120, 130, `Overall Instance Cost: Rs.${estimationcount.overall_type_price}`);
      doc.text(120, 140, `Overall Storage Cost: Rs.${estimationcount.overall_storage_price}`);
      doc.text(55, 160, `----- Overall Sandbox Cost (excluding taxes):   Rs.${estimationcount.total_price} -----`);  

      details.map((invoice) => {
         var id, name, os, spec, price;
           id = invoice.id;
           name = invoice.name;
           os = invoice.os;
           spec = invoice.spec;
           price = invoice.price;
           var temp = [id, name, os, spec, price];
           rows.push(temp);    
      });
      
      doc.autoTable(col, rows, {
        startY: doc.autoTableEndPosY() + 170,
        theme: "grid",
        margin: {
          top: 15
         },
        headStyles: {
          valign: "middle",
          halign: "center",
         },
        bodyStyles: {
          valign: "middle",
          halign: "center",
         }, 
      });     
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(9);
      doc.text('This sandbox is eligible for discount upto 30%, please contact sales@zippyops.com to know your discount.',60-20,310-30,null,null,"left");
      doc.setFontSize(7);

      for(var i = 1; i <= pageCount; i++) {

          doc.setPage(i);
          doc.text('Page ' + String(i) + ' of ' + String(pageCount),210-20,320-30,null,null,"right");
      }      
      doc.save(`${state.reportname}`)
    }   
    
      return (
        <Button
        startIcon={
        <GetAppIcon />
        }
        variant="contained"
        color="primary"
        fullWidth
        onClick={generatePDF}
        disabled={estimationcount.total_price > 0 ? false : true }
      >Report
      </Button>
      );
}

export default PDFGenerate;