import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  AppBar,
  Box,
  IconButton,
  Hidden,  
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import WelcomeUser from '../pages/WelcomeUser/Welcomeuser';
import ImageUpload from "./ImageUpload.js";

const user = {
  avatar: '/static/images/avatars/userimg.png',
  avatar1: '/static/images/avatars/zippy.png',
  email: localStorage.getItem("email"),
  name: localStorage.getItem("user")
};

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {

  const logout = () => {
    localStorage.clear();
    window.localStorage.clear();
    window.location.href = "/";
  };

  const [userlist, setusers] = useState([]);

  React.useEffect(() => {    
    fetch(`${process.env.REACT_APP_API_URL}/api/user_management/user_list/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          localStorage.setItem("user_access", data.detail);
        } else {
          setusers(data)
        }
      })
      .catch((error) => {
        if (error.code) {
          window.location.href = "/access_denied";
          } else {
          window.location.href = "/something_went_wrong";
          }
      });     
  }, []);


  const [userlogin, setUserlogin] = React.useState([]);
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/userstatuscheck/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setUserlogin(resp))
      .catch((error) => {
        console.error(error);        
      });
  }, []);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/availableBalance/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => localStorage.setItem("available", resp))
      .catch((error) => {
        console.error(error);        
      });
  }, []);

  const [useravatar, setUseravatar] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/usrimage/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setUseravatar(resp[0]))
      .catch((error) => {
        console.error(error);

      });
  }, []);

  const navicons = (
    <>
      
        <Avatar
          src={useravatar ? useravatar.cover : ''}
          sx={{
            cursor: 'pointer',
            width: 28,
            height: 28
          }}
          to="/app/profile"
        />
        <Typography
          className="px-1"
          color="#ffff"
          variant="body2"
        >
          {localStorage.getItem("email")}
        </Typography>    
    <IconButton 
    color="inherit"
    title="Logout"
    aria-label="Logout"
    onClick={logout} 
    to="/auth/login"
    tag={RouterLink}
    >        
      <InputIcon />
    </IconButton>
    </>
  ); 
  
  return (
    <AppBar
      elevation={0}
      {...rest}
    >
    <Toolbar style={{ minHeight: '60px',
      backgroundImage: 'linear-gradient(145deg, rgb(69 183 217) 7%, rgb(41, 46, 73) 60%)'
  }}
    >
        <div
        className="d-table mr-auto ml-2"
        style={{
          alignItems: "Right",
          fontWeight: 800,
          color: "#ffffff", 
          fontFamily: "sans-serif",
          paddingLeft: '0px'
        }}
      >
        <ImageUpload 
        />
      </div>                
        <Box sx={{ flexGrow: 1 }} />
        <WelcomeUser userlogin={userlogin}/>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>  
        <Hidden lgDown>
        {navicons}        
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
