import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import GridItem from "../../../components/authentication/components/Grid/GridItem.js";
import Button from "../../../components/authentication/components/CustomButtons/Button.js";
import Card from "../../../components/authentication/components/Card/Card.js";
import CardHeader from "../../../components/authentication/components/Card/CardHeader.js";
import CardFooter from "../../../components/authentication/components/Card/CardFooter.js";
import styles from "../../../components/authentication/assets/jss/labs/views/loginPage.js";
import image from "../../../components/authentication/assets/img/bg.jpg";
import { RemoveScrollBar } from "react-remove-scroll-bar";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [state, setState] = useState({
    email: "",
    password: "",
    errorMessage: "",
    submitted: false,
    handleChange: false,
  });

  return (
    <div
      style={{
        zoom: "70%",
      }}
    >
      <RemoveScrollBar />

      <div
        className="text-muted text-center mt-2 mb-3"
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",

          display: "block",

          fontFamily: "work sans",
        }}
      >
        <div className={classes.container}>
          {state.errorMessage && !state.submitted && (
            <React.Fragment>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
                style={{
                  paddingTop: "60px",
                  paddingRight: "30px",
                  position: "fixed",
                }}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleClose}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
                maxSnack={3}
              >
                <Alert onClose={handleClose} severity="error">
                  {state.errorMessage}
                </Alert>
              </Snackbar>
            </React.Fragment>
          )}

          <GridItem
            sm={12}
            item
            md={6.5}
            item
            xs={20}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              display: "block",
              position: "center",
              height: "calc(150vh)",
            }}
          >
            <Card
              className={classes[cardAnimaton]}
              style={{
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                position: "center",
              }}
            >
              <form className={classes.form}>
                <CardHeader>
                  <img
                    style={{
                      width: "50%",
                      height: "50%",
                      class: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex",
                    }}
                    alt="..."
                    src={
                      require("../../../assets/img/rectcolor.png").default
                    }
                  />
                  <br />
                </CardHeader>

                <strong
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                 Password updated successfully !!!
                </strong>

                <br />

                <strong
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Please Login with e-mail and updated password to Dashboard.
                </strong>

                <br />
                <br />
                <br />

                <CardFooter className={classes.cardFooter}>
                  <Button
                    href="admin"
                    simple
                    variant="contained"
                    style={{
                      color: "#ffffff",
                      backgroundColor: "#e91e63",
                      marginLeft: "auto",
                      marginRight: "auto",
                      margineTop: "10px",
                    }}
                  >
                    Log in
                  </Button>
                </CardFooter>

                <Grid md={12}></Grid>
                <CardFooter className={classes.cardFooter}></CardFooter>

                <div
                  style={{ paddingBottom: "30px" }}
                  className="col-sm-12"
                ></div>
              </form>
            </Card>
          </GridItem>
        </div>
      </div>
    </div>
  );
}
