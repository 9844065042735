import React, { useState } from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Divider, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ScheduleError from "./ScheduleError";
import ScheduleError1 from "./ScheduleError1";
import CustomDaypicker from "./CustomDaypicker";

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

const Days = [
  { id: 1, name: "Sunday", value: "SUN" },
  { id: 2, name: "Monday", value: "MON" },
  { id: 3, name: "Tuesday", value: "TUE" },
  { id: 4, name: "Wednesday", value: "WED" },
  { id: 5, name: "Thurday", value: "THU" },
  { id: 6, name: "Friday", value: "FRI" },
  { id: 7, name: "Saturday", value: "SAT" },
];

export default function Schedule(props) {
  const { statevalue, onState, activeStep, handleBack, handleNext, classes } =
    props;

  const app = JSON.parse(statevalue);

  const [temporary, settemporary] = React.useState({
    repeat_frequency: "none",
  });
  const [schedulevalue, setSchedulevalue] = React.useState({
    repeat_frequency: "none",
  });

  const handleReload = () => {
    window.location.reload();
  };

  const [checkstate, setCheckstate] = React.useState({
    selectedValue: "checkedA",
  });

  const [scheduleerroropen, setscheduleerroropen] = React.useState(false);
  const [scheduleerroropen1, setscheduleerroropen1] = React.useState(false);

  const handlecheckChange = (event) => {
    const name = "selectedValue";
    setCheckstate({ [name]: event.target.value });
  };

  const [frequencyday, setFrequencyDay] = React.useState([]);      
  const [frequencyMonth, setFrequencyMonth] = useState();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSchedulevalue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleChange1 = (event, value) => {
    const id = "cronvalue";
    setSchedulevalue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    let timecount = 0;
    let timecount1 = 0;

    var zone = "America/New_York";
    // var datefmt = "MM-DD-YYYY";
    var datefmt = "MM-DD";
    var timefmt = "HH:mm";

    var startDate;
    var NewstartDate;
    var stDate1;

    if (schedulevalue.start_date_time) {
      startDate = new Date(schedulevalue.start_date_time)
        .toISOString()
        .split("T")[0];
      NewstartDate = new Date(startDate);
    } else {
      startDate = new Date().toISOString().substring(0, 10);
      NewstartDate = new Date(startDate);
    }

    var a = moment(NewstartDate, datefmt, zone).utc().format(datefmt);
    var sDate = new Date(a);

    var sDate2 = new Date(NewstartDate);

    const startdays = sDate2.getUTCDate();
    const startmonths = sDate2.getUTCMonth() + 1;

    var endDate;
    var NewendDate;
    if (schedulevalue.end_date_time) {
      endDate = new Date(schedulevalue.end_date_time)
        .toISOString()
        .split("T")[0];
      NewendDate = new Date(endDate);
    } else {
      endDate = new Date().toISOString().substring(0, 10);
      NewendDate = new Date(endDate);
    }

    var b = moment(NewendDate, datefmt, zone).utc().format(datefmt);
    var eDate = new Date(b);

    var eDate2 = new Date(NewendDate);
    const enddays = eDate2.getUTCDate();
    const endmonths = eDate2.getUTCMonth() + 1;

    var startminutes, starthours;
    if (checkstate.selectedValue !== "checkedC") {
      const startTime = new Date(schedulevalue.start_date_time)
        .toISOString()
        .split("T")[1];
      const stTime = startTime.split(":");

      startminutes = stTime[1];
      starthours = stTime[0];

      const CountstopTime = schedulevalue.end_date_time.split("T")[1];
      if (CountstopTime <= startTime) {
        timecount = timecount + 1;
      }
    }
    const stopTime = new Date(schedulevalue.end_date_time)
      .toISOString()
      .split("T")[1];
    const spTime = stopTime.split(":");

    const stopminutes = spTime[1];
    const stophours = spTime[0];
    const repeatFrequency = schedulevalue.repeat_frequency;
    const Every = schedulevalue.every;

    if (schedulevalue.cronvalue) {
      const onDays = schedulevalue.cronvalue;

      const MaponDays = onDays
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((ondayvalue) => {
          const one = ondayvalue.value;
          return one;
        });
      const stringonDays = JSON.stringify(MaponDays);
      const onDaysreplace = stringonDays
        .replace("[", "")
        .replace("]", "")
        .replace(/"/g, "");
      var NewonDays = onDaysreplace;
      var stronDays = NewonDays;      
    }

    // Minutes Hours Days(start-end) Month(start-end) Week  Year(start-end)
    //    30     3        8-9              8-8         ?       2021-2021

    var cron_expression_for_start;
    var cron_expression_for_end;
    var cron_expression_for_start2;
    var cron_expression_for_end2;
    var depends_on;
    if (checkstate.selectedValue === "checkedA") {
      depends_on = "always";
      cron_expression_for_start = `${startminutes} ${starthours} ${startdays} ${startmonths} ?`;
      cron_expression_for_end = `${stopminutes} ${stophours} ${enddays} ${endmonths} ?`;
    } else if (checkstate.selectedValue === "checkedB") {
      depends_on = "during_training";
      if (repeatFrequency === "day") {
        if (frequencyday.length === 0) {
          timecount1 = timecount1 + 1;
        }
        else {
        cron_expression_for_start = `${startminutes} ${starthours} ${frequencyday[0]} ?`;
        cron_expression_for_end = `${stopminutes} ${stophours} ${frequencyday[0]} ?`;
        if (frequencyday[1] !== undefined) {
          cron_expression_for_start2 = `${startminutes} ${starthours} ${frequencyday[1]} ?`;
          cron_expression_for_end2 = `${stopminutes} ${stophours} ${frequencyday[1]} ?`;
        } else {
          cron_expression_for_start2 = 'multiple month not selected';
          cron_expression_for_end2 = 'multiple month not selected';
        }
       }
      } else {
        if (stronDays === undefined) {
          timecount1 = timecount1 + 1;
        }
        else {
        cron_expression_for_start = `${startminutes} ${starthours} ? ${startmonths} ${NewonDays}`;
        cron_expression_for_end = `${stopminutes} ${stophours} ? ${startmonths} ${NewonDays}`;
        if (startmonths != endmonths) {
          cron_expression_for_start2 = `${startminutes} ${starthours} ? ${endmonths} ${NewonDays}`;
          cron_expression_for_end2 = `${stopminutes} ${stophours} ? ${endmonths} ${NewonDays}`; 
        } else {
          cron_expression_for_start2 = 'multiple month not selected';
          cron_expression_for_end2 = 'multiple month not selected';
        }
       }
      }      
    } else {
      depends_on = "immediate";
      cron_expression_for_end = `${stopminutes} ${stophours} ${enddays} ${endmonths} ?`;
    }


    var currentTime = new Date();

    var hoursUTC = String(currentTime.getUTCHours()).padStart(2, "0");
    var minutesUTC = String(currentTime.getUTCMinutes()).padStart(2, "0");

    var FinalstartTime;
    if (schedulevalue.start_date_time) {
      const FinalstartTime1 = new Date(schedulevalue.start_date_time)
        .toISOString({ hour: "2-digit", minute: "2-digit" })
        .split("T")[1];
      const FinalTime = FinalstartTime1.split(":");
      const minsUTC = FinalTime[1];
      const hrsUTC = FinalTime[0];

      FinalstartTime = `${hrsUTC}:${minsUTC}`;
    } else {
      FinalstartTime = `${hoursUTC}:${minutesUTC}`;
    }

    var currentDTime = new Date();
    var hoursUTC = String(currentDTime.getUTCHours()).padStart(2, "0");
    var minutesUTC = String(currentDTime.getUTCMinutes()).padStart(2, "0");
    var FinalstopTime;

    if (schedulevalue.end_date_time) {
      const FinalstopTime1 = new Date(schedulevalue.end_date_time)
        .toISOString({ hour: "2-digit", minute: "2-digit" })
        .split("T")[1];
      const FinalStopTime = FinalstopTime1.split(":");
      const minsUTC = FinalStopTime[1];
      const hrsUTC = FinalStopTime[0];
      FinalstopTime = `${hrsUTC}:${minsUTC}`;
    } else {
      FinalstopTime = `${hoursUTC}:${minutesUTC}`;
    }

    if (timecount > 0) {
      setscheduleerroropen(true);
    }
    else if (timecount1 > 0) {
      setscheduleerroropen1(true);
    }
     else {
      const id1 = "start_date";
      const id2 = "end_date";
      const id3 = "start_time";
      const id4 = "end_time";
      const id5 = "depends_on";
      const id6 = "repeat_frequency";
      const id7 = "every";
      const id8 = "on_days";
      const id9 = "startcron_expression";
      const id10 = "stopcron_expression";
      const id11 = "startcron_expression2";
      const id12 = "stopcron_expression2";
      onState((prevState) => ({
        ...prevState,
        [id1]: startDate,
        [id2]: endDate,
        [id3]: FinalstartTime,
        [id4]: FinalstopTime,
        [id5]: depends_on,
        [id6]: repeatFrequency,
        [id7]: Every,
        [id8]: NewonDays,
        [id9]: cron_expression_for_start,
        [id10]: cron_expression_for_end,
        [id11]: cron_expression_for_start2,
        [id12]: cron_expression_for_end2,
      }));
      handleNext();
    }
  };

  var currentTime = new Date();
  var currentOffset = currentTime.getTimezoneOffset();
  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );
  var hoursIST = ISTTime.getHours();
  var minutesIST = ISTTime.getMinutes();
  var currentDate = new Date().toISOString().substring(0, 10);
  let DateTimeformat = `${currentDate}`;
  let Dateformat = new Date(DateTimeformat);
  let currentDateTime = `${hoursIST}:${minutesIST}`;

  let localTime = new Date();
  let minDate1 = moment(localTime).format("YYYY-MM-DDTHH:MM");

  let startTimelocal = new Date(schedulevalue.start_date_time);
  let startday = (startTimelocal.getDate() + 1).toString().padStart(2, "0");
  let startmonth = (startTimelocal.getMonth() + 1).toString().padStart(2, "0");
  let startyear = startTimelocal.getFullYear();
  let maxDate1 = `${startyear}-${startmonth}-${startday}`;

  var nextMonth = ((Dateformat.getMonth() + 1) % 12) + 1;
  var cMonth = Dateformat.getMonth() + 1;

  if (cMonth === 12) {
    var year = currentTime.getFullYear() + 1;
    var date = new Date(`${year}`, `${nextMonth}`, 0);
    var endDate = date.getDate();
    var maxDate = `${year}-${nextMonth}-${endDate}`;
  } else {
    var year = currentTime.getFullYear();
    var date = new Date(`${year}`, `${nextMonth}`, 0);
    var endDate = date.getDate();
    var maxDate = `${year}-${nextMonth}-${endDate}`;
  }

  var maxDateFinal = new Date(maxDate).toISOString().substring(0, 10);

  return (
    <React.Fragment>
      <Box sx={{ pt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Scheduling
        </Typography>
      </Box>
      <ScheduleError
        validerroropen={scheduleerroropen}
        setvaliderroropen={setscheduleerroropen}
      />
      <ScheduleError1
        validerroropen={scheduleerroropen1}
        setvaliderroropen={setscheduleerroropen1}
      />
      <Box sx={{ pt: 3 }}>
        <Box
          borderColor="error.main"
          border={checkstate.selectedValue === "checkedA" ? 1 : 0}
        >
          <Accordion square expanded={checkstate.selectedValue === "checkedA"}>
            <AccordionSummary>
              <FormControlLabel
                aria-label="Acknowledge"
                control={
                  <Checkbox
                    checked={checkstate.selectedValue === "checkedA"}
                    onChange={handlecheckChange}
                    value="checkedA"
                    color="secondary"
                  />
                }
                label={
                  <Typography variant="h5">
                    {" "}
                    Always{" "}
                    <small>
                      (Sandbox will be running continuously between the schedule
                      provided )
                    </small>
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h7" gutterBottom>
                    Start Date & Time
                  </Typography>
                  <TextField
                    fullWidth
                    name="start_date_time"
                    id="start_date_time"
                    type="datetime-local"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    disabled={
                      checkstate.selectedValue === "checkedA" ? false : true
                    }
                    inputProps={{
                      min: minDate1,
                      max: `${maxDateFinal}T00:00`,
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  />
                </Grid>

                <Grid item md={3} xs={12}>
                  <Typography variant="h7" gutterBottom>
                    End Date & Time
                  </Typography>
                  <TextField
                    fullWidth
                    name="end_date_time"
                    id="end_date_time"
                    type="datetime-local"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    disabled={
                      checkstate.selectedValue === "checkedA" ? false : true
                    }
                    InputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box border={checkstate.selectedValue === "checkedB" ? 1 : 0}>
          <Accordion square expanded={checkstate.selectedValue === "checkedB"}>
            <AccordionSummary>
              <FormControlLabel
                aria-label="Acknowledge"
                control={
                  <Checkbox
                    checked={checkstate.selectedValue === "checkedB"}
                    onChange={handlecheckChange}
                    value="checkedB"
                    color="secondary"
                  />
                }
                label={
                  <Typography variant="h5">
                    {" "}
                    During Training Hours{" "}
                    <small>
                      (Sandbox will be running at given frequencies between the
                      schedule provided )
                    </small>
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h7" gutterBottom>
                    Start Date & Time
                  </Typography>
                  <TextField
                    fullWidth
                    name="start_date_time"
                    id="start_date_time"
                    type="datetime-local"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    disabled={
                      checkstate.selectedValue === "checkedB" ? false : true
                    }
                    inputProps={{
                      min: minDate1,
                      max: `${maxDateFinal}T00:00`,
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  />
                </Grid>

                <Grid item md={3} xs={12}>
                  <Typography variant="h7" gutterBottom>
                    End Date & Time
                  </Typography>
                  <TextField
                    fullWidth
                    name="end_date_time"
                    id="end_date_time"
                    type="datetime-local"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    disabled={
                      checkstate.selectedValue === "checkedB" ? false : true
                    }
                    inputProps={{
                      min:
                        checkstate.selectedValue === "checkedB" &&
                        schedulevalue.start_date_time
                          ? schedulevalue.start_date_time
                          : minDate1,
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ pb: 2, pt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Frequency Details
                </Typography>
                <Divider />
              </Box>
              <Grid container spacing={3}>
                <Grid item md={4} xs={4}>
                  <Typography variant="h8" gutterBottom>
                    Repeat Frequency
                  </Typography>
                  <TextField
                    fullWidth
                    name="repeat_frequency"
                    id="repeat_frequency"
                    onChange={handleChange}
                    disabled={
                      checkstate.selectedValue === "checkedB" ? false : true
                    }
                    required
                    select
                    SelectProps={{ native: true }}
                    autoComplete="repeat_frequency"
                    InputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  >
                    {" "}
                    <option key="" value=""></option>
                    <option key="day" value="day">
                      Specific Day's
                    </option>
                    <option key="week" value="week">
                      Weekly
                    </option>
                  </TextField>
                </Grid>
                {temporary.repeat_frequency === "day" ? (
                  <Grid item md={4} xs={4}>
                    <Typography variant="h8" gutterBottom>
                      Choose the Required Dates
                    </Typography>
                    <CustomDaypicker
                      setFrequencyDay={setFrequencyDay}
                      setFrequencyMonth={setFrequencyMonth}
                      startDate={schedulevalue.start_date_time}
                      endDate={schedulevalue.end_date_time}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {temporary.repeat_frequency === "week" ? (
                  <Grid item md={4} xs={4}>
                    <Typography variant="h8" gutterBottom>
                      On Days
                    </Typography>
                    <Autocomplete
                      multiple
                      name="on_days"
                      size="small"
                      limitTags={2}
                      options={Days}
                      onChange={handleChange1}
                      disabled={
                        checkstate.selectedValue === "checkedB" ? false : true
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <React.Fragment>
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="On Days"
                          />
                        </React.Fragment>
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          borderColor="error.main"
          border={checkstate.selectedValue === "checkedC" ? 1 : 0}
        >
          <Accordion square expanded={checkstate.selectedValue === "checkedC"}>
            <AccordionSummary>
              <FormControlLabel
                aria-label="Acknowledge"
                control={
                  <Checkbox
                    checked={checkstate.selectedValue === "checkedC"}
                    onChange={handlecheckChange}
                    value="checkedC"
                    color="secondary"
                  />
                }
                label={
                  <Typography variant="h5">
                    {" "}
                    Immediate{" "}
                    <small>(Sandbox will be running upto end date )</small>
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h7" gutterBottom>
                    End Date & Time
                  </Typography>
                  <TextField
                    fullWidth
                    name="end_date_time"
                    id="end_date_time"
                    type="datetime-local"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    disabled={
                      checkstate.selectedValue === "checkedC" ? false : true
                    }
                    inputProps={{
                      min: minDate1,
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReload}
              className={classes}
            >
              Cancel
            </Button>
          </div>
          <div className={classes}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} className={classes}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitClick}
              className={classes}
              disabled={
                checkstate.selectedValue === "checkedA" &&
                schedulevalue.start_date_time &&
                schedulevalue.end_date_time
                  ? false
                  : checkstate.selectedValue === "checkedB" &&
                    schedulevalue.start_date_time &&
                    schedulevalue.end_date_time &&
                    (schedulevalue.repeat_frequency || schedulevalue.on_days)
                  ? false
                  : checkstate.selectedValue === "checkedC" &&
                    schedulevalue.end_date_time
                  ? false
                  : true
              }
            >
              Next
            </Button>
          </div>
        </Box>
      </Box>
    </React.Fragment>
  );
}
