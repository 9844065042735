import React, { useState, useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import { Helmet } from "react-helmet";
import CustomBillingTable from "./CustomBillingTable";
import AvailableBalance from "../Balance";

const CustomerList = () => {
 
  const [billinginfo, setBillinginfo] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/billingview/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setBillinginfo(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <AvailableBalance />
          </Box>

          <CustomBillingTable results={billinginfo.results} />
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
