import React, { useRef, useState} from 'react';
import IdleTimeOutModal from './IdleTimeOutModal'
import IdleTimer from 'react-idle-timer'

const IdleTimeOutHandler = (props)=>{

    const idleTimerRef = useRef(null)

    const [show, setShow] = useState(false);

    const onIdle = () => {
        setShow(true)
        toggle()
    }
    const [open, setOpen] = useState(false);
    const [focusAfterClose] = useState(true);
    const toggle = () => setOpen(!open);  

    const handleContinue = () => {
        toggle();
        window.location.reload();
      };

    const handleLogout = () => {
        toggle();
        localStorage.clear();
        window.localStorage.clear();  
        window.location.reload(); 
        window.location.href = "/login";     
    };      

    return (
        <div>
        <IdleTimeOutModal show={show} open={open} focusAfterClose={focusAfterClose} handleContinue={handleContinue} handleLogout={handleLogout}/>
         <IdleTimer
         ref={idleTimerRef}
         timeout={1000 * 60 * 30}
         onIdle={onIdle}
         ></IdleTimer>    
        </div>
    )   
}
    
export default IdleTimeOutHandler;