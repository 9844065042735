import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SearchField from "react-search-field";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import InfoIcon from "@material-ui/icons/Info";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LabelTwoToneIcon from "@material-ui/icons/LabelTwoTone";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#b5b6b7",
    color: "black",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const delay = 5;

const options = [
  { id: 1, name: "Lab Name", value: "Lab_name" },
  { id: 2, name: "Schedule Type", value: "Schedule_type" },
];

function UpcomingDialog(props) {
  const { startDate, stopDate, dates, onTime, offTime, ontime, offtime } =
    props;
  const [open, setOpen] = React.useState(false);
  const fullWidth = true;
  const maxWidth = "md";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function UpcomingDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Date1 = new Date(date).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const Date2 = Date1.split(",");
    const Datef = Date2[0].replace("/", "-");
    const Date3 = Datef.replace("/", "-");
    return Date3;
  }

  return (
    <React.Fragment>
      <TableCell>
        <Button onClick={handleClickOpen}>
          <InfoIcon />
        </Button>
      </TableCell>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          <Chip icon={<LabelTwoToneIcon />} label="UPCOMING DAYS" /> (
          <span style={{ color: "red" }}>
            Start Date : {startDate}, End-Date : {stopDate}
          </span>
          )
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>DATE</StyledTableCell>
                  <StyledTableCell>DAY</StyledTableCell>
                  <StyledTableCell>ON TIME</StyledTableCell>
                  <StyledTableCell>OFF TIME</StyledTableCell>
                  <StyledTableCell>TIME ZONE</StyledTableCell>
                </TableRow>
              </TableHead>
              {dates ? (
                <React.Fragment>
                  {dates.map((data) => {
                    var days = [
                      "SUNDAY",
                      "MONDAY",
                      "TUESDAY",
                      "WEDNESDAY",
                      "THURSDAY",
                      "FRIDAY",
                      "SATURDAY",
                    ];
                    var d = new Date(data.date);
                    var dayName = days[d.getDay()];
                    return (
                      <React.Fragment>
                        <TableBody>
                          <TableRow hover>
                            <TableCell>
                              {UpcomingDate(`${data.date}`)}
                            </TableCell>
                            <TableCell>
                              <Chip {...a21yProps(dayName)} />
                            </TableCell>
                            {ontime === "" || ontime === null ? (
                              <TableCell>NA</TableCell>
                            ) : (
                              <TableCell>{onTime}</TableCell>
                            )}
                            {offtime === "" || offtime === null ? (
                              <TableCell>NA</TableCell>
                            ) : (
                              <TableCell>{offTime}</TableCell>
                            )}
                            <TableCell>IST</TableCell>
                          </TableRow>
                        </TableBody>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  return (
                  <React.Fragment>
                    <TableBody>
                      <TableRow hover>
                        <TableCell></TableCell>
                        <TableCell>Currently no events are scheduled</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </React.Fragment>
                  )
                </React.Fragment>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function a21yProps(value) {
  if (value === "SUNDAY") {
    return {
      style: { color: "rgb(228 3 3 / 94%)" },
      label: value,
      size: "small",
    };
  } else if (value === "MONDAY") {
    return {
      style: { color: "#007eec" },
      label: value,
      size: "small",
    };
  } else if (value === "TUESDAY") {
    return {
      style: { color: "#00a200" },
      label: value,
      size: "small",
    };
  } else if (value === "WEDNESDAY") {
    return {
      style: { color: "rgb(228 193 3 / 94%)" },
      label: value,
      size: "small",
    };
  } else if (value === "THURSDAY") {
    return {
      style: { color: "rgb(0 146 141 / 94%)" },
      label: value,
      size: "small",
    };
  } else if (value === "FRIDAY") {
    return {
      style: { color: "rgb(146 0 139 / 94%)" },
      label: value,
      size: "small",
    };
  } else {
    return {
      style: { color: "rgb(255 126 9 / 94%)" },
      label: value,
      size: "small",
    };
  }
}

function NextDate(props) {
  const { date } = props;
  const days = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  const d = new Date(date[0].date);
  const dayName = days[d.getDay()];

  function UpcomingDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Date1 = new Date(date).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const Date2 = Date1.split(",");
    const Datef = Date2[0].replace("/", "-");
    const Date3 = Datef.replace("/", "-");
    return Date3;
  }

  return (
    <React.Fragment>
      <TableCell>
        {UpcomingDate(`${date[0].date}`)}
        <Chip {...a21yProps(dayName)} />
      </TableCell>
    </React.Fragment>
  );
}
const CurrentSchedule = ({ ...rest }) => {
  function UpcomingDate(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Date1 = new Date(date).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const Date2 = Date1.split(",");
    const Datef = Date2[0].replace("/", "-");
    const Date3 = Datef.replace("/", "-");
    return Date3;
  }

  function UpcomingDate1(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Date1 = new Date(date).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const Date2 = Date1.split(",");
    const Datef = Date2[0].replace("/", "-");
    const Date3 = Datef.replace("/", "-");
    return Date3;
  }

  function UpcomingOnTime(date, time) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Time1 = new Date(date + "T" + time + ":00.000Z").toLocaleString(
      "en-GB",
      { timeZone: `${timezone}` }
    );
    const Time2 = Time1.split(",");
    const Time3 = Time2[1].substring(0, 6);
    return Time3;
  }

  function UpcomingOffTime(date, time) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Time1 = new Date(date + "T" + time + ":00.000Z").toLocaleString(
      "en-GB",
      { timeZone: `${timezone}` }
    );
    const Time2 = Time1.split(",");
    const Time3 = Time2[1].substring(0, 6);
    return Time3;
  }

  const [instanceschlist, setinstanceschlist] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/upcominglabs/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setinstanceschlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, instanceschlist.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [searchitems, setSearchItems] = React.useState(0);

  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ButtonGroup
            sx={{ mr: 2 }}
            variant="outlined"
            style={{
              backgroundColor: "#ffff",
              color: "rgb(90, 90, 90)",
              border: "1px solid rgb(221, 221, 221)",
            }}
            aria-label="split button"
          >
            <Button
              style={{
                width: "180px",
                backgroundColor: "#ffff",
                color: "rgb(90, 90, 90)",
                border: "1px solid rgb(221, 221, 221)",
              }}
            >
              {options[selectedIndex].name} {" = "}
            </Button>
            <Button
              style={{
                backgroundColor: "#ffff",
                color: "rgb(90, 90, 90)",
                border: "1px solid rgb(221, 221, 221)",
              }}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleClick}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option.name}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Popover>
          <SearchField
            value={searchitems}
            placeholder="Search..."
            onChange={(event) => {
              setSearchItems(event);
            }}
            classNames="test-class"
          />
          <Box sx={{ pt: 3 }}>
            <Card {...rest}>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1000 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Lab Name</TableCell>
                          <TableCell>No.of Trainee</TableCell>
                          <TableCell>On Time</TableCell>
                          <TableCell>Off Time</TableCell>
                          <TableCell>Scheduling Type</TableCell>
                          <TableCell>Time Zone</TableCell>
                          <TableCell>more Info</TableCell>
                        </TableRow>
                      </TableHead>
                      {instanceschlist.length > 0 ? (
                        <TableBody>
                          {instanceschlist
                            .filter((val) => {
                              if (searchitems == "") {
                                return val;
                              } else if (
                                val[options[selectedIndex].value]
                                  .toLowerCase()
                                  .includes(searchitems.toLowerCase())
                              ) {
                                return val;
                              }
                            })
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((instance) =>
                              instance.Application === "custom" ? (
                                <React.Fragment>
                                  <TableRow hover key={instance.Id}>
                                    <NextDate date={instance.result} />
                                    <TableCell>{instance.Lab_name}</TableCell>
                                    <TableCell>{instance.Trainees}</TableCell>
                                    {instance.On_time === "" ||
                                    instance.On_time === null ? (
                                      <TableCell>NA</TableCell>
                                    ) : (
                                      <TableCell>
                                        {UpcomingOnTime(
                                          `${instance.Start_date}`,
                                          `${instance.On_time}`
                                        )}
                                      </TableCell>
                                    )}
                                    {instance.Off_time === "" ||
                                    instance.Off_time === null ? (
                                      <TableCell>NA</TableCell>
                                    ) : (
                                      <TableCell>
                                        {UpcomingOffTime(
                                          `${instance.Stop_date}`,
                                          `${instance.Off_time}`
                                        )}
                                      </TableCell>
                                    )}
                                    <TableCell>
                                      {instance.Schedule_type}
                                    </TableCell>
                                    <TableCell>IST</TableCell>
                                    <UpcomingDialog
                                      startDate={UpcomingDate(
                                        `${instance.Start_date}`
                                      )}
                                      stopDate={UpcomingDate1(
                                        `${instance.Stop_date}`
                                      )}
                                      dates={instance.result}
                                      ontime={instance.On_time}
                                      offtime={instance.Off_time}
                                      onTime={UpcomingOnTime(
                                        `${instance.Start_date}`,
                                        `${instance.On_time}`
                                      )}
                                      offTime={UpcomingOffTime(
                                        `${instance.Start_date}`,
                                        `${instance.Off_time}`
                                      )}
                                    />
                                  </TableRow>
                                </React.Fragment>
                              ) : (
                                ""
                              )
                            )}
                        </TableBody>
                      ) : (
                        <TableBody align="center">
                          <TableCell>No Data Available</TableCell>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {instanceschlist.length > 0 ? (
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={instanceschlist.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Divider />
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default CurrentSchedule;
