import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Card, CardContent } from "@material-ui/core";

const Balance = () => {
  const [billinginfo, setBillinginfo] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/billingview/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setBillinginfo(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "15ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item lg={6} sm={6} xl={6} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={0}>
                <Grid item>
                  <Grid container>
                    <Typography variant="h5" display="block" gutterBottom>
                      Available Credits:{" "}
                      <strong>{`${billinginfo.available_balance}`}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Balance;
