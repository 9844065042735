import React from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const NavItem = ({
  href,
  icon: Icon,
  title,
  title1,
  ...rest
}) => {
  const location = useLocation();  
 
  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const splitLoc1 = splitLocation[2];

 return (
  <>
   {title === "About" ?
 
      <React.Fragment>

    <Button
      component={RouterLink}
      sx={{
        color: '#ffff',
        fontWeight: 'medium',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        py: 0,
        textTransform: 'none',
        width: '100%',
        ...(splitLoc1 === "about" ? {
          backgroundColor: 'rgb(6 57 108)',         
          color: '#2cbcd3'
        }: ""),
        '& svg': {
          mr: 1
        }
      }}
      to={href} 
      target="_blank"       
    >
     
      <span>
        {title}
      </span><OpenInNewIcon className="ml-1" style={{fontSize: '14px'}}fontSize="small"  color="primary"/>
    </Button>
    </React.Fragment> 
    
    : title === "Support" || title === "Docs" ?
      <React.Fragment>
    <a href={href} target="_blank">
    <Button
      sx={{
        color: '#ffff',
        fontWeight: 'medium',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        py: 0,
        textTransform: 'none',
        width: '100%',
        ...(active ? {
          backgroundColor: 'rgb(6 57 108)',
          color: '#2cbcd3'
        }: ""),
        '& svg': {
          mr: 1
        }
      }}         
    >
     
      <span>
        {title}
      </span><OpenInNewIcon className="ml-1" style={{fontSize: '14px'}} fontSize="small"  color="primary"/>
    </Button>
   </a> 
   </React.Fragment>      
    :    
      <React.Fragment>
      <Button
        component={RouterLink}
        sx={{
          color: '#ffff',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          marginLeft: 'auto',
          py: 0,
          textTransform: 'none',
          width: '100%',
          ...(active ? {
            backgroundColor: 'rgb(6 57 108)',
            color: '#2cbcd3'
          }: ""),
          '& svg': {
            mr: 1
          }
        }}
        to={href}        
      >
        <span>
          {title}
        </span>
      </Button>
      </React.Fragment>    
  }  
  </>
 );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  title1: PropTypes.string
};

export default NavItem;
