import React from "react";
import Menu from "@material-ui/core/Menu";
import Log from "./Log";
import Clone from "./Clone";
import Delete from "./Delete";
import Start from "./Startlab";
import Stop from "./Stoplab";
import SupportTeam from "./SupportTeam";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UnCordoned from "./UnCordoned";

export default function SimpleMenu(props) {
  const { InstanceID, name, count, status, spent, budget } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Log InstanceID={InstanceID} />
        {status === "Failed" ||
        status === "Cordoned" ||
        status === "Creating" ||
        status === "Running" ||
        status === "Retrying" ||
        status === "Stopping" ? (
          ""
        ) : (
          <Start
            InstanceID={InstanceID}
            status={status}
            spent={spent}
            budget={budget}
          />
        )}
        {status === "Failed" ||
        status === "Cordoned" ||
        status === "Creating" ||
        status === "Stopped" ||
        status === "Retrying" ||
        status === "Stopping" ? (
          ""
        ) : (
          <Stop InstanceID={InstanceID} status={status} />
        )}
        <SupportTeam status={status} />
        {status === "Creating" ||
        status === "Retrying" ||
        status === "Stopping" ? (
          ""
        ) : (
          <Delete InstanceID={InstanceID} name={name} status={status} />
        )}
        {status === "Cordoned" ? (
          <UnCordoned
            InstanceID={InstanceID}
            name={name}
            status={status}
            spent={spent}
            budget={budget}
          />
        ) : (
          ""
        )}

        {status === "Failed" ||
        status === "Cordoned" ||
        status === "Creating" ||
        status === "Stopping" ? (
          ""
        ) : (
          <Clone
            InstanceID={InstanceID}
            status={status}
            name={name}
            spent={spent}
            count={count}
            budget={budget}
          />
        )}
      </Menu>
    </div>
  );
}
