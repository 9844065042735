import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CardContent,
  TextField,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { red, orange, green, indigo, pink } from "@material-ui/core/colors";
import ViewComfySharpIcon from "@material-ui/icons/ViewComfySharp";
import ViewModuleSharpIcon from "@material-ui/icons/ViewModuleSharp";
import ViewQuiltSharpIcon from "@material-ui/icons/ViewQuiltSharp";
import ViewHeadlineSharpIcon from "@material-ui/icons/ViewHeadlineSharp";
import ViewCarouselSharpIcon from "@material-ui/icons/ViewCarouselSharp";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Usermodify from "src/pages/Management/Usermodify";
import Groupmodify from "src/pages/Management/Groupmodify";
import IconButton from "@material-ui/core/IconButton";
import Error403 from "../Error/403";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function a21yProps(index) {
  return {
    id: `${index}`,
  };
}
function a31yProps(value) {
  if (value === "left") {
    return {
      disabled: true,
      style: { backgroundColor: "#f98521", color: "#f4f4f4" },
    };
  }
}
function a41yProps(value) {
  if (value === "center") {
    return {
      disabled: true,
      style: { backgroundColor: "#f98521", color: "#f4f4f4" },
    };
  }
}
function a51yProps(value) {
  if (value === "right") {
    return {
      disabled: true,
      style: { backgroundColor: "#f98521", color: "#f4f4f4" },
    };
  }
}
function a61yProps(value) {
  if (value === "justify") {
    return {
      disabled: true,
      style: { backgroundColor: "#f98521", color: "#f4f4f4" },
    };
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Request() {
  const classes = useStyles();

  const [adminuserlist, setadminuserlist] = useState([]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/adminuserlistView/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((resp) => resp.json())
      .then((resp) => setadminuserlist(resp))
      .catch((error) => {
        console.error(error);
        window.location.href = "/something_went_wrong";
      });
  }, []);

  const [opsuserlist, setopsuserlist] = useState([]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/opsuserlistView/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((resp) => resp.json())
      .then((resp) => setopsuserlist(resp))
      .catch((error) => {
        console.error(error);
        window.location.href = "/something_went_wrong";
      });
  }, []);

  const [readonlyuserlist, setreadonlyuserlist] = useState([]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/readonlyuserlistView/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((resp) => resp.json())
      .then((resp) => setreadonlyuserlist(resp))
      .catch((error) => {
        console.error(error);
        window.location.href = "/something_went_wrong";
      });
  }, []);

  const [state, setState] = React.useState({
    email: "",
    username: "",
    groups: "",
    password: "",
    password2: "",
    errorMessage: "",
    right: false,
    radioList: "A",
    delete_id: "",
    active: "1",
  });
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (state.password !== state.password2) {
      setState({ errorMessage: "The passwords doesn't match" });
    } else {
      const register = {
        email: state.email,
        username: state.username,
        password: state.password,
        groups: [state.groups],
        is_active: state.active,
      };
      fetch(`${process.env.REACT_APP_API_URL}/api/user_management/user_list/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(register),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.email && data.username) {
            handleClose();
            setValue(2);
            //window.location.reload();
          } else {
            if (data.username) {
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ groups: [] });
              setState({ errorMessage: data.username[0] });
            } else if (data.email) {
              if (
                data.email[0] === "User already exist, It is in Inactive state"
              ) {
                handleClickOpenActive();
              } else if (
                data.email[0] ===
                "User already exist, but user not in our organization"
              ) {
                handleClickOpenActive();
              } else {
                setState({ email: "" });
                setState({ username: "" });
                setState({ password: "" });
                setState({ password2: "" });
                setState({ groups: [] });
                setState({ errorMessage: data.email[0] });
              }
            } else if (data.password) {
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ groups: [] });
              setState({ errorMessage: data.password[0] });
            } else {
              setState({ email: "" });
              setState({ username: "" });
              setState({ password: "" });
              setState({ password2: "" });
              setState({ groups: [] });
              setState({ errorMessage: data.errors.email[0] });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setState({ errorMessage: "Something Went Wrong" });
        });
    }
  };

  const [userlist, setusers] = useState([]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch(`${process.env.REACT_APP_API_URL}/api/user_management/user_list/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.detail) {
            localStorage.setItem("user_access", data.detail);
          } else {
            setusers(data);
          }
        })
        .catch((error) => {
          console.error(error);
          // window.location.href = "/something_went_wrong";
        });
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const Delete = (UserId) => {
    return function () {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/user_management/user_list/${UserId}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({ is_active: "0" }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          handleCloseDeleteindual();
        })
        .catch((error) => {
          handleCloseDeleteindual();
        });
      handleCloseDeleteindual();
    };
  };
  const activate = (userId) => {
    return function () {
      const activate = {
        username: state.username,
        password: state.password,
        groups: state.groups.toString(),
        active: state.active,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/api/user_management/user/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(activate),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          //window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });
    };
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = userlist.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const [alignment, setAlignment] = React.useState("left");

  const handleChange1 = (value) => {
    setAlignment(value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload(false);
  };

  const [opendelete, setOpendelete] = React.useState(false);

  const handleClickOpenDelete = () => {
    setOpendelete(true);
  };

  const handleCloseDelete = () => {
    setOpendelete(false);
  };

  const [openactive, setOpenactive] = React.useState(false);

  const handleClickOpenActive = () => {
    setOpenactive(true);
  };

  const handleCloseActive = () => {
    setOpenactive(false);
  };

  const [opencontact, setOpencontact] = React.useState(false);

  const handleCloseContact = () => {
    setOpencontact(false);
  };

  const [opendeleteindual, setOpendeleteindual] = React.useState(false);

  const handleClickOpenDeleteindual = () => {
    setOpendeleteindual(true);
  };

  const handleCloseDeleteindual = () => {
    setOpendeleteindual(false);
  };
  const [useroverview, setuseroverview] = useState([]);
  useEffect(() => {
    // const intervalId = setInterval(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/overviewcount/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setuseroverview(data);
        }
      })
      .catch((error) => {
        console.error(error);
        // window.location.href = "/something_went_wrong";
      });
  }, []);

  function del(deleteid) {
    setState({ delete_id: deleteid });
  }

  const [erroropen, setErroropen] = React.useState(false);

  const handleCloserror = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErroropen(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      {localStorage.getItem("user_access") ===
        "You do not have permission to perform this action." &&
      localStorage.getItem("group") !== "admin" ? (
        <Error403 />
      ) : (
        <div
          className={classes.root}
          style={{ paddingRight: "30px", paddingLeft: "30px" }}
        >
          <AppBar position="static" style={{ backgroundColor: "#ffff" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Organization" {...a11yProps(0)} />
              <Tab label="Groups" {...a11yProps(1)} />
              <Tab label="Users" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={8} xl={3} xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={4}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              No of Groups
                            </Typography>
                            <Typography color="textPrimary" variant="h3">
                              {useroverview.group}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                backgroundColor: pink[600],
                                height: 56,
                                width: 56,
                              }}
                            >
                              <ViewCarouselSharpIcon />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={4} md={8} xl={3} xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={4}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              Over all User
                            </Typography>
                            <Typography color="textPrimary" variant="h3">
                              {useroverview.all}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                backgroundColor: red[600],
                                height: 56,
                                width: 56,
                              }}
                            >
                              <ViewComfySharpIcon />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={4} md={8} xl={3} xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={3}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              Admin Users
                            </Typography>
                            <Typography color="textPrimary" variant="h3">
                              {useroverview.admin}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                backgroundColor: orange[600],
                                height: 56,
                                width: 56,
                              }}
                            >
                              <ViewModuleSharpIcon />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={4} md={8} xl={3} xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={3}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              Ops User
                            </Typography>
                            <Typography color="textPrimary" variant="h3">
                              {useroverview.ops}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                backgroundColor: green[600],
                                height: 56,
                                width: 56,
                              }}
                            >
                              <ViewQuiltSharpIcon />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={4} md={8} xl={3} xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Grid
                          container
                          spacing={3}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              Read-Only User
                            </Typography>
                            <Typography color="textPrimary" variant="h3">
                              {useroverview.read}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                backgroundColor: indigo[600],
                                height: 56,
                                width: 56,
                              }}
                            >
                              <ViewHeadlineSharpIcon />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
              <Box sx={{ flexGrow: 1 }} />
              <Divider />
            </Card>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    {...a21yProps(0)}
                    {...a31yProps(alignment)}
                    onClick={() => handleChange1("left")}
                    value="left"
                  >
                    All
                  </Button>
                  <Button
                    {...a21yProps(1)}
                    {...a41yProps(alignment)}
                    onClick={() => handleChange1("center")}
                    value="center"
                  >
                    Admin
                  </Button>
                  <Button
                    {...a21yProps(2)}
                    {...a51yProps(alignment)}
                    onClick={() => handleChange1("right")}
                    value="right"
                  >
                    OPS
                  </Button>
                  <Button
                    {...a21yProps(3)}
                    {...a61yProps(alignment)}
                    onClick={() => handleChange1("justify")}
                    value="justify"
                  >
                    Read-Only
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            {alignment === "left" ? (
              <Box sx={{ pt: 0 }}>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  style={{
                    fontSize: "x-large",
                    lineHeight: "2.5",
                    paddingLeft: "24px",
                  }}
                  color="textPrimary"
                  variant="body1"
                >
                  ALL
                </Typography>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 600 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>created At</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {userlist.length > 0 ? (
                          <TableBody>
                            {userlist.map((customer) => (
                              <TableRow hover key={customer.id}>
                                {customer.is_active === "True" ? (
                                  <React.Fragment>
                                    <TableCell>
                                      <Box
                                        sx={{
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {customer.username}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>{customer.email}</TableCell>
                                    <TableCell>
                                      {moment(customer.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Groupmodify UserID={customer.id} />
                                    </TableCell>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody align="center">
                            <TableCell>No Data Available</TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            ) : alignment === "center" ? (
              <Box sx={{ pt: 0 }}>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  style={{
                    fontSize: "x-large",
                    lineHeight: "2.5",
                    paddingLeft: "24px",
                  }}
                  color="textPrimary"
                  variant="body1"
                >
                  Admin Group
                </Typography>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 600 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>created At</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {adminuserlist.length > 0 ? (
                          <TableBody>
                            {adminuserlist.map((customer) => (
                              <TableRow hover key={customer.id}>
                                {customer.is_active === "True" ? (
                                  <React.Fragment>
                                    <TableCell>
                                      <Box
                                        sx={{
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {customer.username}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>{customer.email}</TableCell>
                                    <TableCell>
                                      {moment(customer.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Groupmodify UserID={customer.id} />
                                    </TableCell>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody align="center">
                            <TableCell>No Data Available</TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            ) : alignment === "right" ? (
              <Box sx={{ pt: 0 }}>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  style={{
                    fontSize: "x-large",
                    lineHeight: "2.5",
                    paddingLeft: "24px",
                  }}
                  color="textPrimary"
                  variant="body1"
                >
                  OPS Group
                </Typography>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 600 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>created At</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {opsuserlist.length > 0 ? (
                          <TableBody>
                            {opsuserlist.map((customer) => (
                              <TableRow hover key={customer.id}>
                                {customer.is_active === "True" ? (
                                  <React.Fragment>
                                    <TableCell>
                                      <Box
                                        sx={{
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {customer.username}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>{customer.email}</TableCell>
                                    <TableCell>
                                      {moment(customer.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Groupmodify UserID={customer.id} />
                                    </TableCell>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody align="center">
                            <TableCell>No Data Available</TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            ) : alignment === "justify" ? (
              <Box sx={{ pt: 0 }}>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  style={{
                    fontSize: "x-large",
                    lineHeight: "2.5",
                    paddingLeft: "24px",
                  }}
                  color="textPrimary"
                  variant="body1"
                >
                  Read-only Group
                </Typography>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 600 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>created At</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {readonlyuserlist.length > 0 ? (
                          <TableBody>
                            {readonlyuserlist.map((customer) => (
                              <TableRow hover key={customer.id}>
                                {customer.is_active === "True" ? (
                                  <React.Fragment>
                                    <TableCell>
                                      <Box
                                        sx={{
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {customer.username}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>{customer.email}</TableCell>
                                    <TableCell>
                                      {moment(customer.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Groupmodify UserID={customer.id} />
                                    </TableCell>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody align="center">
                            <TableCell>No Data Available</TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            ) : (
              ""
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  color="primary"
                  startIcon={<PersonAddIcon />}
                  onClick={handleClickOpen}
                >
                  Add User
                </Button>
              </Box>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  width: "600px",
                }}
              >
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">CREATE USER</DialogTitle>
                  <div style={{ paddingLeft: "50px" }}>
                    {state.errorMessage && (
                      <React.Fragment>
                        <Alert
                          style={{ width: "490px" }}
                          action={
                            <React.Fragment>
                              <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloserror}
                              ></IconButton>
                            </React.Fragment>
                          }
                          open={erroropen}
                          onClose={handleCloserror}
                          severity="error"
                        >
                          {state.errorMessage}
                        </Alert>
                      </React.Fragment>
                    )}
                  </div>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      name="email"
                      value={state.email}
                      onChange={handleChange2}
                      autoComplete="new-email"
                      for="email"
                      required
                    />
                    <TextField
                      for="username"
                      id="username"
                      autoFocus
                      margin="dense"
                      label="Name*"
                      type="text"
                      fullWidth
                      value={state.username}
                      onChange={handleChange2}
                      name="username"
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Password*"
                      fullWidth
                      for="password"
                      type="password"
                      autoComplete="new-password"
                      name="password"
                      id="password"
                      value={state.password}
                      onChange={handleChange2}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Conform Password*"
                      fullWidth
                      for="password2"
                      type="password"
                      autoComplete="new-password"
                      name="password2"
                      id="password2"
                      value={state.password2}
                      onChange={handleChange2}
                    />
                  </DialogContent>{" "}
                  <DialogContent>
                    <Grid item md={13} xs={12}>
                      <TextField
                        fullWidth
                        label="Groups"
                        name="groups"
                        id="groups"
                        onChange={handleChange2}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={state.groups}
                        variant="outlined"
                      >
                        <option></option>
                        <option value="1">Admin</option>
                        <option value="2">Read-Only</option>
                        <option value="3">OPS</option>
                      </TextField>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleSubmitClick}
                      color="primary"
                    >
                      Create
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  width: "600px",
                }}
              >
                <Dialog
                  open={opendelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete the User{" "}
                    <strong style={{ color: "#EF5350" }}>
                      {localStorage.getItem("specifyusername")}
                    </strong>{" "}
                    ?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                      Disagree
                    </Button>
                    <Button
                      onClick={Delete(state.delete_id)}
                      color="primary"
                      autoFocus
                    >
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  width: "600px",
                }}
              >
                <Dialog
                  open={openactive}
                  onClose={handleCloseActive}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  {" "}
                  {userlist.map((userlist) => {
                    return (
                      <React.Fragment>
                        {userlist.email === state.email ? (
                          <React.Fragment>
                            <DialogTitle id="alert-dialog-title">
                              User already exist, It is in Inactive state. if
                              you want to Activate the account, Please Click
                              Activate
                              <strong style={{ color: "#EF5350" }}>
                                {" "}
                                {state.email}
                              </strong>{" "}
                              ?
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description"></DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleCloseActive}
                                color="primary"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={activate(userlist.id)}
                                color="primary"
                                autoFocus
                              >
                                Activate
                              </Button>
                            </DialogActions>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  })}
                </Dialog>
              </div>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  width: "600px",
                }}
              >
                <Dialog
                  open={opencontact}
                  onClose={handleCloseContact}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {" "}
                    User is Inactive, Please Contact
                    <strong>
                      <a
                        href="mailto:support@zippyops.com"
                        style={{ marginLeft: "5px", color: "#2096ff" }}
                      >
                        support@zippyops.com
                      </a>
                    </strong>{" "}
                    ?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseContact} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Card>
                <PerfectScrollbar>
                  <Box sx={{ minWidth: 600 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      {userlist.length > 0 ? (
                      <TableBody>
                        {userlist.map((customer) => (
                          <TableRow hover key={customer.id}>
                            {" "}
                            {customer.is_active === "True" ? (
                              <React.Fragment>
                                <TableCell>{customer.username}</TableCell>
                                <TableCell>{customer.email}</TableCell>
                                <TableCell>
                                  <Usermodify UserID={customer.id} />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    color="secondary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      handleClickOpenDeleteindual();
                                      localStorage.setItem(
                                        "specifyusername",
                                        customer.email
                                      );
                                      del(customer.id);
                                    }}
                                    variant="contained"
                                    style={{ height: "29px" }}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                      ) : 
                        (
                          <TableBody align="center">
                            <TableCell>No Data Available</TableCell>
                          </TableBody>
                        )}
                    </Table>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "600px",
                      }}
                    >
                      <Dialog
                        open={opendeleteindual}
                        onClose={handleCloseDeleteindual}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          Are you sure you want to delete the User{" "}
                          <strong style={{ color: "#EF5350" }}>
                            {localStorage.getItem("specifyusername")}
                          </strong>{" "}
                          ?
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description"></DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseDeleteindual}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={Delete(state.delete_id)}
                            color="primary"
                            autoFocus
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Box>
          </TabPanel>
        </div>
      )}
    </React.Fragment>
  );
}
