import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Alert } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ShuffleIcon from "@material-ui/icons/Shuffle";

const Groupmodify = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    groups: [],
    errorMessage: "",
    right: false,
    radioList: "A",
  });

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const register = {
      groups: state.groups.toString(),
    };
    const UserID = props.UserID;
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/groupupdate/${UserID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(register),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
      });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [userlist, setusers] = useState([]);
  useEffect(() => {
    const UserID = props.UserID;
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/user_list/${UserID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          localStorage.setItem("user_access", data.detail);
        } else {
          setusers(data);
        }
      })
      .catch((error) => {});
  }, [props.UserID]);

  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        startIcon={<ShuffleIcon />}
        color="primary"
        variant="contained"
        style={{ height: "28px", backgroundColor: "#ec407a" }}
      >
        Change Group
      </Button>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div>
          {state.errorMessage && (
            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
              {" "}
              {state.errorMessage}{" "}
            </Alert>
          )}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Group</DialogTitle>
          <DialogContent>
            <Grid item md={20} xs={12}>
              <TextField
                fullWidth
                name="groups"
                id="groups"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={state.groups}
                variant="outlined"
                style={{ width: "300px" }}
              >
                {parseInt(userlist.groups) === 1 ? (
                  <React.Fragment>
                    <option value="1">Admin</option>
                    <option value="2">Read-Only</option>
                    <option value="3">OPS</option>
                  </React.Fragment>
                ) : parseInt(userlist.groups) === 2 ? (
                  <React.Fragment>
                    <option value="2">Read-Only</option>
                    <option value="1">Admin</option>
                    <option value="3">OPS</option>
                  </React.Fragment>
                ) : parseInt(userlist.groups) === 3 ? (
                  <React.Fragment>
                    <option value="3">OPS</option>
                    <option value="1">Admin</option>
                    <option value="2">Read-Only</option>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </TextField>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmitClick} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Groupmodify;
