import H2 from '@material-tailwind/react/Heading2';
import LeadText from '@material-tailwind/react/LeadText';
import React from 'react';
import background from '../../assets/img/sanboxlanding.jpg';

export default function Header() {
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" style={{ backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(0,0,0,0.6) 0),url(${background})` }}/>
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <H2 color="white">SandboxAsService</H2>
                        <div className="text-gray-200">
                            <LeadText color="white" component={'div'}>
                            Pre-configured independent testing environments on-demand to test your codes.
                            Sandbox As Service comes in handy when you’re looking to test your application and change items while ensuring your real-world system and user data are safe from any damage associated with those new features

                            </LeadText>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
