import React from 'react';
import { Navigate } from 'react-router-dom';
import NotFound from './pages/Error/NotFound';
import MainPage from './pages/auth/MainPage/MainPage';
import LoginPage from './pages/auth/Login/LoginPage';
import Products from './pages/auth/Products/MainPage';
import Email from './pages/auth/Login/Email';
import ForgotPassword from './pages/auth/Login/ForgotPassword';
import SuccessEmail from './pages/auth/Login/Welcome';
import ResetSuccess from './pages/auth/Login/ResetStatus';
import RegisterPage from './pages/auth/Register/RegisterPage';
import WelcomePage from './pages/auth/Welcome/Welcome';
import UnSubscribe from './pages/UnSubscribe/Success';
import 'src/assets/styles/tailwind.css';

const authroutes = [
  {
    path: '/',
    // element: <MainLayout />,
    children: [
      { path: '', element: <MainPage /> },
      { path: 'products', element: <Products /> },
      { path: 'login', element: <LoginPage /> },   
      { path: 'register', element: <RegisterPage /> },
      { path: 'forgotpassword', element: <Email /> },  
      { path: 'emailsend', element: <SuccessEmail /> },
      { path: 'passwdresetsuccess', element: <ResetSuccess /> },
      { path: 'resetpassword/:uidb64/:token', element: <ForgotPassword /> },
      { path: 'welcome', element: <WelcomePage /> },
      { path: 'unsubscribe', element: <UnSubscribe /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/login" /> },
    ]
  }
];

export default authroutes;
