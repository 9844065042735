import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Box, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Technology(props) {
  const { state, onState, handleNext, classes, lablist } = props;
  const [temporary, settemporary] = useState([]);
  const [find, setfind] = useState(false);

  const handleReload = () => {
    window.location.reload();
  };

  const handleChange = (e) => {
    e.preventDefault;
    setfind(false);
    const { id, value } = e.target;
    onState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    const checklab = lablist.map((instance) => {
      if (instance.name === value) {
        setfind(true);
      }
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ pb: 2 }}>
        
      </Box>
      {temporary.name && !/^[a-z0-9_\-]{1,30}$/.test(temporary.name) ? (
        <React.Fragment>
          <Box sx={{ pb: 2 }}>
            <Alert severity="warning">
              Please Enter the lower case letters and Only 15 characters allow
            </Alert>
          </Box>
        </React.Fragment>
      ) : (
        ""
      )}

      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <InputLabel>
            Name<small style={{ paddingLeft: "10px" }}>required</small>
            <small style={{ color: "red" }}>*</small>
          </InputLabel>
          <TextField
            fullWidth
            helperText={
              find
                ? "Lab name already exists, Please enter the unique name"
                : "Please specify the name"
            }
            name="name"
            id="name"
            color={temporary.name && !find ? "success" : ""}
            error={find}
            onChange={handleChange}
            required
            autoComplete="name"
            InputProps={{
              style: {
                height: "40px",
              },
            }}
          />
        </Grid>
        
        
        <Grid item xs={12}>
          <InputLabel>
            Description<small style={{ paddingLeft: "10px" }}>(optional)</small>
          </InputLabel>
          <TextField
            fullWidth
            name="description"
            id="description"
            onChange={handleChange}
            required
            autoComplete="family-name"
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReload}
            className={classes}
          >
            Cancel
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes}
            disabled={
              temporary.name 
                ? false
                : true
            }
          >
            Next
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );
}
