import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import ReactLoading from "react-loading";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Sanboximg1 from "../../../assets/img/tech2.webp";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  image: {
    backgroundImage: `url(${Sanboximg1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    paddingLeft: "7rem",
    paddingRight: "7rem",
    paddingTop: "1rem",
  },
  btncenter: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 30px",
    paddingLeft: 10,
  },
}));

function Progress(props) {
  const { submitted, refreshPage } = props;

  const delay = 3;

  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return show ? (
    refreshPage()
  ) : (
    <React.Fragment>
      <style>
        {`
        .loader {
          background: #ffffff;
          bottom: 0;
          left: 0;
          opacity: 0.8;
          position: absolute;
          right: 0;
          top: 0;
      }
      
      .loader,
      .loader-content {
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .loader-message {
          font-size: 2.5em;
          margin-top: 1em;
      }
      `}
      </style>
      <div className="loader">
        <div className="loader-content">
          <ReactLoading
            type="spinningBubbles"
            color="#BBD2C5"
            height={300}
            width={150}
          />
        </div>
      </div>
      <div className="loader">Loading ...</div>
    </React.Fragment>
  );
}

export default function LoginPage(props) {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const { ...rest } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [state, setState] = useState({
    email: "",
    password: "",
    errorMessage: "",
    submitted: false,
    handleChange: false,
  });

  localStorage.clear();

  const CLIENT_ID = `${process.env.REACT_APP_CLIENTID}`;
  const responseGoogle = (response) => {
    const google = {
      auth_token: response.credential,
    };
    fetch(`${process.env.REACT_APP_API_URL}/social_auth/google/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(google),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("user", data.username);
        localStorage.setItem("email", data.email);
        localStorage.setItem("group", data.group);
        localStorage.setItem("token", data.tokens.access);
        localStorage.setItem("google", "google");
        if (data.username) {
          window.location.href = "/app/dashboard";
        } else {
          if (data.email) {
            setState({ email: "" });
            setState({ password: "" });
            setState({ errorMessage: data.email[0] });
          } else {
            setState({ errorMessage: data.detail });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem("token");
      });
  };

  const failureGoogle = (response) => {
    console.log(response);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function refreshPage() {
    window.location.reload();
  }

  const handleSubmitClick = (e) => {
    setState({ handleChange: false });
    const login = {
      email: state.email,
      password: state.password,
    };
    setState({ submitted: true });
    fetch(`${process.env.REACT_APP_API_URL}/auth/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(login),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.tokens && data.email && data.username) {
          localStorage.setItem("user", data.username);
          localStorage.setItem("email", data.email);
          localStorage.setItem("group", data.group);
          localStorage.setItem("token", data.tokens.access);
          fetch(
            `${process.env.REACT_APP_API_URL}/api/user_management/user_list/`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
            .then((resp) => resp.json())
            .then((data) => {
              localStorage.setItem("user_access", data.detail);
              window.location.href = "/app/dashboard";
            });
        } else {
          setOpen(true);
          setState({ email: "" });
          setState({ password: "" });
          setState({ submitted: false });
          // setLoading(false)
          if (data.email) {
            setState({ email: "" });
            setState({ password: "" });
            setState({ errorMessage: data.email[0] });
            setOpen(true);
          } else if (data.password) {
            setState({ email: "" });
            setState({ password: "" });
            setState({ errorMessage: data.password[0] });
            setOpen(true);
          } else if (data.password && data.email) {
            setState({ email: "" });
            setState({ password: "" });
            setState({ errorMessage: data.email[0] });
            setOpen(true);
          } else {
            setState({ email: "" });
            setState({ password: "" });
            setState({ errorMessage: data.detail });
            setOpen(true);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem("token");
      });
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmitClick();
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <RemoveScrollBar />
      {state.submitted ? (
        <Progress submitted={state.submitted} refreshPage={refreshPage} />
      ) : (
        <React.Fragment>
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              {state.errorMessage && !state.submitted && (
                <React.Fragment>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Note archived"
                    style={{
                      paddingTop: "80px",
                      paddingRight: "50px",
                      position: "fixed",
                    }}
                    action={
                      <React.Fragment>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                    maxSnack={3}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {state.errorMessage}
                    </Alert>
                  </Snackbar>
                </React.Fragment>
              )}

              <img
                style={{
                  width: "40%",
                  height: "40%",
                  class: "center",
                  marginleft: "auto",
                  marginright: "auto",
                  display: "block",
                  paddingTop: "20px",
                }}
                alt="..."
                src={require("../../../assets/img/rectcolor.png").default}
              />
              <a
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                  fontSize: "20px",
                  color: "#757575",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                }}
              >
                Sign in with
              </a>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GoogleOAuthProvider
                  className="loginBtn loginBtn--google"
                  onClick={responseGoogle}
                  clientId={CLIENT_ID}
                  color="default"
                  style={{
                    marginright: "0px",
                    theme: "#0000FF",
                    shape: "circle",
                    background: "rgb(255 255 255)",
                    padding: "0px",
                    borderRadius: "200px",
                    mixBlendMode: "overlay",
                  }}
                >
                  <GoogleLogin
                    onSuccess={responseGoogle}
                    onError={failureGoogle}
                  />
                </GoogleOAuthProvider>
              </div>
              <Divider
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "#757575",
                }}
              >
                or
              </Divider>

              <form className={classes.form}>
                <TextField
                  display="block"
                  marginright="auto"
                  marginleft="auto"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  autoFocus
                  value={state.email}
                  onChange={handleChange}
                  onKeyPress={handleKeypress}
                />

                <TextField
                  display="block"
                  marginright="auto"
                  marginleft="auto"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  placeholder="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onKeyPress={handleKeypress}
                  value={state.password}
                  validate={{
                    pattern: {
                      value:
                        "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
                      errorMessage:
                        "Requires min 8 char, 1 upper case, 1 numeric and 1 special char",
                    },
                  }}
                />

                <div className={classes.btncenter}>
                  <Button
                    simple
                    variant="contained"
                    style={{
                      paddingTop: "10px",
                      color: "#ffffff",
                      backgroundColor: "#e91e63",
                    }}
                    size="lg"
                    onClick={handleSubmitClick}
                    disabled={state.submitted ? true : false}
                  >
                    Sign In
                  </Button>
                </div>
                <br />
                <Divider
                  style={{
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "#757575",
                  }}
                >
                  or
                </Divider>
                {/* <div
                  className="text-muted text-center mt-2 mb-3"
                  style={{ fontFamily: "sans-serif", paddingBottom: "px" }}
                > */}
                  <Grid container spacing={10}>
                  <Grid item md={6} xs={12}>
                      <Link 
                       color="primary"
                       href="/forgotpassword" 
                       fontFamily="sans-serif"                      
                       paddingTop="rem"
                       marginLeft="auto"
                       marginRight="auto"
                       display="block"
                       paddingBottom="10px"
                       paddingLeft="20px"
                       fontSize="17px"
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item md={6} xs={12}>  
                  <Link
                    color="primary"
                    href="register"
                    fontFamily="sans-serif"
                    textAlign="center"
                    paddingTop="rem"
                    marginleft="auto"
                    marginright="auto"
                    display="block"
                    paddingBottom="10px"
                    fontSize="17px"
                  >
                    Create new account
                  </Link>
                  </Grid>
                  </Grid>
                {/* </div> */}

                <div>
                  <Link
                    color="primary"
                    href="https://www.zippyops.com/contact-us"
                    target="_blank"
                    fontFamily="sans-serif"
                    textAlign="center"
                    paddingTop="rem"
                    marginTop="1rem"
                    marginleft="auto"
                    marginright="auto"
                    display="block"
                    fontSize="17px"
                  >
                    <a>Contact us</a>
                  </Link>

                  <br />

                  <br />

                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#757575",
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                    }}
                  >
                    &copy; {1900 + new Date().getYear()} ZippyOPS Consulting
                    Services LLP
                  </Typography>
                </div>

                <div
                  style={{ paddingBottom: "30px" }}
                  className="col-sm-12"
                ></div>
              </form>
            </div>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}
