import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box, Button, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function Schedule(props) {
  const { onState, activeStep, handleBack, handleSubmitClick, classes } = props;

  const [temporary, settemporary] = React.useState([]);

  const handlecheckChange = (event) => {
    onState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    onState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <React.Fragment>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h6" gutterBottom>
          {" "}
          Budget{" "}
        </Typography>
      </Box>
      <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
        <Typography variant="h8">
          Please specify the budget (In Credits) for your lab. if your budget is
          reached, your lab will be automatically stopped. And the next day it
          will be terminated.
        </Typography>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            helperText="NOTE: Enter your Budget in Credits"
            name="budget"
            id="budget"
            onChange={handleChange}
            required
            type="number"
            variant="outlined"
            InputProps={{
              style: {
                height: "40px",
              },
            }}
          ></TextField>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handlecheckChange}
                name="env_keep"
                color="secondary"
              />
            }
            label={<Typography variant="h6"> Keep lab environment </Typography>}
          />
        </Grid>
        {temporary.env_keep ? (
          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              name="env_keep_date"
              id="env_keep_date"
              type="date"
              onChange={handleChange}
              required
              variant="outlined"
              InputProps={{
                style: {
                  height: "40px",
                },
              }}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Typography variant="h8">
        Turn on this feature to maintain your lab environment for another week
        incase of completion of your schedule or exceeded lab budget.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReload}
            className={classes}
          >
            Cancel
          </Button>
        </div>
        <div className={classes}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} className={classes}>
              Back
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitClick}
            className={classes}
            disabled={temporary.budget ? false : true}
          >
            Create
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );
}