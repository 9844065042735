import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import authroutes from './auth_routes';
import jwtDecode from "jwt-decode";

const App = () => {

  const isAuthenticated = () => {
    if (localStorage.getItem('token')) {
      let token = localStorage.getItem('token')
      const { exp } = jwtDecode(token)
      const expirationTime = (exp * 1000) - 60000
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        // set LocalStorage here based on response;
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const routing = useRoutes(routes);
  const authrouting = useRoutes(authroutes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {isAuthenticated() ?  
      routing 
      : 
      authrouting }
    </ThemeProvider>
  );
};

export default App;
