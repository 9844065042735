import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import TextField from '@material-ui/core/TextField';
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  Alert,
  Grid,
  Typography
} from "@material-ui/core"
import { currencies } from './Currency';

var PUBLISHABLE_KEY = `${process.env.REACT_PUBLISHABLE_KEY}`;
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('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');
  }
  return stripePromise;
};

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const [stripeError, setStripeError] = React.useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const [amountopen, setAmountopen] = React.useState(false);

  const handleClickAmountopen = () => {
    setAmountopen(true);
  };

  const handleAmountclose = () => {
    setAmountopen(false);
    window.location.reload();
  };

  const [state, setState] = React.useState({
    currency: "",
    credit: "",
    amount: ""
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCurrencyConversion = () => {
    const postdata = {
      currency: state.currency,
      credit : state.credit
    }
    fetch(`${process.env.REACT_APP_API_URL}/api/currencyconversion/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(postdata)
    })
      .then((resp) => resp.json())
      .then((data) => {
        let name = 'amount'
        setState((prevState) => ({
          ...prevState,
          [name]: data,
        }));
        setOpen(false);
        setAmountopen(true);
      })
      .catch((error) => {
        console.error(error);
        window.location.reload();
      }); 
  };  

  const redirectToCheckout = async () => {
    const apikey = `${process.env.REACT_APP_PUBLISHABLEKEY}`;
    const stripe = await loadStripe(apikey);    
    const postdata = {
      currency: state.currency,
      amount : state.amount,
      credit : state.credit
    }
    fetch(`${process.env.REACT_APP_API_URL}/api/sessioncheckout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(postdata)
    })
      .then((resp) => resp.json())
      .then((data) => {
          if (data.id) {
            const { error } = stripe.redirectToCheckout({sessionId: data.id});
            if (error) { 
              setStripeError(error.message) 
            }
          } else {
            setStripeError(data)
          }
      })
      .catch((error) => {
        console.error(error);
        window.location.reload();
      }); 
  };  
  return (
    <div>
      <Button
              style={{textAlignLast: 'right', webkitWritingMode: 'vertical-rl'}}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
          startIcon={<AccountBalanceWalletTwoToneIcon />}
        >
          Pay
        </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle onClose={handleClose} id="customized-dialog-title">Pay Your Wallet <strong>(Current balance: $100)</strong></DialogTitle>
        {stripeError ?
        <Alert severity="error">{stripeError}</Alert>
        : "" }
        
        <DialogContent>
        <InputLabel id="expmonth">Currency</InputLabel>
        <Select
                            id={`currency`}
                            fullWidth
                            onChange={handleChange}
                            value={state.currency}
                            name={`currency`}
                            autoComplete={`currency`}
                            >
                            <MenuItem>-- --</MenuItem>
                            {Object.keys(currencies).map(item => {
          return(
              <MenuItem value={currencies[item].code}>{currencies[item].code}</MenuItem>
          )
      })}                            
                          </Select>
         <br />
         <br />
         <br />
        <InputLabel id="expmonth">Credits</InputLabel>
        <TextField
          fullWidth
          name={`credit`}
          id={`credit`}
          type="credit"
          onChange={handleChange}
          value={state.credit}
          required
          autoComplete={`credit`}
        />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={state.currency && state.credit ? false : true} variant="contained" autoFocus onClick={handleCurrencyConversion} color="primary">
            Next
          </Button>          
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={amountopen}
        onClose={handleAmountclose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
      >
        {stripeError ?
        <Alert severity="error">{stripeError}</Alert>
        : "" }
        
        <DialogContent>
  <Card
    sx={{ height: '100%' }}
  >
    <CardContent>        
      <Grid
        container
        spacing={0}
      >
        <Grid item>
        <Grid container>
        <Typography variant="h5" display="block" gutterBottom>
                Currency:  
                <strong className="ml-4">{`${state.currency}`}</strong>
                </Typography>             
            </Grid>          
        <Grid container>
        <Typography variant="h5" display="block" gutterBottom>
                Credits:  
                <strong className="ml-4">{`${state.credit}`}</strong>
                </Typography>             
            </Grid>
            <Grid container>
        <Typography variant="h5" display="block" gutterBottom>
                Amount:  
                {currencies && currencies[state.currency] ? <strong className="ml-4">{`${currencies[state.currency].symbol_native}`}</strong> : ""}
                <strong >{`${state.amount} /-`}</strong>
                </Typography>             
            </Grid>
        </Grid>
      </Grid>  
      <Typography variant="h5"><small>
              Note: Tax included in Price ( {state.currency && state.currency === "INR" ? "%18" : "0.3%"})
              </small></Typography>  
      </CardContent>
  </Card>            
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAmountclose} color="primary">
            Cancel
          </Button>
          <Button disabled={state.amount ? false : true} variant="contained" autoFocus onClick={redirectToCheckout} color="primary">
            Checkout
          </Button>          
        </DialogActions>
      </Dialog>      
    </div>
  );
}
