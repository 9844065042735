import {
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
  } from "src/components/authentication/assets/jss/labs.js";
  
  const cardHeaderStyle = {
    cardHeader: {
      width: "200px",
      borderRadius: "3px",
      padding: "1rem 15px",
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "-30px",
      border: "0",
      marginBottom: "0",
    },
    cardHeaderPlain: {
      marginLeft: "0px",
      marginRight: "0px",
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
  };
  
  export default cardHeaderStyle;
  