import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const IdleTimeOutModal = (props) => {
  const { show, open, focusAfterClose, handleContinue, handleLogout } = props;

  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    if (show) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        handleLogout();
      }
    }
  });

  return (
    <>
      <Dialog onClose={handleContinue} aria-labelledby="customized-dialog-title" scroll="paper" open={open} fullWidth maxWidth="sm">
        <DialogTitle id="customized-dialog-title" onClose={handleContinue}>
        You Have Been Idle!
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
          Your session is about to expire in{" "}
          <span style={{ color: "red" }}> {seconds} </span> seconds due to
          inactivity. You will be redirected to the login page
          </Typography>          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLogout} color="primary">
          Logout
          </Button>
          <Button autoFocus onClick={handleContinue} color="primary">
          Continue Session
          </Button>          
        </DialogActions>
      </Dialog>    
    </>
  );
};

export default IdleTimeOutModal;
