import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

export default function CustomDayPicker(props) {
  const { setFrequencyDay, setFrequencyMonth, startDate, endDate } = props;
  const [value, setValue] = useState(new Date());

  const handleChange = (dates) => {
    const groupNames = Array.from(new Set(dates.map((k) => k.month.number)));
    let groups = {};
    groupNames.forEach((k) => {
      groups[k] = [];
    });
    dates.forEach((k) => {
      const month = k.month.number;
      groups[month].push(k);
    });

    let collectDate = [];
    let collectMonth = [];
    let collectExpression = [];

    var FetchMonth;
    for (let key in groups) {
      for (let event of groups[key]) {
        var FetchDates;
        const ChoosenDate = `${event.day}`;
        const ChoosenMonth = `${event.month.number}`;
        collectDate.push(ChoosenDate);
        collectMonth.push(ChoosenMonth);
        if (FetchDates === undefined || FetchDates === "") {
          FetchDates = ChoosenDate;
        } else {
          FetchDates = FetchDates + "," + ChoosenDate;
        }
      }
      collectExpression.push(`${FetchDates} ${key}`);
      FetchDates = "";
    }
    setFrequencyDay(collectExpression);
  };

  let localTime = new Date();
  let minDate1 = moment(localTime).format("YYYY-MM-DDTHH:MM");

  var currentDate = new Date().toISOString().substring(0, 10);
  let DateTimeformat = `${currentDate}`;
  let Dateformat = new Date(DateTimeformat);
  var nextMonth = ((Dateformat.getMonth() + 1) % 12) + 1;
  var cMonth = Dateformat.getMonth() + 1;
  if (cMonth === 12) {
    var year = localTime.getFullYear() + 1;
    var date = new Date(`${year}`, `${nextMonth}`, 0);
    var newendDate = date.getDate();
    var maxDate = `${year}-${nextMonth}-${newendDate}`;
  } else {
    var year = localTime.getFullYear();
    var date = new Date(`${year}`, `${nextMonth}`, 0);
    var newendDate = date.getDate();
    var maxDate = `${year}-${nextMonth}-${newendDate}`;
  }

  return (
    <DatePicker
      format="DD/MM/YYYY"
      minDate={startDate ? startDate : minDate1}
      maxDate={endDate ? endDate : maxDate}
      style={{
        textAlign: "center",
        color: "var(--text-color)",
        width: "340px",
        height: "40px",
        border: "groove",
        marginRight: "10px",
        marginLeft: "10px",
      }}
      multiple
      // value={value}
      onChange={(dates) => handleChange(dates)}
    />
  );
}
