import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Container,
  CardContent,
  Divider,
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import SearchField from "react-search-field";
import { Tab, Tabs } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader1 from "./LabHeader";

import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import StartLab from "./Actions/Startlab";
import StopLab from "./Actions/Stoplab";
import ReportEX from "./ReportExport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#b5b6b7",
    display: "flex",
    height: 280,
  },
  tabs: {
    borderRight: `1px solid`,
    overflow: `visible`,
  },
  title: {
    fontSize: 14,
  },
}));

const Detail = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const guacamole_url = process.env.REACT_APP_GUACAMOLE_URL;

  function DateTime1(startDate) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDate1 = new Date(startDate).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const startDate2 = startDate1.split(",");
    const startDatef = startDate2[0].replace("/", "-");
    const startDate3 = startDatef.replace("/", "-");

    return startDate3;
  }

  function DateTime2(endDate) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endDate1 = new Date(endDate).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const endDate2 = endDate1.split(",");
    const endDatef = endDate2[0].replace("/", "-");
    const endDate3 = endDatef.replace("/", "-");
    return endDate3;
  }

  function DateTime3(startDate, startTime) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const startTime1 = new Date(
      startDate + "T" + startTime + ":00.000Z"
    ).toLocaleString("en-GB", { timeZone: `${timezone}` });
    const startTime2 = startTime1.split(",");
    const startTime3 = startTime2[1].substring(0, 6);
    return startTime3;
  }

  function DateTime4(endDate, endTime) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endTime1 = new Date(
      endDate + "T" + endTime + ":00.000Z"
    ).toLocaleString("en-GB", { timeZone: `${timezone}` });
    const endTime2 = endTime1.split(",");
    const endTime3 = endTime2[1].substring(0, 6);
    return endTime3;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [instancelist, setinstancelist] = useState([]);
  useEffect(() => {
    const InstanceID = localStorage.getItem("InstanceID");
    fetch(`${process.env.REACT_APP_API_URL}/api/labcustomize/${InstanceID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setinstancelist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
 
  
  const [pccreportdataset, setPccreportdataset] = useState([]);

  useEffect(() => {
    const InstanceID = localStorage.getItem("InstanceID");
    fetch(`${process.env.REACT_APP_API_URL}/api/pcclabreport/${InstanceID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setPccreportdataset(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [opendelete, setOpendelete] = React.useState(false);

  const handleClickOpenDelete = () => {
    setOpendelete(true);
  };

  const handleCloseDelete = () => {
    setOpendelete(false);
  };

  const Delete = () => {
    return function () {
      window.location.href = "/app/instance/custom";
      const InstanceID = localStorage.getItem("InstanceID");
      fetch(
        `${process.env.REACT_APP_API_URL}/api/manageInstance/${InstanceID}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          handleCloseDelete();          
        })
        .catch((error) => {
          handleCloseDelete();          
        });
      handleCloseDelete();
    };
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const open1 = Boolean(anchorEl);

  const id = open1 ? "simple-popover" : undefined;

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [searchitems, setSearchItems] = React.useState(0);
  const [copied, setCopied] = React.useState(false);

  return (
    <>
      {instancelist.map((instancelist) => (
        <React.Fragment>
          <Helmet>
            <title>SandboxAsService</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "100%",
              py: 3,
            }}
          >
            <div
              style={{ alignItems: "center", display: "flex", width: "600px" }}
            >
              <Dialog
                open={opendelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Are you sure you want to delete the Lab{" "}
                  <strong style={{ color: "#EF5350" }}>
                    {instancelist.name}
                  </strong>{" "}
                  ?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDelete} color="primary">
                    Disagree
                  </Button>
                  <Button onClick={Delete()} color="primary" autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {instancelist.status === "Stopped" ? (
                <StartLab />
              ) : instancelist.status === "Running" ? (
                <React.Fragment>
                  <ReportEX pccreportdataset={pccreportdataset} />
                  <StopLab />
                </React.Fragment>
              ) : (
                ""
              )}
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleClickOpenDelete}
              >
                Delete
              </Button>
            </Box>
            <CardHeader1
              color="primary"
              style={{
                background:
                  "-webkit-linear-gradient(left top, #444458, #7e4aaf)",
                background:
                  "linear-gradient(to bottom right, #444458, #7e4aaf)",
                textAlign: "center",
              }}
            >
              <Typography
                fontWeight="fontWeightBold"
                color="white"
                variant="h5"
              >
                Application: <strong>{instancelist.name} </strong>
              </Typography>
            </CardHeader1>
            <Container maxWidth={false}>
              <Box border={0} sx={{ mt: 1 }}>
                <Card>
                  <CardContent>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      gutterBottom
                      marginTop={1}
                    >
                      Details
                      <Divider />
                    </Typography>
                    <div>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead style={{ backgroundColor: "#06396c" }}>
                            <TableRow>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Name
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Status
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                No of Trainee
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Budget Credits
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Spent
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="name">
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {instancelist.name}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{
                                    display: "inline-block",
                                    color: "#00a200",
                                  }}
                                  gutterBottom
                                >
                                  {instancelist.status}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {instancelist.no_of_trainee}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight={500}
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >{`${instancelist.budget}`}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight={500}
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >{`${instancelist.spent}`}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      gutterBottom
                      marginTop={4}
                    >
                      Schedule
                      <Divider />
                    </Typography>
                    <div>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead style={{ backgroundColor: "#06396c" }}>
                            <TableRow>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Schedule Type
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Start Date
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                End Date
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Start Time
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Stop Time
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="name">
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {instancelist.depends_on}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {DateTime1(`${instancelist.start_date}`)}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {DateTime2(`${instancelist.end_date}`)}
                                </Typography>
                              </TableCell>
                              {instancelist.start_time == "" ||
                              instancelist.start_time == null ? (
                                <TableCell align="center">
                                  <Typography
                                    variant="h6"
                                    fontWeight={500}
                                    style={{ display: "inline-block" }}
                                    gutterBottom
                                  >
                                    NA
                                  </Typography>
                                </TableCell>
                              ) : (
                                <TableCell align="center">
                                  <Typography
                                    variant="h6"
                                    fontWeight={500}
                                    style={{ display: "inline-block" }}
                                    gutterBottom
                                  >
                                    {DateTime3(
                                      `${instancelist.start_date}`,
                                      `${instancelist.start_time}`
                                    )}
                                  </Typography>
                                </TableCell>
                              )}
                              {instancelist.stop_time == "" ||
                              instancelist.stop_time == null ? (
                                <TableCell align="center">
                                  <Typography
                                    variant="h6"
                                    fontWeight={500}
                                    style={{ display: "inline-block" }}
                                    gutterBottom
                                  >
                                    NA
                                  </Typography>
                                </TableCell>
                              ) : (
                                <TableCell align="center">
                                  <Typography
                                    variant="h6"
                                    fontWeight={500}
                                    style={{ display: "inline-block" }}
                                    gutterBottom
                                  >
                                    {DateTime4(
                                      `${instancelist.end_date}`,
                                      `${instancelist.stop_time}`
                                    )}
                                  </Typography>
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      gutterBottom
                      marginTop={4}
                    >
                      Instance Credentials
                      <Divider />
                    </Typography>
                    <div>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead style={{ backgroundColor: "#06396c" }}>
                            <TableRow>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Instance Username
                              </TableCell>
                              <TableCell
                                style={{ color: "#ffffff" }}
                                align="center"
                              >
                                Instance Password
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="name">
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {instancelist.username}
                                </Typography>
                                <CopyToClipboard
                                  text={instancelist.username}
                                  onCopy={() => setCopied(true)}
                                >
                                  <IconButton
                                    aria-label="Filter"
                                    color="primary"
                                    onClick={handleClick}
                                  >
                                    <FileCopyOutlinedIcon />
                                  </IconButton>
                                </CopyToClipboard>
                                <Popover
                                  id={id}
                                  open={open1}
                                  anchorEl={anchorEl}
                                  onClose={handleClose1}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography style={{ color: "forestgreen" }}>
                                    Copied!.
                                  </Typography>
                                </Popover>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant="h6"
                                  fontWeight="fontWeightBold"
                                  style={{ display: "inline-block" }}
                                  gutterBottom
                                >
                                  {instancelist.password}
                                </Typography>
                                <CopyToClipboard
                                  text={instancelist.password}
                                  onCopy={() => setCopied(true)}
                                >
                                  <IconButton
                                    aria-label="Filter"
                                    color="primary"
                                    onClick={handleClick}
                                  >
                                    <FileCopyOutlinedIcon />
                                  </IconButton>
                                </CopyToClipboard>
                                <Popover
                                  id={id}
                                  open={open1}
                                  anchorEl={anchorEl}
                                  onClose={handleClose1}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography style={{ color: "forestgreen" }}>
                                    Copied!.
                                  </Typography>
                                </Popover>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CardContent>
                </Card>
              </Box>
              <Divider />

              <Box border={0} sx={{ mt: 1 }} paddingBottom={0} paddingTop={0}>
                <Card>
                  <CardHeader
                    subheader={
                      <SearchField
                        value={searchitems}
                        placeholder="Search..."
                        onChange={(event) => {
                          setSearchItems(event);
                        }}
                        classNames="test-class"
                      />
                    }
                  />
                  <Divider />

                  <CardContent>
                    <Box sx={{ flexGrow: 1, display: "flex" }}>
                      <Tabs
                        orientation="vertical"
                        value={value}
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                          borderRight: 1,
                          borderColor: "divider",
                          minWidth: "170px",
                        }}
                      >
                        {instancelist.Instance_info.filter((val) => {
                          if (searchitems == "") {
                            return val;
                          } else if (
                            val.name
                              .toLowerCase()
                              .includes(searchitems.toLowerCase())
                          ) {
                            return val;
                          }
                        }).map((instance_info, i) => (
                          <Tab label={instance_info.name} {...a11yProps(i)} />
                        ))}
                      </Tabs>

                      <PerfectScrollbar>
                        {instancelist.Instance_info.filter((val) => {
                          if (searchitems == "") {
                            return val;
                          } else if (
                            val.name
                              .toLowerCase()
                              .includes(searchitems.toLowerCase())
                          ) {
                            return val;
                          }
                        }).map((instance_info, i) => {
                          const traineeid = i + 1;
                          return (
                            <React.Fragment>
                              <TabPanel value={value} index={i}>
                                <Grid
                                  container
                                  spacing={1}
                                  marginTop={2}
                                  marginBottom={4}
                                  marginLeft={0}
                                >
                                  
                                  <Grid item xs={12} marginLeft={0}>
                                    <Typography
                                      style={{ display: "inline-block" }}
                                      gutterBottom
                                    >
                                      console url :
                                    </Typography>
                                    <Typography
                                      fontWeight={500}
                                      marginLeft={1}
                                      style={{ display: "inline-block" }}
                                      gutterBottom
                                    >
                                      {guacamole_url}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={7} marginLeft={0}>
                                    <Typography
                                      style={{ display: "inline-block" }}
                                      gutterBottom
                                    >
                                      console username :
                                    </Typography>
                                    <Typography
                                      fontWeight={500}
                                      marginLeft={1}
                                      style={{ display: "inline-block" }}
                                      gutterBottom
                                    >
                                      {instance_info.username}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={5} marginLeft={0}>
                                    <Typography
                                      style={{ display: "inline-block" }}
                                      gutterBottom
                                    >
                                      console password :
                                    </Typography>
                                    <Typography
                                      style={{ display: "inline-block" }}
                                      marginLeft={2}
                                      fontWeight={500}
                                      gutterBottom
                                    >
                                      Sandbox@$service
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <TableContainer component={Paper}>
                                  <Table
                                    style={{ width: 1900 }}
                                    size="medium"
                                    aria-label="a dense table"
                                  >
                                    <TableHead
                                      style={{ backgroundColor: "#06396c" }}
                                    >
                                      <TableRow>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          name
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Public address
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Private address
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          status
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Applications{" "}
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          ProgrammingLang{" "}
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Tools{" "}
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          System tools{" "}
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Machine Type
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          OS Type
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Linux (or) Windows Type
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Storage
                                        </TableCell>
                                        <TableCell
                                          style={{ color: "#ffffff" }}
                                          align="left"
                                        >
                                          Port
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {instance_info.details.map((details) => (
                                        <React.Fragment>
                                          <TableRow key={details.name}>
                                            <TableCell
                                              align="left"
                                              component="th"
                                              scope="row"
                                            >
                                              {details.name}
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.public_ip_address}
                                              <CopyToClipboard
                                                text={details.public_ip_address}
                                                onCopy={() => setCopied(true)}
                                              >
                                                <IconButton
                                                  aria-label="Filter"
                                                  color="primary"
                                                  onClick={handleClick}
                                                >
                                                  <FileCopyOutlinedIcon />
                                                </IconButton>
                                              </CopyToClipboard>
                                              <Popover
                                                id={id}
                                                open={open1}
                                                anchorEl={anchorEl}
                                                onClose={handleClose1}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "center",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    color: "forestgreen",
                                                  }}
                                                >
                                                  Copied!.
                                                </Typography>
                                              </Popover>
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.private_ip_address}
                                              <CopyToClipboard
                                                text={
                                                  details.private_ip_address
                                                }
                                                onCopy={() => setCopied(true)}
                                              >
                                                <IconButton
                                                  aria-label="Filter"
                                                  color="primary"
                                                  onClick={handleClick}
                                                >
                                                  <FileCopyOutlinedIcon />
                                                </IconButton>
                                              </CopyToClipboard>
                                              <Popover
                                                id={id}
                                                open={open1}
                                                anchorEl={anchorEl}
                                                onClose={handleClose1}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "center",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    color: "forestgreen",
                                                  }}
                                                >
                                                  Copied!.
                                                </Typography>
                                              </Popover>
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.status === "Creating" ? (
                                                <Typography
                                                  variant="h6"
                                                  marginLeft={2}
                                                  fontWeight="fontWeightBold"
                                                  style={{
                                                    display: "inline-block",
                                                    color: "#2234E8",
                                                  }}
                                                  marginLeft={1}
                                                  gutterBottom
                                                >
                                                  {details.status}
                                                </Typography>
                                              ) : details.status ===
                                                  "Running" ||
                                                details.status === "running" ? (
                                                <Typography
                                                  variant="h6"
                                                  marginLeft={2}
                                                  fontWeight="fontWeightBold"
                                                  style={{
                                                    display: "inline-block",
                                                    color: "#209508",
                                                  }}
                                                  marginLeft={1}
                                                  gutterBottom
                                                >
                                                  {details.status}
                                                </Typography>
                                              ) : details.status ===
                                                  "Stopped" ||
                                                details.status === "stopped" ? (
                                                <Typography
                                                  variant="h6"
                                                  marginLeft={2}
                                                  fontWeight="fontWeightBold"
                                                  style={{
                                                    display: "inline-block",
                                                    color: "#D73206",
                                                  }}
                                                  marginLeft={1}
                                                  gutterBottom
                                                >
                                                  {details.status}
                                                </Typography>
                                              ) : details.status ===
                                                  "Stoping" ||
                                                details.status === "stoping" ? (
                                                <Typography
                                                  variant="h6"
                                                  marginLeft={2}
                                                  fontWeight="fontWeightBold"
                                                  style={{
                                                    display: "inline-block",
                                                    color: "#F3AF05",
                                                  }}
                                                  marginLeft={1}
                                                  gutterBottom
                                                >
                                                  {details.status}
                                                </Typography>
                                              ) : (
                                                ""
                                              )}
                                            </TableCell>
                                            {details.apps === "notapply" ? (
                                              <TableCell align="left">
                                                NA
                                              </TableCell>
                                            ) : (
                                              <TableCell align="left">
                                                {details.apps.map((number) => (
                                                  <li>{number}</li>
                                                ))}
                                              </TableCell>
                                            )}
                                            {details.programming ===
                                            "notapply" ? (
                                              <TableCell align="left">
                                                NA
                                              </TableCell>
                                            ) : (
                                              <TableCell align="left">
                                                {details.programming.map(
                                                  (number) => (
                                                    <li>{number}</li>
                                                  )
                                                )}
                                              </TableCell>
                                            )}
                                            {details.tools === "notapply" ? (
                                              <TableCell align="left">
                                                NA
                                              </TableCell>
                                            ) : (
                                              <TableCell align="left">
                                                <li>{details.tools}</li>
                                              </TableCell>
                                            )}
                                            {details.system_tools ===
                                            "notapply" ? (
                                              <TableCell align="left">
                                                NA
                                              </TableCell>
                                            ) : (
                                              <TableCell align="left">
                                                {details.system_tools.map(
                                                  (number) => (
                                                    <li>{number}</li>
                                                  )
                                                )}
                                              </TableCell>
                                            )}
                                            <TableCell align="left">
                                              {details.instance_type}
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.ostype}
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.ostype === "windows"
                                                ? details.windows_type
                                                : details.linux_type}
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.instance_storage}
                                            </TableCell>
                                            <TableCell align="left">
                                              {details.port}
                                            </TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>

                                {instancelist.customapp_credentials === "" ||
                                instancelist.customapp_credentials === null
                                  ? ""
                                  : instancelist.customapp_credentials.map(
                                      (cred) =>
                                        cred.app === "zabbix" ? (
                                          <Grid
                                            container
                                            spacing={1}
                                            marginTop={2}
                                            marginBottom={4}
                                            marginLeft={0}
                                          >
                                            <Grid item xs={4} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application :
                                              </Typography>
                                              <Typography
                                                fontWeight={500}
                                                marginLeft={1}
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                {cred.app}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={4} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application username :
                                              </Typography>
                                              <Typography
                                                fontWeight={500}
                                                marginLeft={1}
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                {cred.username}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={4} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application password :
                                              </Typography>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                marginLeft={2}
                                                fontWeight={500}
                                                gutterBottom
                                              >
                                                {cred.password}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={4} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application db_password :
                                              </Typography>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                marginLeft={2}
                                                fontWeight={500}
                                                gutterBottom
                                              >
                                                {cred.db_password}
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={8} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application url :
                                              </Typography>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                marginLeft={2}
                                                fontWeight={500}
                                                gutterBottom
                                              >{`http://<public-ip-address>:${cred.port}`}</Typography>
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          <Grid
                                            container
                                            spacing={1}
                                            marginTop={2}
                                            marginBottom={4}
                                            marginLeft={0}
                                          >
                                            <Grid item xs={4} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application :
                                              </Typography>
                                              <Typography
                                                fontWeight={500}
                                                marginLeft={1}
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                {cred.app}
                                              </Typography>
                                            </Grid>

                                            {cred.username === "null" ? (
                                              <Grid item xs={4} marginLeft={0}>
                                                <Typography
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  gutterBottom
                                                >
                                                  application username :
                                                </Typography>
                                                <Typography
                                                  fontWeight={500}
                                                  marginLeft={1}
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  gutterBottom
                                                >
                                                  NA
                                                </Typography>
                                              </Grid>
                                            ) : (
                                              <Grid item xs={4} marginLeft={0}>
                                                <Typography
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  gutterBottom
                                                >
                                                  application username :
                                                </Typography>
                                                <Typography
                                                  fontWeight={500}
                                                  marginLeft={1}
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  gutterBottom
                                                >
                                                  {cred.username}
                                                </Typography>
                                              </Grid>
                                            )}

                                            {cred.password === "null" ? (
                                              <Grid item xs={4} marginLeft={0}>
                                                <Typography
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  gutterBottom
                                                >
                                                  application password :
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  marginLeft={1}
                                                  fontWeight={500}
                                                  gutterBottom
                                                >
                                                  NA
                                                </Typography>
                                              </Grid>
                                            ) : (
                                              <Grid item xs={4} marginLeft={0}>
                                                <Typography
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  gutterBottom
                                                >
                                                  application password :
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  marginLeft={0}
                                                  fontWeight={500}
                                                  gutterBottom
                                                >
                                                  {cred.password}
                                                </Typography>
                                              </Grid>
                                            )}
                                            <Grid item xs={8} marginLeft={0}>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                gutterBottom
                                              >
                                                application url :
                                              </Typography>
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                }}
                                                marginLeft={1}
                                                fontWeight={500}
                                                gutterBottom
                                              >{`http://<public-ip-address>:${cred.port}`}</Typography>
                                            </Grid>
                                          </Grid>
                                        )
                                    )}
                              </TabPanel>
                            </React.Fragment>
                          );
                        })}
                      </PerfectScrollbar>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

export default Detail;
