import { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import CreateIcon from '@material-ui/icons/Create';
import ContactsIcon from '@material-ui/icons/Contacts';
import LoginIcon from '@material-ui/icons/Login';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Contactus from '@material-ui/icons/ContactSupport';

export default function DefaultNavbar() {
    const [openNavbar, setOpenNavbar] = useState(false);

    return (
        <Navbar color="transparent" navbar>
            <NavbarContainer>
                <NavbarWrapper>
                </NavbarWrapper>
                <img style={{ width: "20%", height: "20%" }}
                      alt="..."
                      src={
                        require("src/assets/img/rectwhite.png").default
                          
                      }/>
                <NavbarToggler
                        onClick={() => setOpenNavbar(!openNavbar)}
                        color="white"
                    />
               
                <NavbarCollapse open={openNavbar}>
                    <Nav>
                        <div className="flex flex-col pb-7 z-0 lg:flex-row lg:items-center" style={{color: "white"}}>
                            <Link to="/register">
                            <CreateIcon style={{paddingRight: '3px'}} />
                             Register&nbsp;&nbsp;&nbsp;&nbsp;
                            </Link>
                            <Link to="/admin">
                            <LoginIcon style={{paddingRight: '3px'}} />
                             Login  &nbsp;&nbsp;&nbsp;&nbsp;
                            </Link>
                            
                            <NavLink                                
                                style={{paddingLeft: '2px'}}
                                href="https://www.zippyops.com/contact-us"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                             <ContactsIcon />
                                contact Us 
                            </NavLink>
                            <NavLink                        
                                style={{paddingLeft: '1px'}}        
                                href="https://support.zippyops.com/"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                             <Contactus />
                                Support
                            </NavLink>
                            <NavLink                        
                                style={{paddingLeft: '1px'}}        
                                href="https://www.zippyops.com/"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                             <LibraryBooksIcon />
                                Docs
                            </NavLink>
                            
                           
                        </div>
                    </Nav>                    
                </NavbarCollapse>                
            </NavbarContainer>
        </Navbar>
    );
}
