import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import CardContent from "@material-ui/core/CardContent";
export default function CustomizedDialogs(props) {
  const { InstanceID } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <style>{`
.container {
  display: flex;
  flex-wrap: wrap;
  height:;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 650px;
  min-width: 200px;
}

.canvas {
  align-items: center;
  background: #eeeeee;
  border-radius: 50%;
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  display: flex;
  height: 10em;
  justify-content: center;
  margin: 1em 1em 2em 1em;
  width: 10em;
}

/* Spinner 1 starts here */
.spinner1 {
  align-items: center;
  border: .3em solid transparent;
  border-top: .3em solid #4DB6AC;
  border-right: .3em solid #4DB6AC;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}

@keyframes spinnerOne {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
/* Spinner 1 ends here */

/* Spinner 2 starts here */
.canvas2 {
  position: relative;
}

.spinner2 {
  animation: spinnerTwo 10s linear infinite;
  background: #4DB6AC;
  border-radius: 100px;
  height: 3em;
  transform-origin: top;
  position: absolute;
  top: 50%;
  width: .22em;
}

.hourHand {
  animation: spinnerTwo 100s linear infinite;
  background: #4DB6AC;
  border-radius: 100px;
  height: 2em;
  transform-origin: top;
  position: absolute;
  top: 50%;
  width: .2em;
}

.dot {
  background: #4DB6AC;
  border-radius: 100%;
  height: .5em;
  width: .5em;
}

@keyframes spinnerTwo {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
/* Spinner 2 ends here */

/* Spinner 3 starts here */
.spinner3 {
  animation: spinnerThree 1s linear infinite;
  background: #4DB6AC;
  border-radius: 100%;
  width: 3em;
  height: 3em;
}

@keyframes spinnerThree {
  0%, 35% {
    background: #4DB6AC;
    transform: scale(1);
  }
  20%, 50% {
    background: #80CBC4;
    transform: scale(1.3);
  }
}
/* Spinner 3 ends here */

/* Spinner 4 starts here */
.spinner4 {
  animation: spinnerFour 1s linear infinite;
  border: solid 7px transparent;
  border-top: solid 7px #4DB6AC;
  border-radius: 100%;
  width: 3em;
  height: 3em;
}

@keyframes spinnerFour {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Spinner 4 ends here */

/* Spinner 5 starts here */
.spinner5 {
  animation: spinnerFive 1s linear infinite;
  border: solid 1.5em #4DB6AC;
  border-right: solid 1.5em transparent;
  border-left: solid 1.5em transparent;
  border-radius: 100%;
  width: 0;
  height: 0;
}

@keyframes spinnerFive {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(60deg)
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Spinner 5 ends here */

/* Spinner 6 starts here */
.spinner6 {
  background: #4DB6AC;
  border-radius: 50%;
  height: 1em;
  margin: .1em;
  width: 1em;
}

.p1 {
  animation: fall 1s linear .3s infinite;
}

.p2 {
  animation: fall 1s linear .2s infinite;
}

.p3 {
  animation: fall 1s linear .1s infinite;
}

.p4 {
  animation: fall 1s linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-15px);
  }
  25%, 75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
/* Spinner 6 ends here */

@media (max-width: 600px) {
  .container {
    align-items: center;
    flex-direction: column;
  }
  .canvas {
    margin: 1em;
  }
}
`}</style>
      <Button size="sm" onClick={handleClickOpen}>
        <InfoTwoToneIcon color="primary" />
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogContent dividers>
          <Card style={{ minHeight: 300 }}>
            <CardContent>
              <div id="load">
                <div>your lab is Scheduled...</div>
                <div style={{ textAlign: "-webkit-center" }}>
                  <div class="canvas canvas2">
                    <div class="spinner2"></div>
                    <div class="hourHand"></div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
