import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import LiveHelpTwoToneIcon from "@material-ui/icons/LiveHelpTwoTone";

export default function SupportTeam(props) {
  const { status } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {status === "Failed" || status === "Cordoned" ? (
        <React.Fragment>
          <MenuItem onClick={handleClickOpen}>
          <IconButton aria-label="delete">
            <LiveHelpTwoToneIcon style={{ color: "yellowgreen" }} /> &nbsp;&nbsp;
            <span style={{ fontSize: "0.7em", color: "#172B4D" }}>Help</span>
          </IconButton>          
          </MenuItem>
        </React.Fragment>
      ) : (
        ""
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please contact the Support Team{" "}
            <strong style={{ color: "#EF5350" }}>Support@zippyops.com</strong>
          </DialogContentText>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
