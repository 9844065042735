import React from "react";
import { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
} from "@material-ui/core";
import SearchField from "react-search-field";
import TextField from "@material-ui/core/TextField";
import Payment from "./Payment";
import Clone from "./Clone";
import Moreinfo from "./Moreinfo";

const LabList = (props) => {
  const { results } = props;
  const [instancelist, setinstancelist] = useState([]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch(`${process.env.REACT_APP_API_URL}/api/manageInstance/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.detail) {
          } else {
            setinstancelist(data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [searchitems, setSearchItems] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cloumnName = "Lab_name";
  return (
    <>
      <Box sx={{ pt: 3 }}>
        <Card>
          <CardHeader
            action={<Payment />}
            subheader={
              <SearchField
                value={searchitems}
                placeholder="Search..."
                onChange={(event) => {
                  setSearchItems(event);
                }}
                classNames="test-class"
              />
            }
          />
          <Divider />
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1000 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width="10%">
                        Sandbox Name
                      </TableCell>
                      <TableCell align="center" width="10%">
                        Technology
                      </TableCell>
                      <TableCell align="center" width="10%">
                        No of Trainee
                      </TableCell>
                      <TableCell align="center" width="10%">
                        Sandbox Cost
                      </TableCell>
                      <TableCell align="center" width="10%">
                        Action
                      </TableCell>
                      <TableCell align="center" width="10%">
                        More Info
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {results ? (
                    <TableBody>
                      {results
                        .filter((val) => {
                          if (searchitems == "") {
                            return val;
                          } else if (
                            val[cloumnName]
                              .toLowerCase()
                              .includes(searchitems.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data) => (
                          <TableRow hover key={data.Lab_ID}>
                            <TableCell align="center" width="10%">
                              {data.Lab_name}
                            </TableCell>
                            <TableCell align="center" width="10%">
                              {data.Application}
                            </TableCell>
                            <TableCell align="center" width="10%">
                              {data.No_of_Trainee}
                            </TableCell>
                            <TableCell align="center" width="15%">
                              <TextField
                                error
                                id="outlined-error"
                                defaultValue={`₹ ${data.Price}`}
                                value={`₹ ${data.Price}`}
                              />
                            </TableCell>
                            
                            <TableCell align="center" width="10%">
                            <Clone InstanceID={data.Lab_ID}/>
                            </TableCell>

                            <TableCell align="center" width="10%">
                              <Moreinfo
                                name={data.Lab_name}
                                app={data.Application}
                                appPrice={data.App_price}
                                type={data.Instance_Type_Price}
                                storage={data.Instance_Storage_Price}
                                loadBalancer={data.Load_Balancer_price}
                                overallprice={data.Price}
                                runninghours={data.Running_hours}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  ) : (
                    <TableBody align="center">
                      <TableCell>No Data Available</TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {results ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={results.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                ""
              )}
            </Box>
            <Divider />
          </PerfectScrollbar>
        </Card>
      </Box>
    </>
  );
};

export default LabList;
