import H4 from '@material-tailwind/react/Heading4';
import LeadText from '@material-tailwind/react/LeadText';
import { ListItem } from '@material-ui/core';
import { SocialIcon } from 'react-social-icons';
export default function DefaultFooter() {
    return (
        <>
            <footer className="relative bg-gray-100 pt-8 pb-6">
                <div className="container max-w-7xl mx-auto px-4">
                    <div className="flex flex-wrap text-center lg:text-left pt-6">
                        <div className="w-full lg:w-6/12 px-4">
                            <H4 color="gray">ZippyOPS</H4>
                            <div className="-mt-4">
                                <LeadText color="blueGray">
                                Making Automation Work
                                </LeadText>
                            </div>
                            <div className="flex gap-2 mt-6 md:justify-start md:mb-0 mb-8 justify-center">
                            <SocialIcon target="blank" url="https://twitter.com/ZippyOps" network="twitter" fgColor="#fff" style={{ height: 35, width: 35 }} />
                            <SocialIcon target="blank" url="https://facebook.com/zippyops" network="facebook" fgColor="#fff" style={{ marginLeft: '1em', height: 35, width: 35 }} />
                            <SocialIcon target="blank" url="https://instagram.com/zippyops" network="instagram" fgColor="#fff" style={{ marginLeft: '1em', height: 35, width: 35 }} />
                            <SocialIcon target="blank" url="https://youtube.com/channel/UCZh2aijMM-h6ztRo36m3CiQ" network="youtube" fgColor="#fff" style={{ marginLeft: '1em', height: 35, width: 35 }} />
                            <SocialIcon target="blank" url="https://linkedin.com/in/zippyops" network="linkedin" fgColor="#fff" style={{ marginLeft: '1em', height: 35, width: 35 }} />
                            <SocialIcon target="blank" url="https://t.me/ZippyOPS" network="telegram" fgColor="#fff" style={{ marginLeft: '1em', height: 35, width: 35 }}/>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="flex flex-wrap items-top">
                                <div className="w-full lg:w-4/12 px-4 ml-auto md:mb-0 mb-8">
                                    <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                                      Menu
                                    </span>
                                    <ul className="list-unstyled">
                                        <ListItem>
                                            <a
                                                href="https://www.zippyops.com/blog"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                Blog
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <a
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                                target="_blank"
                                                href="https://www.zippyops.com/services"
                                            >
                                                Services
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <a
                                                href="https://www.zippyops.com/about-us"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                About us
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <a
                                                href="https://www.zippyops.com/products"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                Products
                                            </a>
                                        </ListItem>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                                        Blog
                                    </span>
                                    <ul className="list-unstyled">
                                        <ListItem>
                                            <a
                                                href="https://www.zippyops.com/blog/infrastructure"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                Infrastructure
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <a
                                                href="https://www.zippyops.com/blog/devsecops"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                DevSecOps
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <a
                                                href="https://www.zippyops.com/blog/devops"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                DevOps
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <a
                                                href="zippyops.com/blog/AutomatedOPS"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                                            >
                                                AutomatedOPS
                                            </a>
                                        </ListItem>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
