import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowTwoToneIcon from '@material-ui/icons/PlayArrowTwoTone';
import {
  Button  
} from "@material-ui/core";

export default function CustomizedDialogs() {

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const InstanceID = localStorage.getItem("InstanceID"); 
    fetch(`${process.env.REACT_APP_API_URL}/api/startlab/${InstanceID}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        }
    })
        .then((response) => response.json())
        .then((data) => {
            if (data) {
              // handleNext();
              // window.location.reload();
            } else {
              if (data.name) {
                setState({ errorMessage: data.name[0] });
              }
            }        
        })
        .catch((error) => {
            console.log(error)
            window.location.reload();
        });
    };

  return (
    <div>
      <Button sx={{ mx: 1 }}
          variant="contained"
          color="primary"
          startIcon={<PlayArrowTwoToneIcon />}
          onClick={handleSubmitClick}
      >Start</Button>
    </div>
  );
}