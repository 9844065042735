import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { Grid, Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import AcUnitIcon from "@material-ui/icons/AcUnit";

export default function CustomizedDialogs(props) {
  const { InstanceID } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function DateTime1(date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Date1 = new Date(date).toLocaleString("en-GB", {
      timeZone: `${timezone}`,
    });
    const Date2 = Date1.split(",");
    const Datef = Date2[0].replace("/", "-");
    const Date3 = Datef.replace("/", "-");
    return Date3;
  }

  function DateTime2(date, time) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Time1 = new Date(date + "T" + time + ":00.000Z").toLocaleString(
      "en-GB",
      { timeZone: `${timezone}` }
    );
    const Time2 = Time1.split(",");
    const Time3 = Time2[1].substring(0, 6);
    return Time3;
  }

  const [instancelog, setinstancelog] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/activelog/${InstanceID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setinstancelog(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [InstanceID]);

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        <IconButton aria-label="Filter" color="secondary">
          <FilterNoneIcon /> &nbsp;&nbsp;
          <span style={{ fontSize: "0.7em", color: "#172B4D" }}>Log</span>
        </IconButton>
      </MenuItem>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        scroll="paper"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Chip
            icon={<AcUnitIcon />}
            label="Active log"
            clickable
            color="primary"
          />
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="h8">
                    <strong>Status</strong>
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h8">
                    <strong>Date</strong>
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h8">
                    <strong>Time</strong>
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h8">
                    <strong>Executed by</strong>
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>

            {instancelog.map((actdata) => {
              return (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        {actdata.action === "created" ? (
                          <Typography variant="h8">Created</Typography>
                        ) : actdata.action === "started" ? (
                          <Typography variant="h8">Started</Typography>
                        ) : actdata.action === "stopped" ? (
                          <Typography variant="h8">Stopped</Typography>
                        ) : actdata.action === "scheduled" ? (
                          <Typography variant="h8">Scheduled</Typography>
                        ) : actdata.action === "terminated" ? (
                          <Typography variant="h8">Terminated</Typography>
                        ) : actdata.action === "uncordoned" ? (
                          <Typography variant="h8">UnCordoned</Typography>
                        ) : (
                          <Typography variant="h8"></Typography>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="h8">
                          {DateTime1(`${actdata.date}`)}
                        </Typography>
                      </Grid>
                      {actdata.time === "" ? (
                        <Grid item xs={3}>
                          <Typography variant="h8">NA</Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={3}>
                          <Typography variant="h8">
                            {DateTime2(`${actdata.date}`, `${actdata.time}`)}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={3}>
                        <Typography variant="h8">{actdata.email}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
