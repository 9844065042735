import React from "react";
import { Grid, Box, Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import SearchField from "react-search-field";

export default function Applications(props) {
  let { appsetFormFields, appfieldIndex, setDevopsport } = props;

  const [customtools, setCustomTools] = React.useState([[]]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/customtools/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setCustomTools(resp))
      .catch((error) => {
        console.error(error);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [selectedValue, setSelectedValue] = React.useState("");

  const handleSumbitClick = (e) => {
    if (selectedValue === "notools") {
      appsetFormFields((prevState) => ({
        ...prevState,
        [appfieldIndex]: false,
      }));
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handleOnChange = (e, port) => {
    e.preventDefault();
    const { id } = e.target;
    appsetFormFields((prevState) => ({
      ...prevState,
      [appfieldIndex]: e.target.value,
    }));
    setDevopsport((prevState) => ({
      ...prevState,
      [appfieldIndex]: port,
    }));
    setSelectedValue(e.target.value);
  };
  const [searchitems, setSearchItems] = React.useState(0);

  return (
    <>
      <div>
        <Fab variant="extended" size="small" color="primary" aria-label="add">
          <Chip
            icon={<AddIcon />}
            label="Tools"
            clickable
            color="primary"
            onClick={handleClickOpen}
          />
        </Fab>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Tools </DialogTitle>
        <DialogContent dividers>
          <SearchField
            value={searchitems}
            placeholder="Search..."
            onChange={(event) => {
              setSearchItems(event);
            }}
            classNames="test-class"
          />
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Box>
              {customtools ? (
                <React.Fragment>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={0} sx={{ mt: 3 }}>
                        <Grid item xs={6}>
                          <Typography variant="h8" gutterBottom>
                            Tools
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h8" gutterBottom>
                            Version
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />

                      {customtools
                        .filter((val) => {
                          if (searchitems == "") {
                            return val;
                          } else if (
                            val.name
                              .toLowerCase()
                              .includes(searchitems.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((applist) => {
                          const port = applist.port;
                          return (
                            <Grid container spacing={0} sx={{ mt: 3 }}>
                              <Grid item xs={6}>
                                <Radio
                                  checked={selectedValue === applist.name}
                                  onChange={(e) => handleOnChange(e, port)}
                                  value={applist.name}
                                  name="radio-button-demo"
                                  inputProps={{ "aria-label": applist.name }}
                                />
                                <Typography variant="h8">
                                  {applist.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="h8">
                                  {applist.version}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                ""
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSumbitClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
