import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Box,
  Container,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import InputLabel from "@material-ui/core/InputLabel";
import CustomApplications from "./CustomApplications";
import ClearIcon from "@material-ui/icons/Clear";
import PDF1 from "./pdf1";
import EstimationSchedule from "./EstimationSchedule";
import ScheduleError from "./ScheduleError";

const useStyles = makeStyles((theme) => ({
  btncenter: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 30px",
    paddingLeft: 10,
  },
}));

export default function LabEstimation() {
  const classes = useStyles();

  const [instancetype, setInstancetype] = React.useState([[]]);
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/instancetype/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setInstancetype(resp))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [state, setState] = React.useState({
    reportname: "",
    currency: "",
    labtype: "custom",
    nooftrainee: "1",
    actualhours: "",
    application: "",
    machinetype: "linux",
    instance_type: "",
    ostype: "linux",
    // linux_type: "centos7",
    instance_storage: "",
    customdata: "",
    start_date_time: "",
    end_date_time: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [checkstate, setCheckstate] = React.useState({
    selectedValue: "checkedA",
  });

  const [formFields, setFormFields] = React.useState([]);

  const [linuxsingleselectedapp, setLinuxsingleselectedapp] = React.useState(
    []
  );

  const [linuxmultiselectindex, setlinuxmultiselectindex] = React.useState([]);
  const [linuxselectedapps, setLinuxselectedapps] = React.useState([]);

  const [windowssingleselectedapp, setWindowssingleselectedapp] =
    React.useState([]);
  const [windowsmultiselectindex, setWindowsmultiselectindex] = React.useState(
    []
  );
  const [windowsselectedapps, setWindowsselectedapps] = React.useState([]);

  const [scheduleerroropen, setscheduleerroropen] = React.useState(false);

  const [programmingapps, setProgrammingapps] = React.useState([]);
  const [Systemtools, setSystemtools] = React.useState([]);
  const [normalapps, setNormalapps] = React.useState([]);
  const [devopsapps, setDevopsapps] = React.useState([]);

  const [devopsport, setDevopsport] = React.useState([]);
  const [normalport, setNormalport] = React.useState([]);

  const [estimationcount, setEstimationcount] = React.useState({
    application: "",
    no_of_trainee: "",
    actual_hours: "",
    overall_app_price: "",
    overall_type_price: "",
    overall_storage_price: "",
    total_price: "",
    details: [],
  });

  const handleSubmitClick = (e) => {
    e.preventDefault();
    let timecount = 0;

    if (checkstate.selectedValue === "checkedA") {
      state["depends_on"] = "always";
    } else if (checkstate.selectedValue === "checkedB") {
      state["depends_on"] = "during_training";
    } else {
      state["depends_on"] = "immediate";
    }

    const Lengthform = formFields.length;

    [...Array(Lengthform)].map((frm, i) => {
      if (normalapps[i]) {
        const listofnrmlapps = normalapps[i].map((b) => {
          return `${b}`;
        });
        formFields[i]["apps"] = listofnrmlapps;
      } else {
        formFields[i]["apps"] = "notapply";
      }

      if (programmingapps[i]) {
        const listofprogrammingapps = programmingapps[i].map((b) => {
          return `${b}`;
        });
        formFields[i]["programming"] = listofprogrammingapps;
      } else {
        formFields[i]["programming"] = "notapply";
      }

      if (devopsapps[i]) {
        formFields[i]["tools"] = devopsapps[i];
      } else {
        formFields[i]["tools"] = "notapply";
      }
      if (Systemtools[i]) {
        const listofsystemtools = Systemtools[i].map((b) => {
          return `${b}`;
        });
        formFields[i]["systemtools"] = listofsystemtools;
      } else {
        formFields[i]["systemtools"] = "notapply";
      }
    });
    const CountstartTime = state.start_date_time.split("T")[1];
    const CountstopTime = state.end_date_time.split("T")[1];
    if (CountstopTime <= CountstartTime) {
      timecount = timecount + 1;
    }

    if (timecount > 0) {
      setscheduleerroropen(true);
    } else {
      state["customdata"] = formFields;
      state["start_date"] = state.start_date_time.split("T")[0];
      state["end_date"] = state.end_date_time.split("T")[0];
      state["start_time"] = state.start_date_time.split("T")[1];
      state["stop_time"] = state.end_date_time.split("T")[1];
      var value = JSON.stringify(state);
      fetch(`${process.env.REACT_APP_API_URL}/api/labestimation/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: value,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setEstimationcount(data);
          } else {
            setState({ errorMessage: data });
          }
        })
        .catch((error) => {
          window.location.reload();
        });
    }
  };

  const handleCacheClear = (e) => {
    e.preventDefault();
    setEstimationcount({
      application: "",
      no_of_trainee: "",
      actual_hours: "",
      overall_app_price: "",
      overall_type_price: "",
      overall_storage_price: "",
      total_price: 0,
      details: [],
    });
    setState({
      reportname: "",
      labtype: "custom",
      nooftrainee: "1",
      actualhours: "",
      application: "",
      machinetype: "linux",
      ostype: "linux",
      // linux_type: "centos7",
      instance_type: "",
      instance_storage: "",
      customdata: "",
      start_date_time: "",
      end_date_time: "",
    });
  };

  function refreshPage() {
    window.location.reload();
  }

  return (
    <div>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ScheduleError
            validerroropen={scheduleerroropen}
            setvaliderroropen={setscheduleerroropen}
          />
          <Card>
            <CardHeader
              title="Sandbox Estimation"
              action={
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Card
                      sx={{ height: "100%", width: "100%", padding: "4px" }}
                      align="center"
                    >
                      <Typography variant="contained" color="Primary">
                        {`Credits: ${estimationcount.total_price}`}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Button
                      startIcon={<ClearIcon />}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleCacheClear}
                      disabled={estimationcount.total_price > 0 ? false : true}
                    >
                      Clear
                    </Button>
                  </Grid>

                  {state.labtype === "custom" ? (
                    <Grid item xs={3.5}>
                      <PDF1
                        estimationcount={estimationcount}
                        state={state}
                        details={estimationcount.details}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              }
            />
            <Divider />
            <CardContent>
              <Grid container justify="center" spacing={3}>
                <Grid container spacing={3} item justify="flex-end" xs={12}>
                  <Grid item xs={3}>
                    <InputLabel>
                      Sandbox Name
                      <small style={{ paddingLeft: "10px" }}>required</small>
                      <small style={{ color: "red" }}>*</small>
                    </InputLabel>
                    <TextField
                      fullWidth
                      name="reportname"
                      id="reportname"
                      onChange={handleChange}
                      value={state.reportname}
                      required
                      size="small"
                      autoComplete="shipping address-line1"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <EstimationSchedule
                      state={state}
                      setState={setState}
                      checkstate={checkstate}
                      setCheckstate={setCheckstate}
                    />
                  </Grid>
                  <Divider />
                  {state.labtype === "custom" ? (
                    <Grid item xs={12}>
                      <CustomApplications
                        mainstate={state}
                        mainsetState={setState}
                        instancetype={instancetype}
                        setInstancetype={setInstancetype}
                        formFields={formFields}
                        setFormFields={setFormFields}
                        linuxsingleselectedapp={linuxsingleselectedapp}
                        setLinuxsingleselectedapp={setLinuxsingleselectedapp}
                        linuxmultiselectindex={linuxmultiselectindex}
                        setlinuxmultiselectindex={setlinuxmultiselectindex}
                        linuxselectedapps={linuxselectedapps}
                        setLinuxselectedapps={setLinuxselectedapps}
                        windowssingleselectedapp={windowssingleselectedapp}
                        setWindowssingleselectedapp={
                          setWindowssingleselectedapp
                        }
                        windowsmultiselectindex={windowsmultiselectindex}
                        setWindowsmultiselectindex={setWindowsmultiselectindex}
                        windowsselectedapps={windowsselectedapps}
                        setWindowsselectedapps={setWindowsselectedapps}
                        setProgrammingapps={setProgrammingapps}
                        setSystemtools={setSystemtools}
                        setNormalapps={setNormalapps}
                        setDevopsapps={setDevopsapps}
                        setDevopsport={setDevopsport}
                        setNormalport={setNormalport}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Grid item xs={12}>
              <br />

              <Divider />
              <br />
              <br />
            </Grid>
            <CardContent>
              <div className={classes.btncenter}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitClick}
                >
                  Estimate
                </Button>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
}
