import SevenZip from 'src/assets/img/tools/7zip.png';
import AngularJS from 'src/assets/img/tools/angularjs.png';
import Ansible from 'src/assets/img/tools/ansible.png';
import ApacheAirflow from 'src/assets/img/tools/apache-airflow.png';
import ApacheAnt from 'src/assets/img/tools/apache-ant.png';
import ApacheJmeter from 'src/assets/img/tools/apache-Jmeter.png';
import ApacheKafka from 'src/assets/img/tools/apache-kafka.png';
import ApacheSolr from 'src/assets/img/tools/apache-solr.png';
import ApacheTomcat from 'src/assets/img/tools/apache-tomcat.png';
import Apache from 'src/assets/img/tools/apache.png';
import ApacheOpenOffice from 'src/assets/img/tools/apache_open_fffice.png';
import AWS from 'src/assets/img/tools/aws.png';
import Azure from 'src/assets/img/tools/azure.png';
import Chef from 'src/assets/img/tools/chef.png';
import Chrome from 'src/assets/img/tools/chrome.png';
import Curl from 'src/assets/img/tools/curl.png';
import Django from 'src/assets/img/tools/django.png';
import Docker from 'src/assets/img/tools/docker.png';
import DotNetCore from 'src/assets/img/tools/dot-net-core.png';
import Drupal from 'src/assets/img/tools/drupal.png';
import ELK from 'src/assets/img/tools/elk.png';
import Firefox from 'src/assets/img/tools/firefox.png';
import GCP from 'src/assets/img/tools/gcp.png';
import Git from 'src/assets/img/tools/git.png';
import Gitlab from 'src/assets/img/tools/gitlab.png';
import Go from 'src/assets/img/tools/go.png';
import Gradle from 'src/assets/img/tools/gradle.png';
import Hadoop from 'src/assets/img/tools/hadoop.png';
import IntelliJIdea from 'src/assets/img/tools/intelliJ-Idea.png';
import Java from 'src/assets/img/tools/java.png';
import JavaEclipse from 'src/assets/img/tools/java_eclipse.png';
import Jenkins from 'src/assets/img/tools/jenkins.png';
import Jfrog from 'src/assets/img/tools/jfrog.png';
import Junit from 'src/assets/img/tools/junit.png';
import Kibana from 'src/assets/img/tools/kibana.png';
import MariaDB from 'src/assets/img/tools/mariadb.png';
import MongoDB from 'src/assets/img/tools/mongodb.png';
import MSIIS from 'src/assets/img/tools/ms-iis.png';
import MySql from 'src/assets/img/tools/mysql.png';
import MySqlServerExpress from 'src/assets/img/tools/mysqlserverexpress.png';
import Maven from 'src/assets/img/tools/maven.png';
import MSOffice365ProPlus from 'src/assets/img/tools/msoffice365proplus.png';
import MSOffice2019ProPlus from 'src/assets/img/tools/msoffice2019proplus.png';
import Nagios from 'src/assets/img/tools/nagios.png';
import NanoEditor from 'src/assets/img/tools/nano_editor.png';
import Nginx from 'src/assets/img/tools/nginx.png';
import NodeJS from 'src/assets/img/tools/node.png';
import NotePad from 'src/assets/img/tools/notepad.png';
import Nunit from 'src/assets/img/tools/nunit.png';
import OpenVas from 'src/assets/img/tools/openvas.png';
import PhP from 'src/assets/img/tools/php.png';
import Powershell from 'src/assets/img/tools/powershell.png';
import Postman from 'src/assets/img/tools/postman.png';
import Puppet from 'src/assets/img/tools/puppet.png';
import Python from 'src/assets/img/tools/python.png';
import R from 'src/assets/img/tools/r.png';
import ReactJS from 'src/assets/img/tools/react.png';
import Redis from 'src/assets/img/tools/redis.png';
import Ruby from 'src/assets/img/tools/ruby.png';
import Rundeck from 'src/assets/img/tools/rundeck.png';
import Saltstack from 'src/assets/img/tools/saltstack.png';
import Selenium from 'src/assets/img/tools/selenium.png';
import Slack from 'src/assets/img/tools/slack.png';
import Sonarqube from 'src/assets/img/tools/sonarqube.png';
import Tensorflow from 'src/assets/img/tools/tensorflow.png';
import Terraform from 'src/assets/img/tools/terraform.png';
import Vim from 'src/assets/img/tools/vim.png';
import VisualStudio from 'src/assets/img/tools/visualstudio.png';
import VisualStudioCode from 'src/assets/img/tools/visualstudiocode.png';
import WebLogic from 'src/assets/img/tools/weblogic.png';
import WebSphere from 'src/assets/img/tools/websphere-application.png';
import Wget from 'src/assets/img/tools/wget.png';
import Zabbix from 'src/assets/img/tools/zabbix.png';
import Zip from 'src/assets/img/tools/zip.png';

const products = [
    {
        name : '7Zip',
        image : SevenZip 
    },
    {
        name : 'AngularJS',
        image : AngularJS 
    },
    {
        name : 'Ansible',
        image : Ansible 
    },    
    {
        name : 'Apache Airflow',
        image : ApacheAirflow 
    },
    {
        name : 'Apache Kafka',
        image : ApacheKafka 
    },
    {
        name : 'Apache Solr',
        image : ApacheSolr 
    },
    {
        name : 'Apache Tomcat',
        image : ApacheTomcat 
    },
    {
        name : 'Apache Ant',
        image : ApacheAnt 
    },
    {
        name : 'Apache Jmeter',
        image : ApacheJmeter 
    },
    {
        name : 'Apache',
        image : Apache 
    },
    {
        name : 'Apache Open Office',
        image : ApacheOpenOffice 
    },
    {
        name : 'AWS',
        image : AWS 
    },
    {
        name : 'Azure',
        image : Azure 
    },
    {
        name : 'Chef',
        image : Chef 
    },
    {
        name : 'Chrome',
        image : Chrome 
    },
    {
        name : 'Curl',
        image : Curl 
    },
    {
        name : 'Django',
        image : Django 
    },
    {
        name : 'Docker',
        image : Docker 
    },
    {
        name : 'Dot Net Core',
        image : DotNetCore 
    },
    {
        name : 'Drupal',
        image : Drupal 
    },
    {
        name : 'ELK',
        image : ELK 
    },
    {
        name : 'Firefox',
        image : Firefox 
    },
    {
        name : 'GCP',
        image : GCP 
    },
    {
        name : 'Git',
        image : Git 
    },
    {
        name : 'Gitlab',
        image : Gitlab 
    },
    {
        name : 'Go',
        image : Go 
    },
    {
        name : 'Gradle',
        image : Gradle 
    },
    {
        name : 'Hadoop',
        image : Hadoop 
    },
    {
        name : 'IntelliJ Idea',
        image : IntelliJIdea 
    },
    {
        name : 'Java',
        image : Java 
    },
    {
        name : 'Java Eclipse',
        image : JavaEclipse 
    },
    {
        name : 'Jenkins',
        image : Jenkins 
    },
    {
        name : 'Jfrog',
        image : Jfrog 
    },
    {
        name : 'Junit',
        image : Junit 
    },
    {
        name : 'Kibana',
        image : Kibana 
    },
    {
        name : 'MariaDB',
        image : MariaDB 
    },
    {
        name : 'MongoDB',
        image : MongoDB 
    },
    {
        name : 'MS IIS',
        image : MSIIS 
    },
    {
        name : 'MS Office 365 ProPlus',
        image : MSOffice365ProPlus 
    },
    {
        name : 'MS Office 2019 ProPlus',
        image : MSOffice2019ProPlus 
    },    
    {
        name : 'MySql Server Express',
        image : MySqlServerExpress 
    },
    {
        name : 'MySql',
        image : MySql 
    },
    {
        name : 'Maven',
        image : Maven 
    },
    {
        name : 'Nagios',
        image : Nagios 
    },
    {
        name : 'Nano Editor',
        image : NanoEditor 
    },
    {
        name : 'Nginx',
        image : Nginx 
    },
    {
        name : 'NodeJS',
        image : NodeJS 
    },
    {
        name : 'NotePad',
        image : NotePad 
    },
    {
        name : 'Nunit',
        image : Nunit 
    },
    {
        name : 'OpenVas',
        image : OpenVas 
    },
    {
        name : 'PhP',
        image : PhP 
    },
    {
        name : 'Postman',
        image : Postman
    },
    {
        name : 'Powershell',
        image : Powershell 
    },
    {
        name : 'Puppet',
        image : Puppet 
    },
    {
        name : 'Python',
        image : Python 
    },
    {
        name : 'R',
        image : R 
    },
    {
        name : 'ReactJS',
        image : ReactJS 
    },
    {
        name : 'Redis',
        image : Redis 
    },
    {
        name : 'Ruby',
        image : Ruby 
    },
    {
        name : 'Rundeck',
        image : Rundeck 
    },
    {
        name : 'Saltstack',
        image : Saltstack 
    },
    {
        name : 'Selenium',
        image : Selenium 
    },
    {
        name : 'Slack',
        image : Slack 
    },
    {
        name : 'Sonarqube',
        image : Sonarqube 
    },
    {
        name : 'Tensorflow',
        image : Tensorflow 
    },
    {
        name : 'Terraform',
        image : Terraform 
    },
    {
        name : 'Vim',
        image : Vim 
    },
    {
        name : 'Visual Studio',
        image : VisualStudio 
    },
    {
        name : 'Visual Studio Code',
        image : VisualStudioCode 
    },
    {
        name : 'Web Logic',
        image : WebLogic 
    },
    {
        name : 'Web Sphere',
        image : WebSphere 
    },
    {
        name : 'Wget',
        image : Wget 
    },
    {
        name : 'Zabbix',
        image : Zabbix 
    },
    {
        name : 'Zip',
        image : Zip 
    },          
]    

export default products;