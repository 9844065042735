import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import StopTwoToneIcon from "@material-ui/icons/StopTwoTone";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";

function Progress(props) {
  const { open, handleClose } = props;

  return (
    <React.Fragment>
      <style>
        {`
        .loader {
          background: #ffffff;
          bottom: 0;
          left: 0;
          opacity: 0.8;
          right: 0;
          top: 0;
      }
      
      .loader,
      .loader-content {
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .loader-message {
          font-size: 2.5em;
          margin-top: 1em;
      }
      `}
      </style>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogContent
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <DialogContentText id="alert-dialog-description">
            <div className="loader">
              <CircularProgress />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default function CustomizedDialogs(props) {
  const { InstanceID, status } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    setOpen(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/stoplab/${InstanceID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setOpen(false);
          window.location.reload();
        } else {
          if (data.name) {
            setState({ errorMessage: data.name[0] });
          }
        }
      })
      .catch((error) => {
        window.location.reload();
      });
  };

  return (
    <div>
      <Progress open={open} handleClose={handleClose} />
      {status === "Running" ? (
        <React.Fragment>
          <MenuItem onClick={handleSubmitClick}>
            <IconButton aria-label="Filter" style={{ color: "red" }}>
              <StopTwoToneIcon /> &nbsp;&nbsp;
              <span style={{ fontSize: "0.7em", color: "#172B4D" }}>Stop</span>
            </IconButton>
          </MenuItem>
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
}
