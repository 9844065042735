import React from 'react';
import { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  TextField
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import SearchField from "react-search-field";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Create from '../../components/labstructure/Create';
import Log from './Log';
import Delete from './Delete';
import Option from './Option';
import Intialize from './Initailize';
import Scheduleclock from './scheduleclock';
import Terminated from './Terminated';

const delay = 5;

const options = [
  { 'id': 1,
    'name': 'Sandbox Name',
    'value': 'name'
  },
  { 'id': 2,
    'name': 'Technology',
    'value': 'application'
  },  
  { 'id': 3,
    'name': 'Status',
    'value': 'status'
  }     
];

const LabList = ({ ...rest }) => {

  //  Instance List //
  
  function DateTime1(startDate, startTime) {    
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDate1 = new Date(startDate).toLocaleString('en-GB', { timeZone: `${timezone}` });      
    const startDate2 = startDate1.split(",");    
    const startDatef = startDate2[0].replace('/' , '-');
    const startDate3 = startDatef.replace('/' , '-');
    
    const startTime1 = new Date(startDate + 'T' + startTime + ':00.000Z').toLocaleString('en-GB', { timeZone: `${timezone}` });    
    const startTime2 = startTime1.split(",");    
    const startTime3 = startTime2[1].substring(0, 6);    

    const startDateTime = startDate3 + '' + startTime3;

    return startDateTime;
  }
  
  function DateTime2(endDate, endTime) {    
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endDate1 = new Date(endDate).toLocaleString('en-GB', { timeZone: `${timezone}` });  
    const endDate2 = endDate1.split(",");
    const endDatef = endDate2[0].replace('/' , '-');
    const endDate3 = endDatef.replace('/' , '-');
    const endTime1 = new Date(endDate + 'T' + endTime + ':00.000Z').toLocaleString('en-GB', { timeZone: `${timezone}` });
    const endTime2 = endTime1.split(",");
    const endTime3 = endTime2[1].substring(0, 6);
             
    const endDateTime = endDate3 + '' + endTime3;

    return endDateTime;
  }

  const [instancelist, setinstancelist] = useState([]); 

  useEffect(() => {    
    const intervalId = setInterval(() => { 
    fetch(`${process.env.REACT_APP_API_URL}/api/manageInstance/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          //localStorage.setItem("user_access", data.detail);
        } else {
          setinstancelist(data)
        }
      })
      .catch((error) => {
        console.error(error);
        // window.location.href = "/something_went_wrong";
      });    
    }, 1000)
    return () => {
      clearInterval(intervalId);
  }          
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, instancelist.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;  

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };  

  const [searchitems, setSearchItems] = React.useState(0);

  return (
    <>
    <ButtonGroup sx={{ mr: 2 }} variant="outlined" style={{ backgroundColor: "#ffff", color: "rgb(90, 90, 90)", border: '1px solid rgb(221, 221, 221)'}} aria-label="split button">
          <Button style={{ width: "250px", backgroundColor: "#ffff", color: "rgb(90, 90, 90)", border: '1px solid rgb(221, 221, 221)'}}>{options[selectedIndex].name} {" = "}</Button>
          <Button
            // color="primary"
            style={{ backgroundColor: "#ffff", color: "rgb(90, 90, 90)", border: '1px solid rgb(221, 221, 221)'}}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleClick}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>   
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
           <Paper>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
              </Paper>
      </Popover>        
    <SearchField
    value={searchitems}
  placeholder="Search..."
  onChange={((event) => {
    setSearchItems(event);
  })}
  // searchText="This is initial search text"
  classNames="test-class"
/>
    <Box sx={{ pt: 3 }}>
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1000 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Sandbox Name
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>               
                <TableCell align="center">
                  Technology
                </TableCell>
                <TableCell align="center">
                Unique ID
                </TableCell>                
                <TableCell align="center">
                  Start Duration
                </TableCell>
                <TableCell align="center">
                  End Duration
                </TableCell>   
                <TableCell align="center" width="20%">
                  Budget Credits
                </TableCell>                      
                <TableCell align="center" width="10%">
                  Spent
                </TableCell>                 
                <TableCell align="center">
                  Actions
                </TableCell>                            
                <TableCell align="center">
                  Details 
                </TableCell>                                
              </TableRow>
            </TableHead>  
            { instancelist.length > 0 ?         
            <TableBody >
              {instancelist
              .filter((val) => {
                if (searchitems == "") {
                  return val;
                } else if ( (val[options[selectedIndex].value]).toLowerCase().includes(searchitems.toLowerCase())){
                  return val;
                }
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((instance) => 
              
              instance.application === "custom" ?

              (
                <TableRow
                  hover
                  key={instance.id}
                >
                  <TableCell align="center">
                    {instance.name}
                  </TableCell>
                  <TableCell align="center">
                  {instance.status === 'Creating' ?
                    <Chip color="primary" label={instance.status} size="small" />
                  : instance.status === 'Scheduled' ?
                  <Chip color="primary" style={{backgroundColor: 'rgb(44 189 186)'}} label={instance.status} size="small" />
                  : instance.status === 'Running' ?
                    <Chip color="primary" style={{backgroundColor: '#00a200'}} label={instance.status} size="small" />
                  : instance.status === 'Stopped' ? 
                    <Chip color="primary" style={{backgroundColor: 'rgb(228 193 3 / 94%)'}} label={instance.status} size="small" />
                  : instance.status === 'Cordoned' ? 
                    <Chip color="primary" style={{backgroundColor: 'rgb(98 95 80 / 94%)'}} label={instance.status} size="small" />                    
                  : <Chip color="primary" style={{backgroundColor: 'rgb(228 3 3 / 94%)'}} label={instance.status} size="small" /> }
                  </TableCell>                                  
                  <TableCell align="center">
                    {instance.application}
                  </TableCell>
                  <TableCell align="center">
                    {instance.id}
                  </TableCell>              
                  {instancelist.start_time === "" || instancelist.start_time === null ?  
                  <TableCell align="center">NA</TableCell>
                  :  
                  <TableCell align="center">
                  {DateTime1(`${instance.start_date}`, `${instance.start_time}`)}                 
                  </TableCell>
                  }
                  {instancelist.stop_time === "" || instancelist.stop_time === null ?  
                  <TableCell align="center">NA</TableCell>
                  :
                  <TableCell align="center">
                  {DateTime2(`${instance.end_date}`, `${instance.stop_time}`)}
                  </TableCell>
                   }
                  <TableCell align="center">
                  <Typography            
               variant="contained"
               color="Primary"
               align="center"
              >
            {`${instance.budget}`}
            </Typography>                  
                  {/* <TextField
                  error
                  id="outlined-error"
                  defaultValue={`₹ ${instance.budget}`}
                  value={`₹ ${instance.budget}`}
                /> */}
                  </TableCell>                  
                  <TableCell align="center">
                  <Typography            
               variant="contained"
               color="Primary"
               align="center"
              >
            {`${instance.spent}`}
            </Typography>                  
                  {/* <TextField
                  error
                  id="outlined-error"
                  defaultValue={`₹ ${instance.spent}`}
                  value={`₹ ${instance.spent}`}
                /> */}
                  </TableCell>
                  <TableCell align="center">
                    <Option InstanceID={instance.id} name={instance.name} status={instance.status} spent={instance.spent} budget={instance.budget}/>
                  </TableCell>                  
                  {instance.application === 'custom' ?
                  <TableCell align="center">
                     { instance.status === 'Running' || instance.status === 'Stopped' ?
                     <Button size="sm">
                       <Link
                         to={{
                           pathname: "/app/custom/instanceedit",
                           }}
                         onClick={() => {
                           localStorage.setItem(
                             "InstanceID", 
                             instance.id
                             );                           
                         }}
                         style={{ color: "#FFF" }}
                       >
                   <ArrowRightAltIcon 
                     color="primary"                
                   />
                       </Link>                  
                   </Button> 
                   : instance.status === 'Scheduled' ?
                   <Scheduleclock />
                  : instance.status === 'Creating' ?
                  <Intialize />
                  : 
                  <Terminated/>
                       }
                   
                 </TableCell>
                 :                  
                  <TableCell align="center">
                    { instance.status === 'Running' || instance.status === 'Stopped' ?
                    <Button size="sm">
                      <Link
                        to={{
                          pathname: "/app/custom/instanceedit",
                          }}
                        onClick={() => {
                          localStorage.setItem(
                            "InstanceID", 
                            instance.id
                            );                           
                        }}
                        style={{ color: "#FFF" }}
                      >
                  <ArrowRightAltIcon 
                    color="primary"                
                  />
                      </Link>                  
                  </Button> 
                   : instance.status === 'Scheduled' ?
                   <Scheduleclock />
                  : instance.status === 'Creating' ?
                  <Intialize />
                  : 
                  <Terminated/>
                       }
                  </TableCell> }                 
                </TableRow>              
              )
              :
              ""
              )
            }
            </TableBody> 
            : 
            <TableBody align='center'>
              <TableCell > 
                No Data Available
              </TableCell>  
            </TableBody>}
          </Table>
          </TableContainer>
          { instancelist.length > 0 ?  
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={instancelist.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        : "" }
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Card>
  </Box>
  </>
  ) 
};

export default LabList;
