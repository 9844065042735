import React, {useState, useEffect} from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import AllOutIcon from '@material-ui/icons/AllOut';

const Budget = (props) => {

  const [labscard, setLabscard] = useState([]); 

  useEffect(() => {    
    fetch(`${process.env.REACT_APP_API_URL}/api/labscard/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setLabscard(data)
      })
      .catch((error) => {
        console.error(error);
      });              
  }, []); 


return (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
           TOTAL SANDBOX
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          > 
            {labscard.customtotal}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: orange[600],
              height: 35,
              width: 35
            }}
          >
            <AllOutIcon />
          </Avatar>
        </Grid>
      </Grid>
      
    </CardContent>
  </Card>
);
    }

export default Budget;
