import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden, 
  List,
  Typography
} from '@material-ui/core';
import {  
  BarChart as BarChartIcon,  
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';
import NavItemdropdown from './NavItemdropdown';
import DashboardNavbar from './DashboardNavbar';
import CloudIcon from '@material-ui/icons/Cloud';
import LaunchIcon from '@material-ui/icons/Launch';
import UpdateSharpIcon from '@material-ui/icons/UpdateSharp';
import SubjectIcon from '@material-ui/icons/Subject';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import HelpIcon from '@material-ui/icons/Help';
import WelcomeUser from '../pages/WelcomeUser/Welcomeuser';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import InfoIcon from '@material-ui/icons/Info';
import AppsIcon from '@material-ui/icons/Apps';
import ImageIcon from '@material-ui/icons/Image';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const user = {
  avatar: '/static/images/avatars/zippy.png',
  email: localStorage.getItem("email"),
  name: localStorage.getItem("user")
};


if (localStorage.getItem("group") === "admin"){ 
var items = [
      
    {
      id: 'Sandbox',
      title: 'Sandbox',
      type: 'group',
      children: [
        {     
          icon: ImageIcon,
          title: 'LabList',
        }  
      ]
    },  
                 
    {
      id: 'Management',
      title: 'Management',
      type: 'group',
      children: [
        {
          href: '/app/management',
          icon: UsersIcon,
          title: 'UserManagement'
        },
        {
          href: '/app/estimation',
          icon: AppsIcon,
          title: 'Sandbox Estimation'
        }, 
        {
          href: '/app/custom/billinginfo',
          title: 'Billing Information',
        }, 

      ]
    },   
    {
      id: 'Settings',
      title: 'Settings',
      type: 'group',
      children: [
        {
          href: '/app/profile',
          icon: PersonPinIcon,
          title: 'Profile'
        },
        {
          href: '/app/about',  
          title: 'About'
        },
        {
          href: 'https://support.zippyops.com',
          icon: HeadsetMicIcon,
          title: 'Support'
        },
        {
          href: 'https://docs.labasservice.com',
          title: 'Docs'
        }    
      ]
    }      
  ];
} else if (localStorage.getItem("group") === "ops_users"){

  var items = [       
    {
      id: 'Sandbox',
      title: 'Sandbox',
      type: 'group',
      children: [
        {     
          icon: ImageIcon,
          title: 'LabList',
        }  
      ]
    },
    
    {
      id: 'Management',
      title: 'Management',
      type: 'group',
      children: [        
        {
          href: '/app/estimation',
          icon: AppsIcon,
          title: 'Sandbox Estimation'
        }, 
        {
          title: 'Billing Information'
        }, 

      ]
    },
    {
      id: 'Settings',
      title: 'Settings',
      type: 'group',
      children: [
        {
          href: '/app/profile',
          icon: PersonPinIcon,
          title: 'Profile'
        },
        {
          href: '/app/about',          
          title: 'About'
        },
        {
          href: 'https://support.zippyops.com',
          icon: HeadsetMicIcon,
          title: 'Support'
        },
        {
          href: 'https://docs.labasservice.com',
          title: 'Docs'
        }    
      ]
    }        
  ];
  
} else {
  
  var items = [       
    {
      id: 'Labs',
      title: 'Labs',
      type: 'group',
      children: [ 
        {          
          title: 'LabList'
        }
      ]
    },
    {
      id: 'Management',
      title: 'Management',
      type: 'group',
      children: [
        {
          href: '/app/estimation',
          icon: AppsIcon,
          title: 'Lab Estimation'
        }
      ]
    },    
    {
      id: 'Settings',
      title: 'Settings',
      type: 'group',
      children: [
        {
          href: '/app/profile',
          icon: PersonPinIcon,
          title: 'Profile'
        },
        {
          href: '/app/about',
          title: 'About'
        },
        {
          href: 'https://support.zippyops.com',
          icon: HeadsetMicIcon,
          title: 'Support'
        },
        {
          href: 'https://docs.labasservice.com',
          title: 'Docs'
        }
            
      ]
    }     
  ];
}


const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();  

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      
      <Box>
        <List>
        <div>
        <ListItem className="px-3 rounded-lg mb-1 text-gray-700">
        <NavItem 
                   href="/app/dashboard"
                   key="Dashboard"
                   title="Dashboard"
                   icon={BarChartIcon}
                  />
         </ListItem> 
         </div>        
        {items.map((item) => (
            <div key={item}>
             <Typography
              color="white"
              variant="caption" display="block"
             >  
              {item.title}
             </Typography>
            <Divider style={{backgroundColor: "rgb(6 57 108)"}}/>  
            {item.children.map((child) => {
              return (                   
               <ListItem key={item}              
               >
                 {child.title === "LabList" ?         
                  <NavItemdropdown                                   
                   style={{ paddingTop: "1px" }}
                  />                                                                              
                  :
                  <NavItem 
                   href={child.href}
                   key={child.title}
                   title={child.title}
                   icon={child.icon}
                   title1={item.title}
                  />
                   } 
               </ListItem>
              )})}
            </div>
          ))}
        </List>
      </Box>
      {/* <Box sx={{ flexGrow: 1 }} /> */}

    </Box>
  );


  const [userlogin, setUserlogin] = React.useState([]);
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/userstatuscheck/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setUserlogin(resp))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const content2 = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/profile"
        />
        <Typography
          color="#ffff"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="#ffff"
          variant="body2"
        >
          {user.email}
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <List>
        {items.map((item) => (
            <div key={item}>
             <Typography
              color="white"
              variant="h6"
              style={{ paddingTop: "7px" }}
             >  
              {item.title}
             </Typography>
            <Divider style={{color: "white"}}/>  
            {item.children.map((child) => {
              return (                   
                <ListItem key={item} className="px-3 rounded-lg mb-1 text-gray-700"              
                >
                  {child.title === "LabList"  ?         
                   <NavItemdropdown                                   
                    style={{ paddingTop: "1px" }}
                   />                                                                  
                   : 
                   <NavItem 
                    href={child.href}
                    key={child.title}
                    title={child.title}
                    icon={child.icon}
                    style={{ paddingTop: "1px" }}
                   />
                    } 
                </ListItem>            
            )})}
            </div>
          ))}
          
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

    </Box>
  );

  return (
    <React.Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
              top: 60,
              backgroundImage: 'linear-gradient(145deg, #292E49 7%, rgb(69 183 217) 90%)'
            }
          }}
        >
          {content2}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 60,
              height: 'calc(100% - 64px)',
              backgroundImage: 'linear-gradient(145deg, rgb(69 183 217) 7%, rgb(41, 46, 73) 60%)'
            }
          }}
        >
          {content}
          
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default DashboardSidebar;
