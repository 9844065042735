import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import LabList from '../../components/labstructure/LabListCustom';

const Labs1 = () => {

  return (
  <React.Fragment>
    <Helmet>
      <title>SandboxAsService</title>
    </Helmet>
    
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        
        <LabList />
      </Container>
    </Box>
  </React.Fragment>
 );
}

export default Labs1;