import React from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';  
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const Detailsmodify = (props) => {
  const { topicname } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };  
 
  const [state, setState] = React.useState({
    address: "",
    country: "",
    errorMessage: "",
  });

  const handleSubmitClick = (e) => {
    e.preventDefault(); 
      const data = {
        address: state.address,
        country: state.country
      };
      fetch(`${process.env.REACT_APP_API_URL}/api/user_management/orgdetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
            window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });  
  };  
 
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
  <>        

        <IconButton onClick={handleClickOpen} color="primary" aria-label="add to shopping cart">
        <EditIcon style={{fontSize: '14px'}} fontSize="small" />
        </IconButton>
        <div style={{ alignItems: 'center', display: 'flex'}}>  
       
            <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Update {topicname}</DialogTitle>
        <DialogContent> 
            <Grid
              item
              md={20}
              xs={12}
            >
             {topicname === "Country" ?   
              <TextField
                fullWidth    
                name="country"
                id="country"
                onChange={handleChange}
                required
                type="text"          
                value={state.country}
                variant="outlined"
                />
              : 
              <TextField
                fullWidth                
                name="address"
                id="address"
                onChange={handleChange}
                required
                type="text"          
                value={state.address}
                variant="outlined"
                />              
            }
            </Grid>           
        </DialogContent> 
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmitClick} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>          
      </div>  
  </>
  );
}

export default Detailsmodify;