import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CurrentSchedule from "./CurrentSchedule.js";
import UpcomingSchedule from "./UpcomingSchedule.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function Schedule() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>

      <div
        className={classes.root}
        style={{ paddingRight: "30px", paddingLeft: "30px" }}
      >
        <AppBar position="static" style={{ backgroundColor: "#ffff" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Current Shedule" {...a11yProps(0)} />
            <Tab label="Up Coming Shedule" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "100%",
              py: 2,
            }}
          >
            <CurrentSchedule />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "100%",
              py: 2,
            }}
          >
            <UpcomingSchedule />
          </Box>
        </TabPanel>
      </div>
    </React.Fragment>
  );
}