import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import EmailIcon from '@material-ui/icons/Email';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import GridItem from "../../../components/authentication/components/Grid/GridItem.js";
import Button from "../../../components/authentication/components/CustomButtons/Button.js";
import Card from "../../../components/authentication/components/Card/Card.js";
import CardBody from "../../../components/authentication/components/Card/CardBody.js";
import CardHeader from "../../../components/authentication/components/Card/CardHeader.js";
import CardFooter from "../../../components/authentication/components/Card/CardFooter.js";

import styles from "../../../components/authentication/assets/jss/labs/views/loginPage.js";

import image from "../../../components/authentication/assets/img/bg.jpg";
import Link from "@material-ui/core/Link";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import ReactLoading from "react-loading";
const useStyles = makeStyles(styles);

function Progress(props) {
  const { submitted, refreshPage } = props;

  const delay = 3;

  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return show ? (
    refreshPage()
  ) : (
    <React.Fragment>
      <style>
        {`
        .loader {
          background: #ffffff;
          bottom: 0;
          left: 0;
          opacity: 0.8;
          position: absolute;
          right: 0;
          top: 0;
      }
      
      .loader,
      .loader-content {
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .loader-message {
          font-size: 2.5em;
          margin-top: 1em;
      }
      `}
      </style>
      <div className="loader">
        <div className="loader-content">
          <ReactLoading
            type="spokes"
            color="#000099"
            height={500}
            width={250}
          />
        </div>
      </div>
      <div className="loader">Loading ...</div>
    </React.Fragment>
  );
}

export default function ForgotPassword(props) {

  const { uidb64, token } = useParams();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [state, setState] = useState({
    password: "",
    password2: "",
    errorMessage: "",
    submitted: false,
    handleChange: false,
  });

  console.log(state)

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function refreshPage() {
    window.location.reload();
  }

  const handleSubmitClick = (e) => {

    if (state.password !== state.password2) {
      setState({ errorMessage: "The passwords doesn't match" });
    } else {
      const login = {
        uidb64: uidb64, 
        token: token,
        password: state.password
      };
      setState({ submitted: true });
      fetch(`${process.env.REACT_APP_API_URL}/auth/password-reset-complete/`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(login),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          window.location.href = "/passwdresetsuccess";
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem("token");
        });
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmitClick();
    }
  };

  return (
    <div
      style={{
        zoom: "70%",
      }}
    >
      <Helmet>
        <title>LabAsService</title>
      </Helmet>
      <RemoveScrollBar />
      {!state.errorMessage && state.submitted ? (
        <Progress submitted={state.submitted} refreshPage={refreshPage} />
      ) : (
        <div
          className="text-muted text-center mt-2 mb-3"
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            display: "block",
            fontFamily: "work sans",
          }}
        >
          <div className={classes.container}>
            <GridItem
              sm={12}
              item
              md={6.5}
              item
              xs={20}
              style={{
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                position: "center",
                height: "calc(150vh)",
              }}
            >
              <Card
                className={classes[cardAnimaton]}
                style={{
                  marginRight: "auto",
                  marginLeft: "auto",
                  display: "block",
                  position: "center",
                }}
              >
                <form className={classes.form}>
                  <CardHeader align="center">
                    
                  <Avatar style={{                      
                      backgroundColor: "#F98E23"
                  }}
                  ><EmailIcon /></Avatar>
                    <Typography component="h1" variant="h5" style={{textAlign: "center", fontSize: "25px"}}>
                    Reset Password 
                    </Typography>

                    <br />                                        
                    
                  </CardHeader>   
                  {state.errorMessage ?              
                  <Typography component="h5" variant="h5" style={{textAlign: "center", fontSize: "15px", color: "red"}}>
                  {state.errorMessage}
                    </Typography>
                  : "" }  
                  <CardBody>
                    <Grid container spacing={4}>
                    <Grid
                      spacing={4}
                      item
                      md={12}
                      xs={6}
                      key="password"
                      style={{
                        position: "relative",
                        bottom: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                      }}
                    >
                      <TextField
                        autoComplete="password"
                        name="password"
                        variant="outlined"
                        placeholder="Password"
                        required
                        fullWidth
                        type="password"
                        id="password"
                        autoFocus
                        onChange={handleChange}
                        value={state.password}
                        validate={{
                          pattern: {
                            value:
                              "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
                            errorMessage:
                              "Requires min 8 char, 1 upper case, 1 numeric and 1 special char",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      spacing={4}
                      item
                      md={12}
                      xs={6}
                      key="password2"
                      style={{
                        position: "relative",
                        bottom: "30px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="password2"
                        placeholder="Confirm Password"
                        name="password2"
                        onChange={handleChange}
                        value={state.password2}
                        autoComplete="current-password"
                        type="password"
                        validate={{
                          pattern: {
                            value:
                              "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
                            errorMessage:
                              "Requires min 8 char, 1 upper case, 1 numeric and 1 special char",
                          },
                        }}
                      />
                    </Grid>
                      
                    </Grid>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      variant="contained"
                      style={{ color: "#ffffff", backgroundColor: "#e91e63" }}
                      size="lg"
                      onClick={handleSubmitClick}
                      disabled={state.submitted ? true : false}
                    >
                     Submit 
                    </Button>
                  </CardFooter>                  
                  <div> 
                    <br />

                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "#757575",
                        fontFamily: "Open Sans",
                        fontWeight: 400,
                      }}
                    >
                      &copy; {1900 + new Date().getYear()} ZippyOPS Consulting
                      Services LLP
                    </p>
                  </div>

                  <div
                    style={{ paddingBottom: "30px" }}
                    className="col-sm-12"
                  ></div>
                </form>
              </Card>
            </GridItem>
          </div>
        </div>
      )}
    </div>
  );
}
