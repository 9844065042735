import React, {useState, useEffect} from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';

const TotalCustomers = (props) => {

 
  const [labscard, setLabscard] = useState([]); 

  useEffect(() => {    
    fetch(`${process.env.REACT_APP_API_URL}/api/labscard/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setLabscard(data)
      })
      .catch((error) => {
        console.error(error);        
      });              
  }, []); 

  return(
  <Card sx={{ height: '100%' }} {...props}>
    <CardContent>
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            STOPPED
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
                        
            {labscard.customstopped}
           
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: yellow[600],
              height: 35,
              width: 35
            }}
          >
            <EqualizerIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
    }

export default TotalCustomers;
