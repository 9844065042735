import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import EmailIcon from '@material-ui/icons/Email';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import GridItem from "../../../components/authentication/components/Grid/GridItem.js";
import Button from "../../../components/authentication/components/CustomButtons/Button.js";
import Card from "../../../components/authentication/components/Card/Card.js";
import CardBody from "../../../components/authentication/components/Card/CardBody.js";
import CardHeader from "../../../components/authentication/components/Card/CardHeader.js";
import CardFooter from "../../../components/authentication/components/Card/CardFooter.js";

import styles from "../../../components/authentication/assets/jss/labs/views/loginPage.js";

import image from "../../../components/authentication/assets/img/bg.jpg";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import ReactLoading from "react-loading";
const useStyles = makeStyles(styles);

function Progress(props) {
  const { submitted, refreshPage } = props;

  const delay = 3;

  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return show ? (
    refreshPage()
  ) : (
    <React.Fragment>
      <style>
        {`
        .loader {
          background: #ffffff;
          bottom: 0;
          left: 0;
          opacity: 0.8;
          position: absolute;
          right: 0;
          top: 0;
      }
      
      .loader,
      .loader-content {
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .loader-message {
          font-size: 2.5em;
          margin-top: 1em;
      }
      `}
      </style>
      <div className="loader">
        <div className="loader-content">
          <ReactLoading
            type="spokes"
            color="#000099"
            height={500}
            width={250}
          />
        </div>
      </div>
      <div className="loader">Loading ...</div>
    </React.Fragment>
  );
}

export default function Email(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [state, setState] = useState({
    email: "",
    errorMessage: "",
    submitted: false,
    handleChange: false,
  });

  localStorage.clear();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function refreshPage() {
    window.location.reload();
  }

  const handleSubmitClick = (e) => {
    setState({ handleChange: false });
    const login = {
      email: state.email
    };
    setState({ submitted: true });
    fetch(`${process.env.REACT_APP_API_URL}/auth/request-reset-email/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(login),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = "/emailsend";
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem("token");
      });
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmitClick();
    }
  };

  return (
    <div
      style={{
        zoom: "70%",
      }}
    >
      <Helmet>
        <title>LabAsService</title>
      </Helmet>
      <RemoveScrollBar />
      {!state.errorMessage && state.submitted ? (
        <Progress submitted={state.submitted} refreshPage={refreshPage} />
      ) : (
        <div
          className="text-muted text-center mt-2 mb-3"
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            display: "block",
            fontFamily: "work sans",
          }}
        >
          <div className={classes.container}>
            {state.errorMessage && !state.submitted && (
              <React.Fragment>
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Note archived"
                  style={{
                    paddingTop: "60px",
                    paddingRight: "30px",
                    position: "fixed",
                  }}
                  action={
                    <React.Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
                  maxSnack={3}
                >
                  <Alert onClose={handleClose} severity="error">
                    {state.errorMessage}
                  </Alert>
                </Snackbar>
              </React.Fragment>
            )}

            <GridItem
              sm={12}
              item
              md={6.5}
              item
              xs={20}
              style={{
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                position: "center",
                height: "calc(150vh)",
              }}
            >
              <Card
                className={classes[cardAnimaton]}
                style={{
                  marginRight: "auto",
                  marginLeft: "auto",
                  display: "block",
                  position: "center",
                }}
              >
                <form className={classes.form}>
                  <CardHeader align="center">
                    
                  <Avatar style={{                      
                      backgroundColor: "#F98E23"
                  }}
                  ><EmailIcon /></Avatar>
                    <Typography component="h1" variant="h5" style={{textAlign: "center", fontSize: "25px"}}>
                        Forgot password
                    </Typography>

                    <br />                                        
                    
                  </CardHeader>                 

                  <CardBody>
                    <Grid container spacing={1}>
                      <Grid spacing={4} item md={12} xs={6} key="email">
                        <div
                          className="text-muted text-center mt-2 mb-3"
                          style={{ fontFamily: "sans-serif", display: "block" }}
                        ></div>
                        <TextField
                          display="block"
                          marginRight="auto"
                          marginLeft="auto"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          name="email"
                          autoComplete="email"
                          placeholder="Email"
                          autoFocus
                          value={state.email}
                          onChange={handleChange}
                          onKeyPress={handleKeypress}
                        />
                      </Grid>                      
                    </Grid>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      variant="contained"
                      style={{ color: "#ffffff", backgroundColor: "#e91e63" }}
                      size="lg"
                      onClick={handleSubmitClick}
                      disabled={state.submitted ? true : false}
                    >
                     Send 
                    </Button>
                  </CardFooter>                  
                  <div> 
                    <br />

                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "#757575",
                        fontFamily: "Open Sans",
                        fontWeight: 400,
                      }}
                    >
                      &copy; {1900 + new Date().getYear()} ZippyOPS Consulting
                      Services LLP
                    </p>
                  </div>

                  <div
                    style={{ paddingBottom: "30px" }}
                    className="col-sm-12"
                  ></div>
                </form>
              </Card>
            </GridItem>
          </div>
        </div>
      )}
    </div>
  );
}
