import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Alert } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";

const Usermodify = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    username: "",
    groups: [],
    password: "",
    password2: "",
    errorMessage: "",
    right: false,
    radioList: "A",
    active: "1",
  });

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (state.password !== state.password2) {
      setState({ errorMessage: "The passwords doesn't match" });
    } else {
      const register = {
        username: state.username,
        password: state.password,
        groups: state.groups.toString(),
        active: state.active,
      };
      const UserID = props.UserID;
      fetch(
        `${process.env.REACT_APP_API_URL}/api/user_management/user/${UserID}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(register),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          window.location.reload();
        })
        .catch((error) => {
          window.location.reload();
        });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [userlist, setusers] = useState([]);

  useEffect(() => {
    var UserID = props.UserID;
    fetch(
      `${process.env.REACT_APP_API_URL}/api/user_management/user_list/${UserID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          localStorage.setItem("user_access", data.detail);
        } else {
          setusers(data);
        }
      })
      .catch((error) => {});
  }, [props.UserID]);

  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        startIcon={<EditIcon />}
        color="primary"
        variant="contained"
        style={{ height: "28px", backgroundColor: "#009688" }}
      >
        Edit
      </Button>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div>
          {state.errorMessage && (
            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
              {" "}
              {state.errorMessage}{" "}
            </Alert>
          )}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">CREATE USER</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              placeholder={userlist.email}
              type="email"
              fullWidth
              name="email"
              value={state.email}
              onChange={handleChange}
              autoComplete="new-email"
              for="email"
              required
            />
            <TextField
              for="username"
              id="username"
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              placeholder={userlist.username}
              value={state.username}
              onChange={handleChange}
              name="username"
            />
            <TextField
              autoFocus
              margin="dense"
              label="Password*"
              fullWidth
              for="password"
              type="password"
              autoComplete="new-password"
              name="password"
              id="password"
              value={state.password}
              onChange={handleChange}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Confirm Password*"
              fullWidth
              for="password2"
              type="password"
              autoComplete="new-password"
              name="password2"
              id="password2"
              value={state.password2}
              onChange={handleChange}
            />
          </DialogContent>{" "}
          <DialogContent>
            <Grid item md={13} xs={12}>
              <TextField
                fullWidth
                name="groups"
                id="groups"
                value={state.groups}
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {parseInt(userlist.groups) === 1 ? (
                  <React.Fragment>
                    <option value="1">Admin</option>
                    <option value="2">Read-Only</option>
                    <option value="3">OPS</option>
                  </React.Fragment>
                ) : parseInt(userlist.groups) === 2 ? (
                  <React.Fragment>
                    <option value="2">Read-Only</option>
                    <option value="1">Admin</option>
                    <option value="3">OPS</option>
                  </React.Fragment>
                ) : parseInt(userlist.groups) === 3 ? (
                  <React.Fragment>
                    <option value="3">OPS</option>
                    <option value="1">Admin</option>
                    <option value="2">Read-Only</option>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </TextField>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmitClick} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Usermodify;
