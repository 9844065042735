import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box, Divider, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";

export default function Schedule(props) {
  const { setState } = props;

  const [temporary, settemporary] = React.useState({
    repeat_frequency: "none",
  });
  const [schedulevalue, setSchedulevalue] = React.useState({
    repeat_frequency: "none",
  });

  const [setMaxValue, setSetMaxValue] = React.useState(0);
  const [setMinValue, setSetMinValue] = React.useState(0);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSchedulevalue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    settemporary((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  let localTime = new Date(); // minDate
  let month = "" + (localTime.getMonth() + 1);
  let day = "" + localTime.getDate();
  let year1 = localTime.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  var minDate1 = [year1, month, day].join("-");
  console.log(minDate1);

  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24; //Hours
  const start = schedulevalue.start_date_time;
  const date1 = new Date(start);
  const end = schedulevalue.end_date_time;
  const date2 = new Date(end);
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffDays = Math.ceil(timeDiff / MILLISECONDS_PER_DAY);
  const total_hours = diffDays * 24;

  return (
    <React.Fragment>
      <Box sx={{ pt: 1 }}>
        <Divider />

        <br />
        <Box borderColor="error.main">
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <InputLabel>
                Start Date {"&"} Time
                <small style={{ paddingLeft: "10px" }}>required</small>
                <small style={{ color: "red" }}>*</small>
              </InputLabel>

              <TextField
                fullWidth
                name="start_date_time"
                id="start_date_time"
                type="datetime-local"
                onChange={handleChange}
                required
                format="DD/MM/YYYY"
                variant="outlined"
                inputProps={{
                  min: minDate1,
                }}
                InputProps={{
                  style: {
                    height: "40px",
                  },
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <InputLabel>
                End Date {"&"} Time
                <small style={{ paddingLeft: "10px" }}>required</small>
                <small style={{ color: "red" }}>*</small>
              </InputLabel>

              <TextField
                fullWidth
                defaultValue="00-00-0000"
                name="end_date_time"
                id="end_date_time"
                type="datetime-local"
                format="DD/MM"
                onChange={handleChange}
                required
                variant="outlined"
                inputProps={{
                  min: schedulevalue.start_date_time
                    ? schedulevalue.start_date_time
                    : minDate1,
                }}
                InputProps={{
                  style: {
                    height: "40px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>
                Hours<small style={{ paddingLeft: "10px" }}>required</small>
                <small style={{ color: "red" }}>*</small>
              </InputLabel>
              <TextField
                fullWidth
                name="actualhours"
                id="actualhours"
                disabled={
                  schedulevalue.start_date_time && schedulevalue.end_date_time
                    ? false
                    : true
                }
                onChange={handleChange}
                required
                autoComplete="actualhours"
                type="number"
                inputProps={{
                  min: setMinValue,
                  max: setMaxValue,
                  maxLength: setMaxValue,
                  step: "1",
                }}
                InputProps={{
                  style: {
                    height: "40px",
                  },
                }}
                error={
                  schedulevalue.actualhours &&
                  schedulevalue.actualhours > total_hours
                    ? "Please enter the appropriate hours"
                    : ""
                }
                helperText={
                  schedulevalue.actualhours &&
                  schedulevalue.actualhours > total_hours
                    ? "Please enter the appropriate hours"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h8">
                <strong>Note:</strong>
                <small>
                  {" "}
                  Please select the Start time and Stop time which includes the
                  idle hours.
                </small>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}