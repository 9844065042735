import React from "react";
import ReactExport from "react-export-excel";
import {
    Button  
  } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ReportExport = (props) => {
    const { pccreportdataset } = props;
        return (
            <ExcelFile element={
            <Button sx={{ mx: 1 }}
            variant="contained"
            startIcon={<GetAppIcon />}> Report </Button>       
            }>
                <ExcelSheet data={pccreportdataset} name="Lab Credentials">
                    <ExcelColumn label="Trainee Name" value="trainee"  style={{ font: { sz: "24", bold: true } }}/>
                    <ExcelColumn label="Server Name" value="key"/>
                    <ExcelColumn label="Public Ip" value="public"/>
                    <ExcelColumn label="Private Ip" value="private"/>
                    <ExcelColumn label="Console url" value="console_url"/>
                    <ExcelColumn label="Console username" value="console_username"/>
                    <ExcelColumn label="Console password" value="console_password"/>
                    <ExcelColumn label="Instance username" value="instance_username"/>
                    <ExcelColumn label="Instance password" value="instance_password"/>

                </ExcelSheet>
            </ExcelFile>
        );
}


export default ReportExport;