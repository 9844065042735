import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
} from "@material-ui/core";
import SearchField from "react-search-field";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

const delay = 5;

const options = [
  { id: 1, name: "Lab Name", value: "Lab_name" },
  { id: 2, name: "Schedule Type", value: "Schedule_type" },
];

const CurrentSchedule = ({ ...rest }) => {
  let today = new Date().toISOString().substring(0, 10);

  function UpcomingOnTime(time) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Time1 = new Date(`${today}` + "T" + time + ":00.000Z").toLocaleString(
      "en-GB",
      { timeZone: `${timezone}` }
    );
    const Time2 = Time1.split(",");
    const Time3 = Time2[1].substring(0, 6);
    return Time3;
  }

  function UpcomingOffTime(time) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const Time1 = new Date(`${today}` + "T" + time + ":00.000Z").toLocaleString(
      "en-GB",
      { timeZone: `${timezone}` }
    );
    const Time2 = Time1.split(",");
    const Time3 = Time2[1].substring(0, 6);
    return Time3;
  }

  const [instanceschlist, setinstanceschlist] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/currentlabs/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setinstanceschlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, instanceschlist.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [searchitems, setSearchItems] = React.useState(0);

  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ButtonGroup
            sx={{ mr: 2 }}
            variant="outlined"
            style={{
              backgroundColor: "#ffff",
              color: "rgb(90, 90, 90)",
              border: "1px solid rgb(221, 221, 221)",
            }}
            aria-label="split button"
          >
            <Button
              style={{
                width: "180px",
                backgroundColor: "#ffff",
                color: "rgb(90, 90, 90)",
                border: "1px solid rgb(221, 221, 221)",
              }}
            >
              {options[selectedIndex].name} {" = "}
            </Button>
            <Button
              style={{
                backgroundColor: "#ffff",
                color: "rgb(90, 90, 90)",
                border: "1px solid rgb(221, 221, 221)",
              }}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleClick}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option.name}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Popover>
          <SearchField
            value={searchitems}
            placeholder="Search..."
            onChange={(event) => {
              setSearchItems(event);
            }}
            classNames="test-class"
          />
          <Box sx={{ pt: 3 }}>
            <Card {...rest}>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1000 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Lab Name</TableCell>
                          <TableCell>No.of Trainee</TableCell>
                          <TableCell>On Time</TableCell>
                          <TableCell>Off Time</TableCell>

                          <TableCell>Scheduling Type</TableCell>
                        </TableRow>
                      </TableHead>
                      {instanceschlist.length > 0 ? (
                        <TableBody>
                          {instanceschlist
                            .filter((val) => {
                              if (searchitems == "") {
                                return val;
                              } else if (
                                val[options[selectedIndex].value]
                                  .toLowerCase()
                                  .includes(searchitems.toLowerCase())
                              ) {
                                return val;
                              }
                            })
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((instance) =>
                              instance.Application === "custom" ? (
                                <React.Fragment>
                                  <TableRow hover key={instance.Id}>
                                    <TableCell>{instance.Id}</TableCell>
                                    <TableCell>{instance.Lab_name}</TableCell>
                                    <TableCell>{instance.Trainees}</TableCell>
                                    {instance.On_time === "" ||
                                    instance.On_time === null ? (
                                      <TableCell>NA</TableCell>
                                    ) : (
                                      <TableCell>
                                        {UpcomingOnTime(`${instance.On_time}`)}
                                      </TableCell>
                                    )}
                                    {instance.Off_time === "" ||
                                    instance.Off_time === null ? (
                                      <TableCell>NA</TableCell>
                                    ) : (
                                      <TableCell>
                                        {UpcomingOffTime(
                                          `${instance.Off_time}`
                                        )}
                                      </TableCell>
                                    )}

                                    <TableCell>
                                      {instance.Schedule_type}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ) : (
                                ""
                              )
                            )}
                        </TableBody>
                      ) : (
                        <TableBody align="center">
                          <TableCell>No Data Available</TableCell>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {instanceschlist.length > 0 ? (
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={instanceschlist.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Divider />
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default CurrentSchedule;