import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';


const TypePrice = (props) => {
  const { type } = props;
  return (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            INSTANCE COST
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
           ₹ {type}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
  }
export default TypePrice;
