import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "@material-ui/core/Card";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import CardContent from "@material-ui/core/CardContent";
export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <style>{`
.pac-man {
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 5em;
  border-radius: 100em 100em 0 0;
  background: #f00;
  transform-origin: bottom;
  animation: eating-top .5s infinite;
  
  &, &::before {
    width: @pacman-zise;
    height: calc(@pacman-zise/2);
    background: @primaryClr;
  }
  
  &::before {
    content: '';
    display: block;
    margin-top: calc(@pacman-zise/2);
    position: absolute;
    transform-origin: top;
    border-radius: 0 0 100em 100em;
    transform: rotate(80deg);
    animation: eating-bottom .5s infinite;
  }
`}</style>
      <Button size="sm" onClick={handleClickOpen}>
        <InfoTwoToneIcon color="primary" />
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogContent dividers>
          <Card style={{ minHeight: 300 }}>
            <CardContent>
              <div id="load">
                <div>Terminating your lab with in few minutes...</div>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
