import React, { useState } from "react";
import DefaultNavbar from "./DefaultNavbar";
import DefaultFooter from "src/components/DefaultFooter";
import H2 from "@material-tailwind/react/Heading2";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import H6 from "@material-tailwind/react/Heading6";
import Products from "./Products";
import Contact from "./Contact";
import background from "../../../assets/img/teachers-enviroment.jpg";

const useStyles = makeStyles(() => ({
  root: {
    padding: "4px 6px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  rootCard: {
    maxWidth: 80,
    padding: 7,
    backgroundColor: "#ffffff",
  },
}));

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function MainPage() {
  const classes = useStyles();
  const [searchitems, setSearchItems] = useState("");

  const [filteritems, setFilterItems] = useState([]);

  const keyName = "name";
  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <div className="relative pt-1 pb-10 flex content-center items-center justify-center h-screen">
          <div
            className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full"
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(0,0,0,0.6) 0),url(${background})`,
            }}
          />
          <div className="container max-w-8xl relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-1 ml-auto mr-auto text-center">
                <H2 color="white">SandBoxAsService</H2>
                <div className="text-gray-200">
                  <Paper component="form" className={classes.root}>
                    <InputBase
                      className={classes.input}
                      placeholder="Search ..."
                      inputProps={{ "aria-label": "search ..." }}
                      onChange={(event) => {
                        setSearchItems(event.target.value);
                      }}
                      value={searchitems}
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="pb-10 bg-gray-100 -mt-32">
          <div className="container max-w-7xl mx-auto px-4">
            <div className="flex flex-wrap relative z-50">
              <Grid container spacing={3} justify="center">
                {Products.filter((val) => {
                  if (searchitems == "") {
                    return val;
                  } else if (
                    val[keyName]
                      .toLowerCase()
                      .includes(searchitems.toString().toLowerCase())
                  ) {
                    return val;
                  }
                }).map((number) => {
                  return (
                    <div className="px-4 mt-12">
                      <CardImage
                        className={classes.rootCard}
                        alt=""
                        src={number.image}
                      />
                    </div>
                  );
                })}
                {Products.filter((val) =>
                  val[keyName]
                    .toLowerCase()
                    .includes(searchitems.toString().toLowerCase())
                ).length === 0 ? (
                  <Card>
                    <div className="mr-auto text-center">
                      <H6 color="gray">Manual Request for SandBox</H6>
                    </div>
                    <CardBody>
                      <Contact />
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          </div>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
