import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import AccountProfileDetails from '../../components/account/AccountProfileDetails';
import { makeStyles } from '@material-ui/styles';

  const useStyles = makeStyles ({
    root: {
      flexGrow: 1,
    },
  });

export default function Request() {
    const classes = useStyles();
   
    return (
      <React.Fragment>

      <Helmet>
      <title>SandboxAsService</title>
      </Helmet>
      <div className={classes.root} style={{paddingRight: '20px', paddingLeft: '10px'}}>
     
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        minWidth: '100%',
        py: 3
      }}
      
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          
        >
          
          <Grid
            item
            lg={11.8}
            md={6}
            xs={12}
            style={{
              margin: "auto",
            }}
          >
            <AccountProfileDetails />
          </Grid>
        </Grid>
        
      </Container>
    </Box>
      </div>
      </React.Fragment>
    );
  }