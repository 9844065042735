import DefaultNavbar from 'src/components/DefaultNavbar';
import DefaultFooter from 'src/components/DefaultFooter';
import Header from 'src/components/landing/Header';
import WorkingSection from 'src/components/landing/WorkingSection';

export default function MainPage() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <WorkingSection />
            </main>
            <DefaultFooter />
        </>
    );
}
