import React from "react";
import { makeStyles } from "@material-ui/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Technology from "./Technology";
import Configurations from "./Configurations";
import Schedule from "./Schedule";
import Notification from "./Notification";
import Budget from "./Budget";
import BudgetError from "./BudgetError";
import { Card, Box, Container, CardContent } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  stepper: {
    color: "green",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },

  addbuttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 30px",
    paddingLeft: 10,
  },

}));

export default function RequestLayout() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = [
    "General Info",
    "Configure Machines",
    "Schedule",
    "Notification",
    "Budget",
  ];

  const [lablist, setlablist] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/lablist/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
        } else {
          setlablist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [budgetopen, setBudgetOpen] = React.useState(false);

  const [state, setState] = React.useState({
    name: "",
    count: "1",
    description: "",
    customized_variable: "",
    billing_info: "",
    commands: "",
    application: "custom",    
    cpu_count: "",
    start_date_time: "",
    end_date_time: "",
    start_time: "",
    end_time: "",
    start_date: "",
    end_date: "",
    startcron_expression: "",
    stopcron_expression: "",
    startcron_expression2: "",
    stopcron_expression2: "",    
    budget: "",
    env_keep: "",
    delete_id: "",
    errorMessage: "",
  });

  const handleSubmitClick = (e) => {
    e.preventDefault();
    setBudgetOpen(false);
    let available = localStorage.getItem("available");
    if (parseInt(state.budget) < parseInt(available)) {
      handleNext();
      var addvalue = state.customized_variable;      
      addvalue.lab_name = state.name;
      addvalue.Application_Name = state.application;
      addvalue.master_count = state.count;
      addvalue.node_count = state.count;
      delete addvalue["cpu_count"];
      var email;
      if (state.email) {
        email = state.email;
      } else {
        email = localStorage.getItem("email");
      }
      // addvalue.start_time = state.startcron_expression;
      // addvalue.stop_time = state.stopcron_expression;
      var value = JSON.stringify(addvalue);

      var env_keep;
      if (state.env_keep) {
        env_keep = "yes";
      } else {
        env_keep = "no";
      }
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const instancedata = {
        name: state.name,
        number_of_trainee: state.count,
        description: state.description,
        application: state.application,
        customized_variable: value,
        billing_info: state.billing_info,
        commands: state.commands,
        start_date: state.start_date,
        end_date: state.end_date,
        start_time: state.start_time,
        stop_time: state.end_time,
        time_zone: timezone,
        depends_on: state.depends_on,
        repeat_frequency: state.repeat_frequency,
        every: state.every,
        on_days: state.on_days,
        startcron_expression: state.startcron_expression,
        stopcron_expression: state.stopcron_expression,
        startcron_expression2: state.startcron_expression2,
        stopcron_expression2: state.stopcron_expression2,        
        cpu_count: state.cpu_count,
        email: email,
        budget: state.budget,
        env_keep: env_keep,
      };
      fetch(`${process.env.REACT_APP_API_URL}/api/manageInstance/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(instancedata),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.name) {
            window.location.href = "/app/instance/custom";
          } else {
            if (data.name) {
              setState({ errorMessage: data.name[0] });
              window.location.href = "/app/instance/custom";
            }
          }
        })
        .catch((error) => {
          window.location.reload();
        });
    } else {
      setBudgetOpen(true);
    }
  };

  var value = JSON.stringify(state);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Technology
            state={state}
            onState={setState}
            handleNext={handleNext}
            lablist={lablist}
          />
        );
      case 1:
        return (
          <Configurations
            value={value}
            onState={setState}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            stepslength={steps.length}
          />
        );
      case 2:
        return (
          <Schedule
            statevalue={value}
            onState={setState}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            stepslength={steps.length}
          />
        );
      case 3:
        return (
          <Notification
            onState={setState}
            activeStep={activeStep}
            handleBack={handleBack}
            handleSubmitClick={handleNext}
            stepslength={steps.length}
          />
        );
      case 4:
        return (
          <Budget
            onState={setState}
            activeStep={activeStep}
            handleBack={handleBack}
            handleSubmitClick={handleSubmitClick}
            stepslength={steps.length}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };
  const RunningLab = () => {
    window.location.href = "/app/instance/custom";
  };
  const BacktoNew = () => {
    window.location.href = "/app/maindeployment";
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>SandboxAsService</title>
      </Helmet>
      <Box
        sx={{
          minWidth: "1050",
          paddingRight: "40px",
        }}
      >
        <BudgetError
          validerroropen={budgetopen}
          setvaliderroropen={setBudgetOpen}
        />
        <Typography component="h1" variant="h4" align="center">
          Custom Create Lab
        </Typography>

        <Box sx={{ pt: 2 }}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                minWidth: "1050",
              }}
            >
              <CardContent>
                <form
                  autoComplete="off"
                  noValidate
                  style={{ paddingRight: "40px", paddingLeft: "20px" }}
                >
                  <React.Fragment>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography variant="h5" gutterBottom align="center">
                          <div style={{ height: "40" }}>
                            <DoneIcon style={{ fontSize: "xx-large" }} />{" "}
                            <span style={{ color: "green", fontSize: "30px" }}>
                              Successful !!!
                            </span>
                          </div>
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          Instance will be ready to use within 10 mins!!!
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pt: 2,
                            paddingLeft: "30px",
                          }}
                        >
                          <div
                            className={classes}
                            style={{ paddingRight: "10px" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes}
                              onClick={BacktoNew}
                            >
                              <NavigateBeforeIcon />
                              Back
                            </Button>
                          </div>
                          <div className={classes}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes}
                              onClick={RunningLab}
                            >
                              Go to Running labs
                            </Button>
                          </div>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {getStepContent(activeStep)}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}
