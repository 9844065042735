import React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";

export default function FormDialog(props) {
  const { InstanceID, name, status, spent, budget } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    let balance = parseInt(budget) - parseInt(spent);
    let available = localStorage.getItem("available");
    if (available > balance && balance > 0) {
      fetch(`${process.env.REACT_APP_API_URL}/api/uncordoned/${InstanceID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          handleClose();
        })
        .catch((error) => {
          window.location.reload();
        });
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      {status === "Cordoned" ? (
        <React.Fragment>
          <MenuItem onClick={handleSubmitClick}>
          <IconButton aria-label="delete">
            <RemoveCircleOutlineOutlinedIcon /> &nbsp;&nbsp;
            <span style={{ fontSize: "0.7em", color: "#172B4D" }}>UnCordoned</span>
          </IconButton>                    
          </MenuItem>
        </React.Fragment>
      ) : (
        ""
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle id="form-dialog-title">
          Please check the Available Balance!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Not enough balance in your account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
