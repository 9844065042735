import React from "react";
import { Grid, TextField, Box, Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import Checkbox from '@material-ui/core/Checkbox';
import SearchField from "react-search-field";


export default function Applications(props) {
  let { OSTYPE, appsetFormFields, appfieldIndex, setNormalport } = props;

  const [customapps, setCustomapps] = React.useState([[]]);
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/customapplist/${OSTYPE}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => setCustomapps(resp))
      .catch((error) => {
        console.error(error);
      });    
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectedPorts, setSelectedPorts] = React.useState([]);
  const [selectedApps, setSelectedApps] = React.useState([]);
  const [forversion, setForversion] = React.useState([]);
  const [selectedVersions, setSelectedVersions] = React.useState([]);

  const handleChange = (e, index, ports) => {
    const { id, value } = e.target;

    if (selectedValue[value]) {
      setSelectedValue((prevState) => ({
        ...prevState,
        [value]: false,
      }));
      if (ports !== null) {
        setSelectedPorts((prevState) => ({
          ...prevState,
          [ports]: false,
        }));  
        setSelectedApps((prevState) => ({
          ...prevState,
          [ports]: "",
        }));
      }    
      setForversion((prevState) => ({
        ...prevState,
        [index]: "",
      }))     
      setSelectedVersions((prevState) => ({
        ...prevState,
        [value]: "",
      }));                  
    } else {
      setSelectedValue((prevState) => ({
        ...prevState,
        [value]: e.target.checked,
      }));  
      if (ports !== null) {
        setSelectedPorts((prevState) => ({
          ...prevState,
          [ports]: true,
        }));  
        setSelectedApps((prevState) => ({
          ...prevState,
          [ports]: value,
        })); 
      }    
      if (value) {
        const apipostval = value + "-" + OSTYPE; //OSTYPE
        fetch(
          `${process.env.REACT_APP_API_URL}/api/customversionlist/${apipostval}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then((resp) => resp.json())
          .then((resp) =>
            setForversion((prevState) => ({
              ...prevState,
              [index]: resp,
            }))
          )          
          .catch((error) => {
            console.error(error);
          });
      }              
    }
  }


  const [blockapps, setBlockapps] = React.useState(false);
  const [apperror, setApperror] = React.useState(false);

  const [formFields, setFormFields] = React.useState([]);

  const handleChangeInput = (e, index, appname) => {
    const { id, value } = e.target;
    setSelectedVersions((prevState) => ({
      ...prevState,
      [appname]: value,
    })); 
  };

  const handleSumbitClick = (e) => {
    let assignAppValue = [];
    let assignAppports = [];
    let count = 0
    let version;
    customapps.map((appdata) => {
      if (selectedValue[appdata.Name]) {
        if (selectedVersions[appdata.Name]) {
          version = selectedVersions[appdata.Name]
          const appval = appdata.Name  //+ version
          assignAppValue.push(appval);
          assignAppports.push(appdata.Ports);
        } else {
          count = count + 1
        } 
      }
    })
    if (count > 0) {
      setApperror(true)
    } else {
      setApperror(false)  
      appsetFormFields((prevState) => ({
        ...prevState,
        [appfieldIndex]: assignAppValue,
      })); 
      setNormalport((prevState) => ({
        ...prevState,
        [appfieldIndex]: assignAppports,
      }));   
      setOpen(false);
    }
  };

  const [searchitems, setSearchItems] = React.useState(0);

  return (
    <>
      <div>
        <Fab variant="extended" size="small" color="primary" aria-label="add">
          <Chip
            icon={<AddIcon />}
            label="Applications"
            clickable
            color="primary"
            onClick={handleClickOpen}
          />
        </Fab>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Applications{" "}          
        </DialogTitle>
        { blockapps ?
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Don't choose multiple applications with same port!
        </Alert>
        : "" }
        { apperror ?
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Please make sure all the fields are selected.
        </Alert>
        : "" }        
        <DialogContent dividers>
        <SearchField
    value={searchitems}
  placeholder="Search..."
  onChange={((event) => {
    setSearchItems(event);
  })}
  classNames="test-class"
/>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <Box>
              {customapps ? (
                  <React.Fragment>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                      <Grid container spacing={0} sx={{ mt: 3 }}>
                              <Grid item xs={5}>
                      <Typography variant="h8" gutterBottom>
                  Application
                </Typography>
                </Grid>
                              <Grid item xs={2}>
                              <Typography variant="h8" gutterBottom>
                  Version
                </Typography>
                              </Grid>
                </Grid>
                    <Divider />
                      {customapps
                      .filter((val) => {
                        if (searchitems == "") {
                            return val;
                        } else if ( (val.Title).toLowerCase().includes(searchitems.toLowerCase())){
                          return val;
                        }
                      })
                      .map((e, index) => {
                          let ports = e.Ports;
                          let appname = e.Name;
                          return (
                            <Grid container spacing={0} sx={{ mt: 3 }}>
                              <Grid item xs={5}>
                              <Checkbox
        checked={selectedValue[e.Name]}
        onChange={(e) => handleChange(e, index, ports)}        
        disabled={selectedApps[ports] !== e.Name && selectedPorts[ports]}
        value={e.Name}        
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
                      <Typography variant="h8" gutterBottom>
                  {`${e.Title}`}
                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  fullWidth                                  
                                  name={`version`}
                                  id={`version`}
                                  onChange={(e) => handleChangeInput(e, index, appname)}                                  
                                  required
                                  select
                                  SelectProps={{ native: true }}
                                  autoComplete="name"
                                >
                                  <option value="">
                                    --Select the Version--
                                  </option>
                                  {forversion[index] &&
                                    forversion[index]
                                      .split(",")
                                      .map((appdata) => {
                                        return (
                                          <React.Fragment>
                                            <option value={appdata}>
                                              {appdata}
                                            </option>
                                          </React.Fragment>
                                        );
                                      })}
                                </TextField>
                              </Grid>
                            </Grid>
                                              )})}
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            fontStyle: "italic",
                            height: "100%",
                            backgroundColor: "whitesmoke"
                          }}
                          borderColor="error.main"
                          border={1}
                        >
                          <strong>Choosen application :</strong>
                          {customapps.map((e, index) => {
                            if (selectedValue[e.Name] && !selectedVersions[e.Name]) { 
                            return <li style={{textAlignLast: "center"}}>{`${e.Name}-<Version>`}</li>;
                            } else if (selectedValue[e.Name] && selectedVersions[e.Name]) {
                            return <li style={{textAlignLast: "center"}} >{`${e.Name}-${selectedVersions[e.Name]}`}</li>;
                            }
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  </React.Fragment>
              ) : ( "" ) }
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSumbitClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
