import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

export default function FormDialog(props) {
  const { InstanceID, name, status } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Delete = (InstanceID) => (e) => {
    e.preventDefault();
    window.location.reload();
    fetch(
      `${process.env.REACT_APP_API_URL}/api/manageInstance/${InstanceID}/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        handleClose();
      })
      .catch((error) => {
        handleClose();
      });
    handleClose();
  };

  return (
    <div>
      {status === "Running" ||
      status === "Stopped" ||
      status === "Failed" ||
      status === "Cordoned" ? (
        <React.Fragment>
          <MenuItem onClick={handleClickOpen}>
            <IconButton aria-label="delete">
              <DeleteIcon /> &nbsp;&nbsp;
              <span style={{ fontSize: "0.7em", color: "#172B4D" }}>
                Delete
              </span>
            </IconButton>
          </MenuItem>
        </React.Fragment>
      ) : (
        ""
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Lab{" "}
            <strong style={{ color: "#EF5350" }}>{name}</strong> ?
          </DialogContentText>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={Delete(InstanceID)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
