import React from 'react';
import {
  Box,
  Button
} from '@material-ui/core';

const Create = (props) => {
  const CreateInsatnce = () => {
    window.location.href = "/app/maindeployment";
  };
  return (
  <Box {...props}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >

      {localStorage.getItem("group") !== "read_only" ?
      <Button
        color="primary"
        variant="contained"
        onClick={CreateInsatnce}
      >
        Add Labs
      </Button>
      :
      <Button
        color="primary"
        variant="contained"
      >
        Add Labs
      </Button>
     }
    </Box>
  </Box>
);
}

export default Create;
